@function relativePos($value, $container, $multiplier: 100%) {
 @return ($value / $container) * $multiplier;
}

@mixin full-bleed(){
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;

  @include breakpoint(medium up) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include breakpoint(large up) {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include breakpoint(xlarge up) {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include breakpoint(xxlarge up) {
    margin-left: -60px;
    margin-right: -60px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
