.o-fiveCol {
  overflow: hidden;
}

.o-fiveCol .flickity-viewport {
  overflow: visible;
}

.o-fiveCol__item {
}

.o-fiveCol__footer {
  margin-top: 20px;

  @include breakpoint(large up){
    margin-top: 23px;
  }
}

.o-fiveCol__footer-cta {
  @extend %a-cta--dual-line;
  margin-bottom: 4px;
}

.body--highlight,
.s-module-bkgd--highlight {
  .o-fiveCol__footer-cta {
    color: $color__text;
  }
}
