.o-textLongForm {
}

.o-textLongForm__content {
  margin-top: 33px;
  overflow: hidden;
  transition: height 0.3s $bezier--ease-out;

  @include breakpoint(large up) {
    margin-top: 64px;
  }
}

.o-textLongForm__content .a-img {
  margin: 0;
}

.o-textLongForm__content img {
  display: block;
  max-width: 100%;
}

.o-textLongForm__content .a-img + p,
.o-textLongForm__content p:first-child {
  @extend %f-h6;
}

.o-textLongForm__content .a-img + p {
  margin-top: 32px;

  @include breakpoint(large up) {
    margin-top: 66px;
  }
}

.o-textLongForm__content p {
  @extend %f-h15;
}

.o-textLongForm__trigger {
  @extend %f-h10;
  display: inline-block;
  margin-top: 24px;
  color: $color__highlight;
  text-decoration: none;
}

.o-textLongForm__trigger svg {
  width: 11px;
  height: 7px;
  transition: transform 0.3s $bezier--ease-in-out;

  .o-textLongForm--open & {
    transform: rotate(180deg);
  }
}

