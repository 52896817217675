.a-accordionCard {
  height: 100%;
  padding-top: 18px;
  padding-bottom: 12px;
  border-top: 1px solid $color__separator;

  @include breakpoint(large up) {
    min-height: 490px;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;

    @include hover-focus {
      .a-accordionCard__hover-off {
        opacity: 0;
        pointer-events: none;
      }

      .a-accordionCard__hover-on {
        transition-delay: 0.1s;
      }

      .a-accordionCard__hover-on,
      .a-accordionCard__trigger .a-accordionCard__cta,
      .a-accordionCard__trigger .a-accordionCard__link {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.a-accordionCard a {
  text-decoration: none;
}

.a-accordionCard__body,
.a-accordionCard__title a,
.a-accordionCard__subtitle,
.a-accordionCard__secondary li a,
.a-accordionCard__body .a-accordionCard__link,
.a-accordionCard__body .a-accordionCard__cta,
.a-accordionCard__body .a-accordionCard__cta:after {
  transition: all ease-in-out 180ms;
}

.a-accordionCard__body {
  @include sans-serif(13, 20);
  position: relative;

  p {
    margin-top: 0;
    padding-top: 15px;
  }

  @include breakpoint(medium up) {
    @include sans-serif(14, 25);

    p {
      margin-top: 22px;
      padding-top: 0;
    }
  }

  @include breakpoint(large up) {
    display: block;
    height: 100%;
    min-height: 450px;
    padding: 28px 30px 79px;
    background: $color__ctaBackground;

    .a-accordionCard__cta,
    .a-accordionCard__link {
      position: absolute;
    }

    .a-accordionCard__cta {
      margin: 0;
    }
  }
}

.a-accordionCard__trigger {
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;

  @include breakpoint(large up) {
    display: block;
    position: relative;
    cursor: default;
  }

  @include hover-focus {
    .a-accordionCard__title {
      color: $color__highlight;

      @include breakpoint(large up) {
        color: inherit;
      }
    }
  }

  .a-accordionCard__cta,
  .a-accordionCard__link {
    display: none;

    @include breakpoint(large up) {
      display: inline-block;
      top: 100%;
      margin-top: 12px;
      opacity: 0;
    }
  }
}

.a-accordionCard__subtitle {
  display: block;
  color: $color__text--subtitle;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  order: 2;
}

.a-accordionCard__title {
  @extend %f-h5;
  display: block;
  margin: 0;
  padding-right: 35px;
  order: 1;

  @include breakpoint(large up) {
    padding-right: 0;
  }

  a {
    pointer-events: none;

    @include breakpoint(large up) {
      pointer-events: all;
    }
    
    @include hover-focus {
      color: $color__highlight;
    }
  }
}

.a-accordionCard__title a {
  @include hover-focus {
    color: $color__highlight;
  }
}

.a-accordionCard__subtitle + .a-accordionCard__title {
  @include breakpoint(large up) {
    margin-top: 15px;
  }
}

.a-accordionCard__inner {
  order: 3;
  height: 0;
  overflow: hidden;
  transition: height 0.2s $bezier--ease-in-out;

  @include breakpoint(large up) {
    height: auto;
    overflow: visible;
  }

  .a-accordionCard__cta,
  .a-accordionCard__link {
    @include breakpoint(large up) {
      bottom: 45px;
    }
  }
}

.a-accordionCard__link {
  border-bottom: 1px solid transparent;
  display: inline-block;
  margin: -1px 0 0 20px;
  padding: 1px 0 1px;
  position: relative;
  text-decoration: none;
  transition: border-bottom ease-in-out 180ms, color ease-in-out 180ms;

  @include hover-focus {
    color: $color__highlight;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: -25px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23000' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
    background-size: 100% auto;
    transition: opacity ease-in-out 180ms;

    @include breakpoint(large up) {
      top: 6px;
    }
  }
}

// Color variations
.a-accordionCard--dark {
  color: $color__text--dark;
  background: $color__cardBackgroundDark;
}

.a-accordionCard__cta,
.a-accordionCard__link {
  margin-top: 15px;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.a-accordionCard__cta {
  @extend %a-cta--dual-line;
}

.a-accordionCard__hover-off {
  @include breakpoint(large up) {
    transition: opacity 0.2s $bezier--ease-in;
  }
}

.a-accordionCard__hover-on {
  @include breakpoint(large up) {
    position: absolute;
    bottom: 0;
    min-height: 172px;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s $bezier--ease-out;
  }
}

.a-accordionCard__hover-on,
.a-accordionCard__secondary {
  margin-top: 30px;
  padding-bottom: 33px;
}

.a-accordionCard__inner .a-accordionCard__subtitle {
  margin-bottom: 17px;
  color: $color__text;

  @include breakpoint(large up) {
    margin-bottom: 19px;
  }
}

.a-accordionCard__inner ul {
  @include breakpoint(large up) {
    display: flex;
    flex-flow: row wrap;
  }
}

.a-accordionCard__inner li {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  @include breakpoint(large up) {
    width: 50%;
    margin-top: 0;

    &:nth-child(2n+1){
      padding-right: 10px;
    }
  }
}

.a-accordionCard__inner li a {
  border-bottom: 1px solid;

  @include hover-focus {
    color: $color__highlight;
    border-bottom-color: transparent;
  }  
}

.a-accordionCard .icon--minus,
.a-accordionCard .icon--plus-alt {
  position: absolute;
  right: 0;
  color: $color__toggleIcon;
  pointer-events: none;

  @include breakpoint(large up) {
    display: none;
  }
}

.a-accordionCard .icon--minus {
  top: 13px;
}

.a-accordionCard .icon--plus-alt {
  top: 8px;
}

.a-accordionCard--active .icon--plus-alt {
  display: none;
}

.o-three-col__item .a-accordionCard {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}


.a-accordionCard--no-hover .a-accordionCard__cta,
.a-accordionCard--no-hover .a-accordionCard__link {
  position: relative;
  bottom: auto;

  @include breakpoint(large up){
    margin-top: 30px;
  }
}

.a-accordionCard--no-hover .a-accordionCard__inner > div {
}

.a-accordionCard--no-hover .a-accordionCard__secondary {
  @include breakpoint(large up) {
    position: absolute;
    bottom: 0;
    padding-bottom: 53px;
  }
}

.a-accordionCard--no-hover .a-accordionCard__inner li a {
}
