.o-accordionHorizontal {
}

.o-accordionHorizontal__inner {
  @include breakpoint(large up) {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: 1px solid $color__separator;
      z-index: 5;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}

.o-accordionHorizontal__wrapper {
  @include breakpoint(large up) {
    max-width: 9999px;
    border-top: 1px solid $color__separator;
    border-bottom: 1px solid $color__separator;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.o-accordionHorizontal__footer {
  margin-top: 20px;

  @include breakpoint(large up){
    margin-top: 23px;
  }
}

.o-accordionHorizontal__footer-cta {
  @extend %a-cta--dual-line;
}

.o-accordionHorizontal__items {
  @include breakpoint(large up) {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
  }
}

.o-accordionHorizontal__item {
  @include breakpoint(large up) {
    display: flex;
    flex-flow: row-reverse nowrap;
    flex-shrink: 0;
    width: 80px;
    border-left: 1px solid $color__separator;
    overflow: hidden;
    transition: all 0.3s $bezier--ease-in-out;
    box-shadow: inset 10px 0 10px -10px rgba(0,0,0,0);

    @include hover-focus {
      width: 200px;
      background: $color__ctaBackground;
      box-shadow: inset 10px 0 10px -10px rgba(0,0,0,0.3);
    }

    &:first-child {
      border-left: 0;
      box-shadow: none;

      @include hover-focus {
        box-shadow: none;
      }
    }

    &:last-child {
      border-right: 1px solid $color__separator;
    }
  }
}

.o-accordionHorizontal__item-title {
  @extend %f-h10;
  display: none;

  @include breakpoint(large up) {
    display: block;
    width: 80px;
    transition: opacity 0.3s $bezier--ease-in-out;
  }
}

.o-accordionHorizontal__item-trigger {
  @include breakpoint(large up) {
    display: block;
    width: 605px;
    height: 605px;
    padding: 27px 45px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transform: translateX(80px) rotate(90deg);
    transform-origin: left top;
  }
}

.o-accordionHorizontal__item-content {
  @include breakpoint(large up) {
    pointer-events: none;
  }
}

.a-accordionHorizontal--active.o-accordionHorizontal__item {
  @include breakpoint(large up) {
    background: $color__ctaBackground;
    box-shadow: inset 10px 0 10px -10px rgba(0,0,0,0.3);

    &:first-child {
      box-shadow: none;
    }
  }
}

.a-accordionHorizontal--active .o-accordionHorizontal__item-title {
  @include breakpoint(large up) {
    opacity: 0;
  }
}

.a-accordionHorizontal--active .o-accordionHorizontal__item-content {
  @include breakpoint(large up) {
    pointer-events: all;
  }
}


.o-accordionHorizontal .a-accordionCard {
  @include breakpoint(large up) {
    .a-accordionCard__body {
      background: transparent;
    }

    .a-accordionCard__body {
    }

    @include hover-focus {
      .a-accordionCard__body {
        background: transparent;
        color: $color__text;

        .a-accordionCard__cta {
          color: $color__highlight;
          border-color: $color__highlight;

          &:after {
            background-color: $color__highlight;
          }
        }

        .a-accordionCard__subtitle {
          color: $color__text--subtitle;
        }

        .a-accordionCard__hover-on .a-accordionCard__subtitle {
          color: $color__text;
        }
      }
    }
  }
}
