.o-shuffler {
}

.o-shuffler .m-moduleHeader__intro {
  margin-bottom: 20px;
}

.o-shuffler__items {
}

.o-shuffler__items .carousel {
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;

  @include breakpoint(medium up) {
    margin-left: 0;
    margin-right: 0;
  }
}

.o-shuffler .flickity-viewport {
  overflow: visible;

  @include breakpoint(medium up){
    overflow: hidden;
  }
}

.o-shuffler .carousel-cell {
  width: 100%;
  margin: 0 -30px;
  padding: 0 40px;

  @include breakpoint(medium up){
    width: 100%;
    margin: 0 10px;
    padding: 0;
  }
}

.o-shuffler .carousel-cell img {
  width: 100%;
}

.o-shuffler__carousel-image {
  margin: 0;
  opacity: 0.45;
  transition: opacity 0.3s $bezier--standard;

  .is-selected & {
    opacity: 1;
  }

  @include breakpoint(medium up){
    display: none;
  }
}

.o-shuffler__group {
  display: none;
  
  &.o-shuffler__group--active {
    display: block;
  }
}

.o-shuffler__images {
  display: none;

  @include breakpoint(medium up){
    display: block;
  }
}

.o-shuffler__images-inner {
  position: relative;
  height: 575px;
}

.o-shuffler__image {
  position: absolute;
  margin: 0;
  background-color: $color__black;

  &:nth-child(1) {
    bottom: percentage(85/575);
    left: percentage(395/840);
    z-index: 6;
  }

  &:nth-child(2) {
    top: percentage(110/575);
    left: percentage(280/840);
    z-index: 5;
  }

  &:nth-child(3) {
    bottom: percentage(15/575);
    left: 0;
    z-index: 4;
  }

  &:nth-child(4) {
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  &:nth-child(5) {
    top: percentage(30/575);
    left: percentage(79/840);
    z-index: 2;
  }

  &:nth-child(6) {
    top: 0;
    left: percentage(470/840);
    z-index: 1;
  }

  &:hover {
    z-index: 11;
  }

  img {
    display: block;
    opacity: 0.35;
    cursor: pointer;
    transition: opacity 0.2s $bezier--standard;
  }

  &:hover img {
    opacity: 0.75;
  }

  &.o-shuffler__image--active {
    z-index: 10;
  }

  &.o-shuffler__image--active img {
    opacity: 1;
  }
}

.o-shuffler__data {
  margin: 19px 0 0;
  opacity: 0;
  transition: opacity 0.3s $bezier--standard;

  .is-selected & {
    opacity: 1;
  }

  @include breakpoint(medium){
    margin: 30px 0 0;
    opacity: 1;
  }
}

.o-shuffler__data dt,
.o-shuffler__data dd {
}

.o-shuffler__data dt {
  @extend %f-h11;
  margin-top: 22px;
  color: $color__highlight;

  @include breakpoint(medium up){
    margin-top: 31px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.o-shuffler__data dd {
  @extend %f-h10;
  margin-top: 13px;

  @include breakpoint(medium up){
    margin-top: 9px;
  }
}

.o-shuffler__data .o-shuffler__data--large {
  @extend %f-h5;
  margin-top: 11px;

  @include breakpoint(medium up){
    margin-top: 6px;
  }
}

.o-shuffler__data .o-shuffler__data--large + dt {
  margin-top: 19px;

  @include breakpoint(medium up){
    margin-top: 29px;
  }
}

.o-shuffler__footer {
  display: none;

  @include breakpoint(medium up){
    display: block;
    margin-top: 30px;
  }
}

.o-shuffler__nav {
  @extend %f-h11;
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.o-shuffler__nav .icon {
  vertical-align: middle;
}

.o-shuffler__nav span {
  margin-left: 5px;
}

.o-shuffler__nav--next {
  margin-left: 5px;
}

.o-shuffler__nav--shuffle {
  margin-left: 40px;
}

.m-featurePanel + .o-shuffler {
  margin-top: 5px; // 50px - 45px;

  @include breakpoint(medium up) {
    margin-top: 65px; // 110px - 45px;
  }

  @include breakpoint(large up) {
    margin-top: 35px; // 110px - 75px;
  }
}
