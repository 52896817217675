.o-multimediaGallery {
  overflow: hidden;
  
  @include breakpoint(small down) {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include breakpoint(medium up) {
    // overflow: visible;
  }
}

.o-multimediaGallery .m-moduleHeader__intro {
  @include breakpoint(large up) {
    margin-bottom: 0;
  }
}

.o-multimediaGallery .flickity-viewport {
  overflow: visible;
}

.o-multimediaGallery__items {
  position: relative;
  z-index: 1;
}

.o-multimediaGallery__item {
  width: calc(100% - 20px);

  @include breakpoint(large up) {
    position: relative;
    z-index: 2;
    float: none !important;
    width: auto;
    margin-bottom: 40px;
    pointer-events: none;

    & > * {
      pointer-events: all;
    }

    .columns {
      float: none !important;
    }

    &:nth-child(1) .columns {
      margin-top: -40px;
    }

    // &:nth-child(2) .columns {
    //   margin-top: -180px;
    // }

  //   &:nth-child(6n-5) .columns {
  //     margin-top: -40px;
  //   }

  //   &:nth-child(6n-4) .columns {
  //     margin-top: -180px;
  //   }

  //   &:nth-child(6n-3) .columns {
  //     margin-top: -85px;
  //   }

  //   &:nth-child(6n-2) .columns {
  //     margin-top: -145px;
  //   }

  //   &:nth-child(6n-1) .columns {
  //     margin-top: -310px;
  //   }

  //   &:nth-child(6n) .columns {
  //     margin-top: -110px;
  //   }
  }
}

.o-multimediaGallery__footer {
  position: relative;
  z-index: 2;
  margin-top: 41px;

  @include breakpoint(large up) {
    margin-top: 38px;
  }
}

.o-multimediaGallery__items .m-post {
  width: 100%;
  height: auto;
}
