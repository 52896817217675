.o-fastFacts {
  margin-top: 30px;
  margin-bottom: 10px;

  @include breakpoint(large up) {
    margin-top: 65px;
    margin-bottom: 43px;
  }
}

.o-fastFacts__items {
  @include breakpoint(medium up){
    display: flex;
    flex-flow: row nowrap;
  }
}

.o-fastFacts__item {
  @extend %f-h8-b;
  float: none !important;
  margin-top: 23px;

  &:first-child {
    margin-top: 0;
  }

  @include breakpoint(medium up){
    margin-top: 0;
  }
}

.o-fastFacts__item-inner {
  padding-top: 20px;
  border-top: 1px solid $color__separator;

  @include breakpoint(medium up) {
    padding-top: 0;
    border-top: 0;
  }
}

.o-fastFacts__item:first-child .o-fastFacts__item-inner {
  padding-top: 0;
  border-top: 0;
}
