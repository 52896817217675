.m-collegeList {
}

.m-collegeList__item {
  margin-top: 19px;
  padding-top: 16px;

  @include breakpoint(large up) {
    margin-top: 0;
    padding-top: 0;
  }
}

.m-collegeList__toggle {
  @include breakpoint(medium down) {
    font-size: 16px;
    line-height: 25px;
  }
}

.m-collegeList__content {
  @include breakpoint(medium down) {
    padding-top: 17px;
  }
}

.m-collegeList__cta {
  @include breakpoint(medium down) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
