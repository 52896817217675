.o-heroGallery {
  // height: 300vh;
  margin-top: 19px;

  @include breakpoint(medium up) {
    margin-top: 39px;
    margin-bottom: 60px;
  }
}

.o-heroGallery__header {
  position: sticky;
  top: 107px;
  z-index: 5;

  @include breakpoint(large up) {
    top: 140px;    
  }
}

.o-heroGallery__title {
  @include title(25, 25, 700);

  @include breakpoint(small up) {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01rem;
  }

  @include breakpoint(medium up) {
    font-size: 50px;
    line-height: 50px;
  }
}

.o-heroGallery__title span {
  color: $color__highlight;
}

.o-heroGallery__images {
  margin-top: 21px;

  @include breakpoint(large up) {
    margin-top: 50px;
  }
}

.o-heroGallery__image {  
  position: relative;

  &:nth-child(1) {
    z-index: 1;
  }

  &:nth-child(2) {
    z-index: 2;
    margin-top: -3%;

    @include breakpoint(large up) {
      margin-top: -13%;
    }
  }

  &:nth-child(3) {
    z-index: 3;
    margin-top: -3%;
    
    @include breakpoint(large up) {
      margin-top: -16%;
    }
  }
}

.o-heroGallery__image .columns {
  float: none;
}

.o-heroGallery__images .a-img {
  margin: 0;
}

.o-heroGallery__images .a-img img {
  display: block;
  max-width: 100%;
}


.o-heroGallery__image--right .a-img {
  display: block;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);

  @include breakpoint(small up) {
    margin: 0 0 0 -30px;
    width: calc(100% + 30px);
  }

  @include breakpoint(large up) {
    margin: 0;
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    width: calc(100% + 45px);
  }

  @include breakpoint(xlarge up) {
    width: calc(100% + 60px);
  }

  @include breakpoint(xxlarge up) {
    min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
    width: 100%;
  }

  @include breakpoint(1740px) {
    // height: 585px;
    min-height: auto;
  }
}
