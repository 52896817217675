.o-twoThirds {
}

.o-twoThirds__inner {
  margin-top: 37px;

  @include breakpoint(large up) {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 42px;
  }
}

.o-twoThirds__aside,
.o-twoThirds__main {
  @include breakpoint(large up) {
    float: none !important; 
  }
}

.o-twoThirds__main {
}

.o-twoThirds__aside {
  margin-top: 45px;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.o-twoThirds__items {
}

.o-twoThirds__cta {
  @extend %a-cta--dual-line;
  margin-top: 28px;
}
