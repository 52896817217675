.a-btn--grey--light {
  border: 2px solid $color__separator;
  color: $color__grey--35;

  @include breakpoint(large up) {
    @include hover-focus {
      background: $color__separator;
      border: 2px solid $color__separator;
      color: $color__cta;
      cursor: pointer;
    }
  }

  .body--highlight & {
    border: 2px solid $color__white;
    color: $color__text--dark;

    @include hover-focus {
      background: $color__white;
      border: 2px solid $color__white;
      color: $color__highlight;
    }
  }
}
