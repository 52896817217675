.icon--facebook {
	height: 18px;
	width: 18px;
}

.icon--twitter {
	height: 18px;
	width: 18px;
}

.icon--youtube {
	height: 18px;
	width: 18px;
}

.icon--instagram {
	height: 18px;
	width: 18px;
}

.icon--arrow-circle-left,
.icon--arrow-circle-right,
.icon--shuffle-circle {
  width: 45px;
  height: 45px;
}

.icon--plus-alt {
  width: 13px;
  height: 13px;
}

.icon--minus {
  width: 13px;
  height: 3px;
}
