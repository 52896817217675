.o-heroFlyout {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: -200px;
  padding-top: 46px;

  @include breakpoint(large up) {
    display: block;
    min-height: 2765px;
    padding-top: 0;
    margin-bottom: 0;
  }
}

.o-heroFlyout__wrapper {
  @include breakpoint(large up) {
    height: 2765px;
    padding-bottom: 300px;
  }
}

.o-heroFlyout__title-wrap {
  position: relative;
  z-index: 5;
  margin-bottom: -8px;

  @include breakpoint(large up) {
    position: sticky;
    bottom: 44px;
    margin-bottom: 0;
  }
}

.o-heroFlyout__title {
  @extend %f-h18;
  float: none !important;
  margin: 0;
}

.o-heroFlyout__title span {
  color: $color__highlight;
}

.o-heroFlyout__inner {
  position: relative;
  height: 985px;

  @include breakpoint(medium up) {
    height: 1200px;
  }

  @include breakpoint(large up) {
    position: sticky;
    top: 95px;
    height: calc(100vh - 95px);
    margin-bottom: 500px;
    // height: 1965px;
  }
}

.o-heroFlyout__images {
  height: 985px;

  @include breakpoint(medium up) {
    height: 1200px;
  }

  @include breakpoint(large up) {
    position: relative;
    height: 2265px;
    // height: calc(100vh - 155px);
    // height: 100vh;
  }
}

.o-heroFlyout__images .a-img {
  position: absolute;
  margin: 0;
  float: none !important;
  opacity: 0;

  @include breakpoint(large up) {
    top: 25%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    &:nth-child(1){ // 6th
      top: 25%;
      left: 50%;
      z-index: 6;
      animation-delay: 1200ms;

      img {
        max-width: 440px;
      }
    }

    &:nth-child(2){ // 1st
      z-index: 1;
      animation-delay: 200ms;

      img {
        max-width: 440px;
      }
    }

    &:nth-child(3){ // 4th
      z-index: 4;
      animation-delay: 800ms;

      img {
        max-width: 365px;
      }
    }

    &:nth-child(4){ // 3rd
      z-index: 3;
      animation-delay: 600ms;

      img {
        max-width: 580px;
      }
    }

    &:nth-child(5){ // 5th
      z-index: 5;
      animation-delay: 1000ms;

      img {
        max-width: 520px;
      }
    }

    &:nth-child(6){ // 2nd
      top: auto;
      z-index: 2;
      animation-delay: 400ms;

      img {
        max-width: 440px;
      }
    }
  }

  @include breakpoint(medium down) {
    &:nth-child(1){
      top: 200px;
      left: 30px;
      z-index: 3;
    }

    &:nth-child(2){
      top: 0;
      left: -30px;
      z-index: 2;
    }

    &:nth-child(3){
      top: 80px;
      right: -30px;
      z-index: 1;
    }

    &:nth-child(4){
      top: 406px;
      right: -30px;
      z-index: 4;
    }

    &:nth-child(5){
      top: 475px;
      left: -30px;
      z-index: 5;
    }

    &:nth-child(6){
      top: 605px;
      right: 30px;
      z-index: 6;
    }
  }

  @include breakpoint(small down) {
    &:nth-child(1){
      left: 20px;
    }

    &:nth-child(2){
      left: -20px;
    }

    &:nth-child(3){
      right: -10px;
      padding-right: 0;
    }

    &:nth-child(4){
      right: -10px;
      padding-right: 0;
    }

    &:nth-child(5){
      left: -20px;
    }

    &:nth-child(6){
      right: 20px;
    }
  }
}

.o-heroFlyout__images .a-img img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.o-heroFlyout--active .o-heroFlyout__images .a-img {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.1,0.3,0.25,1);

  @include breakpoint(large up) {
    &:nth-child(n+2){
      left: auto;
    }
  }
}
