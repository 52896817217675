.g-breadcrumbs {
  @include breakpoint(large up) {
    max-width: calc(100% - 80px);
  }

  @include breakpoint(xlarge up) {
    max-width: calc(100% - 120px);
  }

  @include breakpoint(xxlarge up) {
    max-width: 80%;
  }
}
