// Main Color List - try not to use
$color__red: #E42A1D;
$color__red--dark: #A22017;
$color__yellow: #FFE000;
$color__green: #169B62;

// Colors
$color__black: #000000;
$color__black--98: #050505;
$color__black--92: #141414;
$color__black--90: #191919;
$color__black--87: #212121;
$color__black--80: #333333;
$color__black--73: #444444;

$color__grey--65: #585858;
$color__grey--55: #A7A9AC;
$color__grey--46: #767676;
$color__grey--43: #8c8c8c;
$color__grey--40: #A7A9AC;
$color__grey--35: #8C8C8C;
$color__grey--30: #B2B2B2;
$color__grey--15: #D9D9D9;
$color__grey--13: #F1F2F2;
$color__grey--10: #E5E5E5;
$color__grey--5: #F2F2F2;
$color__grey--4: #F5F5F5;

$color__white: #FFFFFF;

$color__alert--important: $color__red;
$color__alert--information: $color__green;
$color__alert--announcement: $color__yellow;

// Global
$color__background: $color__white;
$color__background--dark: $color__black--90;
$color__background--light: $color__grey--4;
$color__text: $color__black--90;
$color__text--dark: $color__white;
$color__text--subtitle: $color__grey--43;
$color__error: $color__red;
$color__separator: $color__grey--15;
$color__separator--dark: $color__black--80;
$color__highlight: $color__red;
$color__highlight--dark: $color__red--dark;
$color__text-hover: $color__grey--65;

// Navigation
$color__nav: $color__black--90;
$color__secondaryNav: $color__grey--46;
$color__navSplitter: $color__grey--15;
$color__menu: $color__black--87;
$color__navRelated: $color__grey--30;
$color__navAdditionalLinks: $color__grey--30;
$color__nav--highlight: $color__red;
$color__nav--hover: $color__red;
$color__nav--hover-dark: $color__red--dark;
$color__tertiaryNav-background: $color__black--98;
$color__footerNav-background: $color__black--98;
$color__tertiaryNav-link: $color__grey--30;
$color__mobileMenuBackground: $color__grey--5;
$color__utilNavTertiary: $color__grey--43;
$color__utilNavBackground: $color__black--92;
$color__utilNavSplitter: $color__grey--65;
$color__utilNavIcon: $color__grey--55;

// Page Nav
$color__pageHeaderBackgorund--filters: $color__grey--13;
$color__pageHeaderItem--filters: $color__grey--55;

// Footer
$color__footerBackground: $color__black--98;
$color__footerSocialBackground: $color__black--87;
$color__footerGrey: $color__grey--55;
$color__footerSocialSeparator: $color__black--80;

// CTA
$color__cta: $color__white;
$color__cta--alt: $color__red--dark;
$color__ctaBackground: $color__grey--13;

// Figcaption
$color__figcaption: $color__grey--46;

// Block Link
$color__meta: $color__grey--46;

// Drawer
$color__toggleIcon: $color__grey--15;
$color__toggleIconHover: $color__grey--65;

// AZList
$color__azListTitle: $color__text;
$color__azListItem: $color__grey--46;

// Update
$color__nav--tertiary: $color__grey--30;

// Sidebar Modules
$color__borderTop: $color__black--80;
$color__sidebarText: $color__text;
$color__sidebarNavigationBackground: $color__grey--5;
$color__sidebarNavigationSeparator: $color__grey--30;

// Breadcrumbs
$color__breadcrumbsItem: $color__text;
$color__breadcrumbsLink: $color__grey--46;

// Quote
$color__quoteSeparator: $color__black--90;

// Content Panels
$color__contentPanelsBackground: $color__grey--10;
$color__contentPanelBackground: $color__white;

// Table
$color__tableAlternateBackground: $color__grey--5;
$color__tableFooter: $color__grey--46;

// Video Carousel
$color__videoCarouselBackground: $color__black--87;
$color__videoCarouselSeparator: $color__black--80;

// Search
$color__searchPlaceholder: $color__grey--15;
$color__searchResultsSeparator: $color__grey--15;
$color__searchFilterLink: $color__grey--35;
$color__searchResultSummary: $color__grey--35;
$color__searchResultMore: $color__grey--46;
$color__searchResultMoreBorder: $color__grey--15;
$color__searchResultMoreDisabled: $color__grey--5;
$color__searchResultHighlightBg: $color__grey--13;

// Map
$color__mapFiltersBackground: $color__black--87;
$color__mapFilter: $color__grey--40;
$color__mapLocationNumber: $color__grey--65;
$color__mapInfoWindowFooter: $color__grey--30;
$color__mapInfoWindowFooterBackground: $color__black--87;

// Timeline
$color__timelineContentBackground: $color__black--87;
$color__timelineIcon--active: $color__text;

// Full Width Carousel
$color__fullWidthCarousel: $color__black--98;
$color__diagonalCarousel: $color__black--98;

// Social
$color__socialItemBackground: $color__grey--5;
$color__socialitemIcons: $color__grey--30;
$color__socialCTA: $color__grey--46;
$color__socialCTABorder: $color__grey--15;

// Landing
$color__landingBackground--highlight: $color__highlight;
$color__landingBackground--dark: $color__black--98;
$color__landingText--dark: $color__black--98;

// Fade Carousel
$color__fadeCarouselArrow: $color__grey--30;

// Filters
$color__filterListBackground: $color__grey--5;
$color__filterListBackground--dark: $color__black--87;
$color__filterSearchIcon: $color__grey--55;
$color__filterSeparator--dark: $color__black--73;
$color__filterClear: $color__grey--30;
$color__filterBorder: $color__black--80;

// Stats
$color__statsSeparator: $color__grey--15;
$color__statsSeparator--light: $color__black--80;
$color__statsSeparator--dark: #C82A1F;
$color__statsSeparator--black: $color__black--80;
$color__statsSeparator--highlight: $color__black--90;

// College List
$color__collegeListNotActive: $color__grey--40;

// Calendar
$color__calendarBackground: $color__grey--5;
$color__calendarEventBackground: $color__grey--10;
$color__calendarEventBackground--hover: $color__grey--15;

// Pathways
$color__pathwaysCTA: $color__grey--15;
$color__pathwaysBackground: $color__black--87;
$color__pathwaysIconBorder: $color__black--80;

// Buttons
$color__btn--grey: $color__grey--46;

// Cards
$color__cardBackgroundDark: $color__black--87;

// Events
$color__events__text--dark: $color__grey--30;
$color__events__subtitle: $color__grey--65;

// Hero
$color__hero__arrow--light: $color__grey--55;

// Dropdowns
$color__dropdown__border: $color__grey--15;
$color__dropdown__border--hover: $color__grey--30;
$color__dropdown__label: $color__grey--43;
$color__dropdown--active: $color__black;
