.m-pub {
}

.m-pub > a {
  display: block;
  text-decoration: none;

  @include hover-focus {
    .m-pub__title {
      color: $color__highlight;
    }
  }
}

.m-pub__image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px;
  
  @include breakpoint(large up) {
  }
}

.m-pub__image-inner {
  @include breakpoint(large up) {
    // text-align: center;
  }
}

.m-pub__image .a-img {
  display: inline-block;
  position: relative;
  max-height: 275px;
  margin: 0;

  &::after {
    background: $color__highlight;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    transition: transform ease-in-out 360ms;
  }

  a:hover & {
    &:after {
      transform: translate3D(10px, 10px, 0);
    }

    img {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      transform: translate3D(-10px, -10px, 0);
    }
  }
}

.m-pub__image img {
  display: block;
  position: relative;
  z-index: 2;
  max-width: 100%;
  margin: 0 auto;
  object-fit: contain;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
  transition: transform ease-in-out 360ms, box-shadow ease-in-out 360ms;
}

.m-pub__title {
  @extend %f-h14;
  margin: 22px 0 0;
  color: inherit;
}

.m-pub__subtitle {
  @extend %f-h10;
  margin: 0;
}

.m-pub__meta {
  @extend %f-h10;
  display: block;
  margin-top: 5px;
  color: $color__text--subtitle;

  @include breakpoint(small up) {
    line-height: 20px;
  }
}


.body--highlight,
.s-module-bkgd--highlight {
  .m-pub__title {
    color: $color__text;
  }

  .m-pub__meta {
    color: rgba($color__text--dark, 0.4);
  }

  .m-pub__image .a-img::after {
    background-color: $color__background--dark;
  }

  a {
    @include hover-focus{
      .m-pub__title {
        color: $color__text;
      }
    }
  }
}
