.o-three-col {
  overflow: hidden;
}

.o-three-col .m-moduleHeader__intro {
  @include breakpoint(small down) {
    margin-bottom: 35px;
  }
}

.o-three-col__items {
  @include breakpoint(large up){
    display: flex;
    flex-flow: row wrap;

    &::before,
    &::after {
      display: none;
    }
  }
}

.o-three-col__item {
  float: none !important;
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child .a-accordionCard {
    border-bottom: 1px solid $color__separator;
  }

  @include breakpoint(large up){
    border-left: 1px solid $color__separator;

    &:nth-child(3n+1) {
      border-left: 0;
    }

    &:nth-child(-n+3) {
      margin-top: 0;
    }

    &:last-child .a-accordionCard {
      border-bottom: 0;
    }

    .flickity-viewport & {
      margin-top: 0;
    }
  }
}

.o-three-col__item > .m-post {
  padding-top: 30px;
  border-top: 1px solid $color__separator;

  @include breakpoint(large up) {
    padding-top: 0;
    border-top: 0;
  }
}

.o-three-col__items:first-child .o-three-col__item:first-child > .m-post {
  padding-top: 0;
  border-top: 0;
}

.o-three-col__footer {
  margin-top: 20px;

  @include breakpoint(large up){
    margin-top: 23px;
  }
}

.o-three-col__footer-cta {
  @extend %a-cta--dual-line;
  margin-bottom: 4px;
}

.o-three-col__nav {
  display: none;
  text-align: right;

  @include breakpoint(large up) {
    &.show {
      display: block;
    }
  }
}

.o-three-col__nav-item {
  @extend %f-h11;
  margin: 0 0 20px;
  padding: 0;
  border: 0;
  background: none;
  color: $color__grey--40;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $color__highlight;
  }
}

.o-three-col__nav-item .icon {
  vertical-align: middle;
}

.o-three-col__nav--next {
  margin-left: 5px;
}

.s-module-bkgd--dark .o-three-col__item {
  @include breakpoint(large up){
    border-color: $color__separator--dark;
  }
}


.o-three-col--carousel {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;

  @include breakpoint(medium) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include breakpoint(large up) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.o-three-col--carousel .m-moduleHeader__intro {
  @include breakpoint(large up) {
    margin-bottom: 17px;
  }
}

.o-three-col--carousel .o-three-col__items {
  @include breakpoint(large up){
    display: block;
  }
}

.o-three-col--carousel .flickity-viewport {
  width: 100%;
  
  @include breakpoint(medium down){
    overflow: visible;
  }

  @include breakpoint(large up) {
    margin-top: -10px;
  }
}

.o-three-col--carousel .flickity-slider {
  @include breakpoint(large up) {
    padding-top: 10px;
  }
}

.o-three-col--carousel .flickity-viewport .o-three-col__item {
  @include breakpoint(medium down){
    margin-top: 0;
  }

  @include breakpoint(large up){
    padding-bottom: 10px;

    &:nth-child(3n+1) {
      // border-left: 1px solid $color__separator;
    }
  }
}

.o-three-col--carousel.o-three-col--set-height .o-three-col__item {
  @include breakpoint(large up) {
    height: 100%;
  }
}

.o-three-col--no-divider .o-three-col__item {
  border-left: 0;

  @include breakpoint(small down) {
    margin-top: 0;
  }
}

.o-three-col--carousel .flickity-viewport .m-post,
.o-heroGallery + .o-three-col .o-three-col__item:first-child > .m-post {
  padding-top: 0;
  border-top: 0;
}
