.s-sticky-body {
  position: relative;
}

.s-sticky-body__sticky {
}

.s-sticky-body--abs,
.s-sticky-body--active {
  position: fixed;
  top: auto;
  bottom: 49px;
}

.s-sticky-body--abs {
  position: absolute;
}

.s-sticky-body--sticky {
  position: sticky;
  top: 120px;
  bottom: auto;
}
