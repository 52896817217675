.cta--double-line {
  display: inline-block;
  position: relative;
  border-bottom: 2px solid $color__highlight;
  color: $color__highlight;
  font-weight: 900;
  line-height: 15px;
  font-size: 10px;
  letter-spacing: 0.05rem;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3D(0, 0, 0);

  &:after {
    background: $color__highlight;
    bottom: -6px;
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:hover {
    border-bottom-width: 2px;
  }
}

.btn--link {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  outline: 0;
}
