.m-landingIntro {
}

.m-landingIntro p {
}

.m-landingIntro--large {
  margin-bottom: -7px;

  @include breakpoint(medium up) {
    margin-bottom: -8px;
  }
  @include breakpoint(large up) {
    margin-bottom: -9px;
  }
}

.m-landingIntro--large p {
  @extend %f-h6;
}

.body--highlight .m-landingIntro p a {
  border-color: $color__white;
}
