.o-quotePortrait {
  @include full-bleed;
}

.o-quotePortrait__inner {
  @include breakpoint(medium up){
    display: flex;
    flex-flow: row nowrap;
  }
}

.o-quotePortrait__images {
  float: right;
  margin-left: 30px;

  @include breakpoint(small down){
    padding-left: 0;
    width: 130px;
  }

  @include breakpoint(medium up){
    float: none;
    margin-left: 0;
  }
}

.o-quotePortrait__images-inner {
  @include breakpoint(medium up){
    display: inline-block;
    position: relative;
    padding-bottom: 30px;
  }

  @include breakpoint(large up){
    padding-bottom: 40px;
  }
}

.o-quotePortrait__image {
  @include breakpoint(medium up){
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: $color__background--dark;
      // background: rgba($color__background--dark, 0.2);
    }
  }
}

.o-quotePortrait__image,
.o-quotePortrait__image img {
  transition: opacity 0.3s $bezier--standard;
}


.o-quotePortrait__image:first-child {
  @include breakpoint(medium up){
    z-index: 2;
  }
}

.o-quotePortrait__image:last-child:not(:first-child) {
  display: none;

  @include breakpoint(medium up){
    display: block;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    // transform: translate3d(30px, -100%, 0);

    img {
      opacity: 0.2;
    }
  }

  @include breakpoint(large up){
    top: 40px;
    left: 40px;
    // margin-top: 40px;
    // transform: translate3d(40px, -100%, 0);
  }
}

.o-quotePortrait__image img {
  display: block;
  position: relative;
  z-index: 2;
  max-width: 100%;
}



.o-quotePortrait--hover img {
  @include breakpoint(medium up){    
    opacity: 0.2;
  }
}

.o-quotePortrait__image {
  @include breakpoint(medium up){
    @include hover-focus {
      z-index: 5;

      img {
        opacity: 1;
      }
    }
  }
}

.o-quotePortrait__main {
  position: relative;
  float: none !important;

  @include breakpoint(medium up) {
    padding-bottom: 48px;
  }
}

.o-quotePortrait__main blockquote {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0;
}

.o-quotePortrait__main blockquote p {
  @extend %f-h19;
}

.o-quotePortrait__main blockquote p:first-child::before {
  content: '“';
}

.o-quotePortrait__main blockquote p:last-of-type::after {
  content: '”';
}

.o-quotePortrait__main blockquote cite {
  @extend %f-h10;
  display: block;
  margin-top: 14px;

  @include breakpoint(medium up){
    margin-top: 25px;
  }
}

.o-quotePortrait__main blockquote cite strong {
}

.o-quotePortrait__cta {
  @extend %a-cta--dual-line;
  margin-top: 23px;

  @include breakpoint(medium up){
    position: absolute;
    bottom: 3px;
  }
}
