// utility nav not shown on mobile so we have to override the top val from a previous phase
.g-header--has-utility-on-mobile { 
  top: 0;
}

.g-header--college.g-header--has-utility-on-mobile + main {
  @include breakpoint(small down) {
    padding-top: 70px;
  }

  @include breakpoint(medium) {
    padding-top: 85px;
  }
}
