.m-textBlock {
}

.m-textBlock:last-child {
  padding-bottom: 0;
}

.m-textBlock h2 {
  @include breakpoint(large up) {
    margin-top: 45px;
  }
}

.m-textBlock h5 {
}

.m-textBlock ul {
}

.m-textBlock.o-primaryContent h5 + ul {
  margin-top: 0;
}

.m-textBlock.o-primaryContent ul li {
  margin-top: 0;
}

.m-textBlock.o-primaryContent .inline-cta {
  display: inline-block;
  margin-top: 0;
  color: $color__highlight;

  &::before {
    top: 7px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23E82E21' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
  }
}

[data-ux-pagebuilder] > ul > li:first-child .m-textBlock.add-separator--top:first-child,
[data-ux-pagebuilder] > ul > li:first-child span + .m-textBlock.add-separator--top {
  padding-top: 0;

  &:before {
    display: none;
  }

  h2:first-child {
    // margin-top: 0;
  }
}
