body {
  overflow: visible;
}

.o-quoteMedia {
  position: relative;

  @include breakpoint(large up) {
    height: 200vh;
  }
}

.o-quoteMedia--video {
}

.o-quoteMedia__inner {
  @include breakpoint(large up) {
    position: sticky;
    top: 170px;
    left: 0;
  }
}

.o-quoteMedia__main {
  @include breakpoint(large up) {
    position: absolute;
    top: 75px;
    z-index: 2;
    transition: transform 0.1s linear;
  }
}

.o-quoteMedia__main blockquote {
  margin: 0;
  padding: 0;
  border: 0;
}

.o-quoteMedia__main blockquote p {
  @extend %f-h4;
}

.o-quoteMedia__main blockquote cite {
  @extend %f-h10;
  display: block;
  margin-top: 25px;

  @include breakpoint(large up) {
    width: 30%;
    margin-top: 28px;
  }

  &::before {
    content: '\2014';
    margin-right: 5px;
  }
}

.o-quoteMedia__main blockquote p:first-child::before {
  content: '“';
}

.o-quoteMedia__main blockquote p:last-of-type::after {
  content: '”';
}

.o-quoteMedia__media {
  margin-top: 25px;

  @include breakpoint(large up){
    margin-top: 80px;
    opacity: 0.25;
  }
}

.o-quoteMedia__media .a-img {
  margin: 0;
}

.o-quoteMedia__media .a-img__image {
  display: block;
  max-width: 100%;
}
