.o-heroFullScreen {
  @include full-bleed;
  margin-top: -70px;
  padding-bottom: 25px;

  @include breakpoint(medium up) {
    margin-top: -85px;
  }

  @include breakpoint(large up) {
    margin-top: -100px;
    padding-bottom: 74px;
  }
}

.o-heroFullScreen .icon--chevron-thin-down {
  display: none;

  @include breakpoint(large up) {
    display: block;
    position: fixed;
    bottom: 50px;
    right: 60px;
    z-index: 30;
    width: 30px;
    height: 30px;
    fill: $color__background;
    cursor: pointer;
  }
}

.o-heroFullScreen__main {
  position: relative;
}

.o-heroFullScreen__main-image {
}

.o-heroFullScreen__main-image .a-img {
  position: relative;
  width: 100vw;
  min-height: 240px;
  margin: 0 -10px;

  @include breakpoint(medium up) {
    margin: 0 -20px;
    height: 100vh;
    min-height: 800px;
  }

  @include breakpoint(large up) {
    position: relative;
    height: calc(100vh - 60px);
    margin: 0 -30px;
    overflow: hidden;
  }

  @include breakpoint(xlarge up) {
    margin: 0 -40px;
  }

  @include breakpoint(xxlarge up) {
    margin: 0 -60px;
  }
}

.o-heroFullScreen__main-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 110px;
  margin-left: -10px;
  margin-right: -10px;
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0));
  pointer-events: none;

  @include breakpoint(medium up) {
    height: 150px;
    margin-left: -20px;
    margin-right: -20px;
  }

  @include breakpoint(large up) {
    margin-left: -30px;
    margin-right: -30px;
  }

  @include breakpoint(xlarge up) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @include breakpoint(xxlarge up) {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.o-heroFullScreen__main-image .a-img__image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.o-heroFullScreen__main-title {
  @extend %f-h18;
  position: absolute;
  bottom: 13px;

  @include breakpoint(large up) {
    position: sticky;
    bottom: 50px;
  }
}

.o-heroFullScreen__main-title span {
  display: block;
  float: none !important;
}

.o-heroFullScreen__title {
  @extend %f-h1;
  position: absolute;
  bottom: 12px;
  z-index: 26; // to sit over videos
  float: none !important;
  margin-top: 0;
  margin-bottom: 0;
  color: $color__text--dark;

  @include breakpoint(large up) {
    position: sticky;
    bottom: 46px;
  }
}

.o-heroFullScreen__text {
  @extend %f-h6;
  position: relative;
  z-index: 27; // to sit over titles which sit over videos
  padding-top: 32px;
  padding-bottom: 34px;

  @include breakpoint(large up) {
    padding-top: 83px;
    padding-bottom: 107px;
  }
}

.o-heroFullScreen__text--last {
  @include breakpoint(large up) {
    padding-bottom: 69px;
  }
}

.o-heroFullScreen__text p:first-child {
  margin-top: 0;
}

.o-heroFullScreen__item {
  position: relative;

  @include breakpoint(large up) {
  padding-bottom: 46px;
  }
}

.o-heroFullScreen__item .a-video.is-active {
  position: relative;
  z-index: 25;
}

.o-heroFullScreen__item .a-video__link,
.o-heroFullScreen__item .a-video__figure {
  float: none !important;
}

.o-heroFullScreen__item .a-img,
.o-heroFullScreen__item .a-video,
.o-heroFullScreen__item .a-video__link {
  margin: 0;
}

.o-heroFullScreen__item .a-img__image,
.o-heroFullScreen__item .a-video {
  display: block;
  width: calc(100% + 20px);
  margin-right: -20px;

  @include breakpoint(medium up) {
    width: calc(100% + 30px);
    margin-right: -30px;
  }

  @include breakpoint(large up) {
    width: calc(100% + 45px);
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    margin-right: -45px;
  }

  @include breakpoint(xlarge up) {
    width: calc(100% + 60px);
    margin-right: -60px;
  }

  @include breakpoint(xxlarge up) {
    width: calc(100% + 80px);
    min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
    margin-right: -80px;
  }

  @include breakpoint(1740px) {
    min-height: auto;
  }
}

.o-heroFullScreen__item .a-video {
  min-height: 0;
}

.o-heroFullScreen__item .a-img__image {
  display: block;
  max-width: 100%;
}

.o-heroFullScreen__item:nth-child(even) .o-heroFullScreen__title {
  left: 15px;
}

.o-heroFullScreen__item:nth-child(even) .o-heroFullScreen__title span {  
  @include breakpoint(large up) {
    float: right !important;
    text-align: right;
  }
}

.o-heroFullScreen__item:nth-child(odd) .o-heroFullScreen__title span {  
  left: 0;
}

.o-heroFullScreen__item:nth-child(odd) .a-img,
.o-heroFullScreen__item:nth-child(odd) .a-video {
  left: 0;
}

.o-heroFullScreen__item:nth-child(odd) .a-img__image,
.o-heroFullScreen__item:nth-child(odd) .a-video {
  margin-right: 0;
  margin-left: -20px;

  @include breakpoint(medium up) {
    margin-left: -40px;
  }

  @include breakpoint(large up) {
    margin-left: -60px;
  }
}

.o-heroFullScreen__item:nth-child(odd) .a-video__figure {
  padding-left: 0;
}

.o-heroFullScreen__item:nth-child(even) .a-video__figure {
  padding-right: 0;
}

.dark .o-heroFullScreen,
.dark .o-heroFullScreen__item,
.dark .o-heroFullScreen__text {
  background-color: $color__background--dark;
  color: $color__text--dark;
}

.o-heroFullScreen,
.o-heroFullScreen__item,
.o-heroFullScreen__text {
  background-color: $color__background;
  color: $color__text;
  transition: background-color 0.2s $bezier--ease-in-out, color 0.2s $bezier--ease-in-out;
}
