.o-heroSidebySide {
  @include breakpoint(medium up) {
    margin-bottom: 50px;
  }

  @include breakpoint(large up) {
    margin-bottom: 75px;
  }
}

.o-heroSidebySide__inner {
  display: flex;
  flex-flow: column-reverse nowrap;

  @include breakpoint(large up) {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.o-heroSidebySide__main {
  position: relative;
  z-index: 5;
}

.o-heroSidebySide__title {
  @extend %f-h2;

  @include breakpoint(large up) {
    width: calc(100% + 80px);
  }
}

.o-heroSidebySide__title strong {
  color: $color__highlight;
}

.o-heroSidebySide__media {
}

.o-heroSidebySide__media .a-img,
.o-heroSidebySide__media .a-video__link {
  margin: 0;
}

.o-heroSidebySide__media img {
  display: block;
  max-width: 100%;
}


.o-heroSidebySide__media--right .a-img,
.o-heroSidebySide__media--right .a-video {
  display: block;
  margin: 0 0 22px -20px;
  width: calc(100% + 40px);

  @include breakpoint(small up) {
    margin: 0 0 22px -30px;
    width: calc(100% + 30px);
  }

  @include breakpoint(large up) {
    margin: 0;
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    width: calc(100% + 45px);
  }

  @include breakpoint(xlarge up) {
    width: calc(100% + 60px);
  }

  @include breakpoint(xxlarge up) {
    min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
    width: 100%;
  }

  @include breakpoint(1740px) {
    // height: 585px;
    min-height: auto;
  }
}
