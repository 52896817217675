.m-sidebarModule {
  margin-bottom: 35px;

  @include breakpoint(large up) {
    margin-bottom: 50px;
  }
}

.m-sidebarModule__content iframe {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.m-sidebarModule--events {
}

.m-sidebarModule__events {
  @include breakpoint(large up) {
    padding-bottom: 23px;
    border-bottom: 1px solid $color__separator;
  }
}

.m-sidebarModule__events li {
  margin-top: 23px;
  padding-top: 18px;
  border-top: 1px solid $color__separator;

  &:first-child {
    // margin-top: 0;
    // padding-top: 0;
    // border-top: 0;
  }
}

.m-sidebarModule__events a {
  display: block;
  text-decoration: none;

  @include hover-focus {
    color: $color__highlight;
  }
}

.m-sidebarModule__events-meta {
  @extend %f-h11;
  display: block;
  color: $color__text--subtitle;
}

.m-sidebarModule__events-title {
  @extend %f-h10;
  margin: 0;
}

.m-sidebarNavigation + .m-sidebarModule {
  @include breakpoint(medium down) {
    margin-top: 35px;
  }
}

.m-sidebar-dropzone {
  margin-top: 40px;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}
