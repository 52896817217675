.m-contentList--grid-view .a-program--simple {
  border-top: 1px solid $color__separator;

  &.is-active::before,
  &.is-active::after {
    bottom: -1px;
  }

  .a-program__blockLink {
    padding-top: 13px;
    padding-bottom: 18px;

    @include breakpoint(medium up){
      padding-top: 27px;
      padding-bottom: 31px;
    }
  }

  .a-program__blockLinkTitle {
    @extend %f-h8;
    position: relative;
    margin-top: 0;
    padding-right: 25px;
  }

  .a-program__blockLinkSubTitle {
    display: none;
  }

  .icon--chevron-down-alt {
    position: absolute;
    top: 10px;
    right: 0;
    width: 12px;
    height: 8px;

    @include breakpoint(xlarge up){
      top: 15px;
    }
  }
}

.a-program__description .a-program__description-list {
  padding-left: 1.25em;
  list-style-type: square;
}

.m-contentList--list-view .a-program--simple {
  .icon--chevron-down-alt {
    display: none;
  }
}

.m-contentList--grid-view .a-program__content--alt,
.m-contentList--list-view .a-program__content--alt {
  margin-bottom: 0;
  // padding-bottom: 0;
  border-bottom: 0;

  @include breakpoint(large up){
    padding-bottom: 95px;
  }

  .a-program__contentTitle {
    display: block;
    margin-top: 0;
  }

  .a-program__description {
    @extend %f-h15;
    margin-top: 10px;

    @include breakpoint(large up){
      margin-top: 30px;
    }
  }

  .a-program__description p {
  }

  .a-program__description ul {
    margin-top: 17px;
  }

  .a-program__description li a {
    margin-top: 0;
  }

  .a-program__description li a::before {
    @include breakpoint(large up){
      top: 4px;
    }

    @include breakpoint(xlarge up){
      top: 9px;
    }

    @include breakpoint(xxlarge up){
      top: 10px;
    }
  }

  .a-program__description h5 {
    margin-top: 13px;
  }

  .a-program__description p.a-program__note {
    @extend %f-h10;
  }
}


.m-contentList--grid-view .a-program--alt {
  .icon--plus-alt {
    display: none;
  }
}

.m-contentList--list-view .a-program--alt {
  .a-program__blockLinkTitle {
    @include breakpoint(large up) {
      width: 86%;
    }
  }

  .a-program__blockLink {
    position: relative;
    padding-right: 25px;

    &:hover .icon--plus-alt {
      color: $color__toggleIconHover;
    }
  }

  .icon--plus-alt {
    position: absolute;
    top: 50%;
    right: 0;
    color: $color__toggleIcon;
    transform: translateY(-50%);
  }
}


.o-primaryContent ul .a-program ul,
.o-primaryContent ul .a-program ol,
.o-primaryContent ul .a-program__description ul,
.o-primaryContent ul .a-program__description ol {
  padding-left: 0;

  li {
    margin-top: 0;
  }

  li::before {
    display: none;
  }
  
  li a {
    border: 0;
  }
  
  li:first-child a {
    margin-top: 0;
  }
}
