.o-primaryContent .a-table ul,
.o-primaryContent .a-table ol,
.o-primaryContent .a-table li,
.a-table p {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.o-primaryContent .a-table ul li {
  &::before {
    top: 8px;
  }
}
