.o-featuredPosts__twoThirds {
  @include breakpoint(medium up) {
    margin-top: 37px;
  }

  @include breakpoint(large up) {
    margin-top: 42px;
  }
}

.o-featuredPosts__threeCol {
  @include breakpoint(medium up) {
    margin-top: 37px;
  }

  @include breakpoint(large up) {
    margin-top: 103px;
  }
}

.o-featuredPosts .m-post {
  @include breakpoint(small down) {
    margin-top: 45px;
  }
}
