%f-h1,
.f-h1 {
  @include title(25, 25, 700);

  @include breakpoint(small up) {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01rem;
  }

  @include breakpoint(medium up) {
    font-size: 65px;
    line-height: 65px;
  }

  @include breakpoint(large up) {
    font-size: 85px;
    line-height: 80px;
    letter-spacing: -0.03rem;
  }

  @include breakpoint(xlarge up) {
    font-size: 110px;
    line-height: 100px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 130px;
    line-height: 125px;
  }
}

%f-h18,
.f-h18 {
  @include title(25, 25, 700, normal, -0.01rem);

  @include breakpoint(medium up) {
    font-size: 50px;
    line-height: 50px;
  }

  @include breakpoint(large up) {
    font-size: 55px;
    line-height: 55px;
  }

  @include breakpoint(xlarge up) {
    font-size: 70px;
    line-height: 70px;
    letter-spacing: -0.02rem;
  }

  @include breakpoint(xxlarge up) {
    font-size: 85px;
    line-height: 85px;
  }
}

%f-h2,
.f-h2 {
  @include title(25, 25, 700);

  @include breakpoint(large up) {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01rem;
  }

  @include breakpoint(xlarge up) {
    font-size: 50px;
    line-height: 50px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 60px;
    line-height: 60px;
  }
}

%f-h3,
.f-h3 {
  @include title(18, 18, 700);

  @include breakpoint(large up) {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.01rem;
  }

  @include breakpoint(xlarge up) {
    font-size: 35px;
    line-height: 35px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 40px;
    line-height: 40px;
  }
}

%f-h20,
.f-h20 {
  @include sans-serif(30, 40, 900);

  @include breakpoint(small up) {
    font-size: 32px;
    line-height: 40px;
  }

  @include breakpoint(medium up) {
    font-size: 38px;
    line-height: 45px;
  }

  @include breakpoint(large up) {
    font-size: 50px;
    line-height: 60px;
  }
}

%f-h4,
.f-h4 {
  @include sans-serif(22, 30, 900);

  @include breakpoint(small up) {
    font-size: 25px;
    line-height: 35px;
  }

  @include breakpoint(medium up) {
    font-size: 30px;
    line-height: 40px;
  }

  @include breakpoint(large up) {
    font-size: 40px;
    line-height: 50px;
  }
}

%f-h44,
.f-h44 {
  @include sans-serif(25, 25, 900);

  @include breakpoint(large up) {
    font-size: 30px;
    line-height: 35px;
  }
}

%f-h5,
.f-h5 {
  @include sans-serif(18, 30, 900);

  @include breakpoint(medium up) {
    font-size: 22px;
    line-height: 35px;
  }
}

%f-h6,
.f-h6 {
  @include sans-serif(14, 25, 400);

  @include breakpoint(medium up) {
    font-size: 18px;
    line-height: 30px;
  }

  @include breakpoint(large up) {
    font-size: 22px;
    line-height: 35px;
  }
}

%f-h7,
.f-h7 {
  @include sans-serif(10, 15, 900, normal, 0.03rem);

  @include breakpoint(medium up) {
    font-size: 12px;
    line-height: 18px;
  }

  @include breakpoint(xlarge up) {
    font-size: 13px;
    line-height: 20px;
  }
}

%f-h8,
.f-h8 {
  @include sans-serif(15, 25, 900);

  @include breakpoint(xlarge up) {
    font-size: 17px;
    line-height: 30px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 18px;
    line-height: 32px;
  }
}

%f-h8-b,
.f-h8-b {
  @include sans-serif(15, 25, 900);

  @include breakpoint(xlarge up) {
    font-size: 25px;
    line-height: 30px;
  }
}

%f-h15,
.f-h15 {
  @include sans-serif(13, 20, 400);

  @include breakpoint(xlarge up) {
    font-size: 17px;
    line-height: 30px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 18px;
    line-height: 32px;
  }
}

%f-h14,
.f-h14 {
  @include sans-serif(13, 20, 900);

  @include breakpoint(small up) {
    line-height: 23px;
  }

  @include breakpoint(xlarge up) {
    font-size: 14px;
    line-height: 25px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 15px;
    line-height: 25px;
  }
}

%f-h10,
.f-h10 {
  @include sans-serif(13, 20, 400);

  @include breakpoint(small up) {
    line-height: 23px;
  }

  @include breakpoint(xlarge up) {
    font-size: 14px;
    line-height: 25px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 15px;
    line-height: 25px;
  }
}

%f-h11,
.f-h11 {
  @include sans-serif(8, 10, 900, normal, 0.03rem);
  text-transform: uppercase;

  @include breakpoint(medium up) {
    font-size: 10px;
    line-height: 15px;
  }
}

%f-h19,
.f-h19 {
  @include sans-serif(22, 30, 900);

  @include breakpoint(small up) {
    font-size: 25px;
    line-height: 35px;
  }

  @include breakpoint(xxlarge up) {
    font-size: 30px;
    line-height: 40px;
  }
}
