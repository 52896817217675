.o-heroTitleFirst {
  @include breakpoint(medium down) {
    display: flex;
    flex-flow: column-reverse;
  }
}

.o-heroTitleFirst .o-landing__copy {
  @include breakpoint(medium down) {
    background-color: $color__background;

    .dark & {
      background-color: $color__background--dark;
    }

    .body--highlight & {
      background-color: $color__highlight;
    }
  }
}

.o-heroTitleFirst .o-landing__title {
  @include breakpoint(medium down) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.o-heroTitleFirst .o-landing__title-wrapper {
  @include breakpoint(small down) {
    padding-bottom: 0;
  }

  @include breakpoint(medium down) {
    margin-top: -35px;
  }
}

.o-heroTitleFirst .o-landing__media {
}

.o-heroTitleFirst .o-landing__copy:first-child + .o-landing__media {
  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}

.o-heroTitleFirst .o-landing__media img {
  display: block;
  max-width: 100%;
  margin: 0;
}

.o-heroTitleFirst .o-landing__mediaImageRight {
  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}
