.m-contentFilters--simple,
.m-contentFilters--title {
  margin: 0;
  border-top: 0;
}

.m-contentFilters--title {

  .m-contentList--list-view & {
    margin-top: 26px;
    
    @include breakpoint(medium up){
      margin-top: 107px;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-contentFilters__title {
  @extend %f-h4;
  margin-top: 0;
  margin-bottom: 15px;

  @include breakpoint(medium up){
    margin-bottom: 41px;
  }
}

.m-contentFilters__displayOptions {
  margin-top: 18px;
}

.pageClass--l2 .m-contentList__noResults {
  @include breakpoint(medium up) {
    padding-right: 0;
    padding-left: 0;
  }
}

.pageClass--l2 h4 + .m-contentList {
  margin-top: 20px;

  @include breakpoint(large up) {
    margin-top: 30px;
  }
}

.m-contentList--list-view .ais-infinite-hits--showmore {
  margin-top: 0;
}

.m-contentList--list-view .ais-infinite-hits--showmoreButton {
  margin-top: 80px;
}

.m-contentFiltersAjax {
  width: 100%;
}

.m-contentFilters__items.m-contentFilters__items--vertical {
  @include breakpoint(medium up) {
    column-count: 3;
    column-gap: 40px;
    padding-right: 55px !important
  }

  .m-contentFilters__item {
    @include breakpoint(medium up) {
      float: none;
      width: auto;
      padding: 0;
      // break-inside: avoid;
    }
  }
}

.m-contentFilters__list.is-active {
  max-height: 800px;
}
