.m-postInline {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px solid $color__separator;

  &:first-child {
    margin-top: 0;
  }

  @include breakpoint(medium down) {
    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }
  
  @include breakpoint(large up) {
    margin-top: 35px;
    padding-top: 35px;
  }
}

.m-postInline > a {
  display: block;

  &,
  .m-postInline__title {
    transition: color ease-in-out 180ms;
  }

  @include hover-focus(){
    &,
    .m-postInline__title {
      color: $color--highlight;
    }
  }
}

.m-postInline__image {
  margin: 0;
}

.m-postInline__image img {
  display: block;
  max-width: 100%;
}

.m-postInline__main {
}

.m-postInline__meta {
  @extend %f-h11;
  display: block;
  margin-top: 22px;
  color: $color__text--subtitle;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.m-postInline__title {
  @extend %f-h5;
  margin-top: 11px;
  color: $color__text;

  .dark & {
    color: $color__text--dark;
  }
}

.m-postInline__excerpt {
  @extend %f-h15;
  margin-top: 4px;
}

.m-postInline--no-image .m-postInline__main {
}

.o-twoThirds .m-postInline__image {
  @include breakpoint(large up) {
    width: 30.4%;
  }
}

.o-twoThirds .m-postInline__main {
  @include breakpoint(large up) {
    width: 69.6%;
  }
}

.o-twoThirds .m-postInline:last-child,
.o-listingFull .m-postInline:last-child {
  padding-bottom: 30px;
  border-bottom: 1px solid $color__separator;

  @include breakpoint(large up) {
    padding-bottom: 35px;
  }
}

.o-twoThirds .m-postInline:last-child {
  @include breakpoint(small down) {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
