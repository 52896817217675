.o-socialFeed {
}

.o-socialFeed .m-moduleHeader {
  margin-bottom: 16px;

  @include breakpoint(large up) {
    margin-bottom: 45px;
  }
}

.o-socialFeed + .m-cta {
  margin-top: 0;
}

.o-socialFeed__title {
  .pageClass--lang & {
    color: $color__highlight;
  }
}
