.design-grid-toggles {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  font-size: 0;

  &::before,
  &::after {
    display: block;
    position: absolute;
    left: 5px;
    bottom: 100%;
    color: #00a449;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &::before {
    margin-bottom: 3px;
  }

  &::after {
    content: attr(data-env);
    margin-bottom: 17px;
  }
}

.design-grid-toggle {
  display: inline-block;
  margin-right: 5px;
  padding: 10px 15px;
  background: #000;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
  transition: all 200ms linear;
}

.design-grid-toggle.js-active {
  background-color: #f00;
}

.design-grid-toggle svg {
  display: block;
  width: 10px;
  height: 10px;
}

.design-grid--baseline {
  display: block;
  position: absolute;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0) #{$base-height}px, #ff0000 #{$base-height}px, #ff0000 #{$base-height + $base-height}px);
  opacity: .25;
  pointer-events: none;

  &.js-hide {
    display: none;
  }
}

.design-grid--columns {
  display: block;
  position: fixed;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 60px 10px 0;
  background-color: transparent;
  opacity: .25;
  pointer-events: none;

  @include breakpoint(medium up) {
    padding: 60px 20px 0;
  }

  @include breakpoint(large up) {
    padding: 0 30px;
  }

  @include breakpoint(xlarge up) {
    padding: 0 40px;
  }

  @include breakpoint(xxlarge up) {
    padding: 0 60px;
  }

  &.js-hide {
    display: none;
  }
  
  .row {
    height: 100%;
  }

  .columns {
    height: 100%;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #7fffff;
    }
  }

  // @each $name, $point in $grid-breakpoints {
  //   @include breakpoint('#{$name}') {
  //     @if (map-get($main-col-widths, $name) == 'fluid') {
  //       // width: calc(100vw - #{map-get($outer-gutters, $name) * 2});
  //       background: repeating-linear-gradient(
  //         90deg,
  //         #7fffff,
  //         #7fffff calc((100vw - #{((map-get($column-count, $name) - 1) * map-get($inner-gutters, $name)) + (2 * map-get($outer-gutters, $name))}) / #{map-get($column-count, $name)}),
  //         rgba(0,0,0,0) calc((100vw - #{((map-get($column-count, $name) - 1) * map-get($inner-gutters, $name)) + (2 * map-get($outer-gutters, $name))}) / #{map-get($column-count, $name)}),
  //         rgba(0,0,0,0) calc(
  //           (
  //             (100vw - #{((map-get($column-count, $name) - 1) * map-get($inner-gutters, $name)) + (2 * map-get($outer-gutters, $name))}) / #{map-get($column-count, $name)})
  //             +
  //             #{map-get($inner-gutters, $name)}
  //           )
  //       );
  //     } @else {
  //       // width: map-get($main-col-widths, $name) + map-get($inner-gutters, $name) + map-get($inner-gutters, $name);
  //       background: repeating-linear-gradient(
  //         90deg,
  //         rgba(0,0,0,0),
  //         rgba(0,0,0,0) map-get($inner-gutters, $name),
  //         #7fffff map-get($inner-gutters, $name),
  //         #7fffff calc(
  //           (
  //             (#{map-get($main-col-widths, $name)} - #{(map-get($column-count, $name) - 1) * map-get($inner-gutters, $name)}) / #{map-get($column-count, $name)})
  //             +
  //             #{map-get($inner-gutters, $name)}
  //           )
  //       );
  //     }
  //   }
  // }
}
