.o-quoteCarousel {
}

.o-quoteCarousel .m-moduleHeader {
  margin-bottom: 34px;

  @include breakpoint(large up) {
    margin-bottom: 75px;
  }
}

.o-quoteCarousel a {
  text-decoration: none;
}

.o-quoteCarousel__cta {
  @extend %a-cta--dual-line;
  margin-top: 11px;
  color: inherit;
}

.o-quoteCarousel__main {
  margin-top: 11px;

  @include breakpoint(large up) {
    margin-top: 40px;
  }
}

.o-quoteCarousel__items {
  position: relative;

  @include breakpoint(large up) {
    margin-top: 30px;
  }
}

.o-quoteCarousel__main + .o-quoteCarousel__items {
  margin-top: 18px;

  @include breakpoint(large up) {
    margin-top: 40px;
  }
}

.o-quoteCarousel__item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding-bottom: 1px;
}

.o-quoteCarousel blockquote {
  margin: 0;
  padding: 0;
  border: 0;
}

.o-quoteCarousel blockquote p {
  @extend %f-h19;
}

.o-quoteCarousel blockquote p:first-child::before {
  content: '“';
}

.o-quoteCarousel blockquote p:last-of-type::after {
  content: '”';
}

.o-quoteCarousel cite {
  @extend %f-h10;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 25px;

  @include breakpoint(large up) {
    margin-top: 28px;
  }
}

.o-quoteCarousel cite .a-img {
  width: 35px;
  flex-shrink: 0;
  margin: 0;
  margin-right: 15px;

  @include breakpoint(large up) {
    width: 55px;
    margin-right: 20px;
  }
}

.o-quoteCarousel cite img {
  max-width: 100%;
  border-radius: 50%;
}

.o-quoteCarousel cite .a-img + span {
  margin-top: -5px;
}


.o-quoteCarousel__nav {
  margin-top: 30px;
  text-align: right;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.o-quoteCarousel__nav-item {
  @extend %f-h11;
  margin: 0 0 20px;
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;

  &:hover {
  }

  @include breakpoint(large up) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.o-quoteCarousel__nav--prev {
  @include breakpoint(large up) {
    left: 6.66667%;
    margin-left: map-get($grid-column-gutter, 'large') / 2;
  }

  @include breakpoint(xlarge up) {
    margin-left: map-get($grid-column-gutter, 'xlarge') / 2;
  }
}

.o-quoteCarousel__nav--next {
  @include breakpoint(large up) {
    right: 6.66667%;
    margin-right: map-get($grid-column-gutter, 'large') / 2;
  }

  @include breakpoint(xlarge up) {
    margin-right: map-get($grid-column-gutter, 'xlarge') / 2;
  }
}
