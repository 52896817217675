.m-navList__container { // copied from previous phase as phase3 css was overriding it
  @include breakpoint(large down) {
    .row {
      margin: 0;
    }
  }
}

.m-navList--tertiary {
  @include breakpoint(small down) {
    padding-left: 10px;
    padding-right: 10px;

    .m-navList__item {
      margin: 0 5px;
    }
  }
}
