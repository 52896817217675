.m-moduleHeader {
}

.m-moduleHeader__title-wrap {
  float: none;
}

.m-moduleHeader__title {
  @extend %f-h18;
  margin-top: 0;
  color: $color__highlight;

  .body--highlight & {
    color: $color__text--dark;
  }

  &.m-moduleHeader__title--has-anchor::before {
    display: block; 
    content: " "; 
    margin-top: -75px; 
    height: 75px; 
    visibility: hidden; 
    pointer-events: none;
    
    @include breakpoint(large up) {
      margin-top: -150px; 
      height: 150px; 
    }
  }
}

.m-moduleHeader__intro {
  @extend %f-h15;
  float: none;
  margin-top: 18px;
  margin-bottom: 45px;

  @include breakpoint(large up) {
    margin-top: 31px;
  }
}

.m-moduleHeader__intro p:first-child {
  margin-top: 0;
}

.m-moduleHeader__cta {
  @extend %a-cta--dual-line;
  margin-top: 22px;
}
