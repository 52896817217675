.pageClass--spe {
  .o-sectionInfo__title,
  .m-landingPanel__title,
  .m-moduleHeader__title {
    color: $color__text;
  }

  &.dark,
  .s-module-bkgd--dark,
  .s-module-bkgd--highlight {
    .o-sectionInfo__title,
    .m-landingPanel__title,
    .m-moduleHeader__title {
      color: $color__text--dark;
    }
  }

  .o-sectionInfo__header {
    @include breakpoint(large up) {
      margin-bottom: 43px;
    }
  }

  .o-sectionInfo__title {
    margin-bottom: 0;
  }

  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__image,
  .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__image {
    padding-left: 0;
  }

  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink p,
  .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__imageLink p {
    @include breakpoint(large up) {
      left: 20px;
    }
  }

  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink::after,
  .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__imageLink::after {
    @include breakpoint(large up) {
      left: 0;
      width: 100%;
    }
  }

  .m-featurePanel .m-landingPanel__title,
  .m-featurePanel .m-landingPanel--alt .m-landingPanel__title {
    top: 0;
    margin-bottom: 23px;
    transform: translateX(0);

    @include breakpoint(large up) {
      margin-bottom: 42px;
    }
  }

  .m-featurePanel--small .m-landingPanel__title,
  .m-featurePanel--small .m-landingPanel--alt .m-landingPanel__title {
    @include breakpoint(medium down) {
      margin-bottom: 0;
    }
  }
}
