.m-featurePanel {
}

.m-featurePanel .m-landingPanel {
  padding-bottom: 0;
}

.m-featurePanel .m-landingPanel .row {
  @include breakpoint(large up) {
    align-items: flex-start;
  }
}

.m-featurePanel--link-list .m-landingPanel__content .f-h11,
.m-landingPanel__content .m-featurePanel--link-list .f-h11 {
  color: $color__highlight;
}

// .m-featurePanel--link-list .m-landingPanel__content ul,
// .m-landingPanel__content .m-featurePanel--link-list ul {
//   margin-left: 0;
//   padding-left: 0;
//   list-style: none;
// }

// .m-featurePanel--link-list .m-landingPanel__content ul li::before,
//  .m-landingPanel__content .m-featurePanel--link-list ul li::before {
//   display: none;
// }

.m-featurePanel .m-landingPanel__content ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.m-featurePanel .m-landingPanel__content ul li::before {
  display: none;
}


.m-landingPanel__content .m-featurePanel--bullet-list ul {
  margin: 30px 0 0;
  padding: 0 0 0 25px;
}

 .m-landingPanel__content .m-featurePanel--bullet-list ul li::before {
  display: inline;
}


.pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title {
  @extend %f-h18;
}

.pageClass--l2 .m-featurePanel .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__title {
  @include breakpoint(large up) {
    transform: translateX(-40px);
  }
}

.pageClass--l2 .m-featurePanel .m-landingPanel__title,
.pageClass--l2 .m-featurePanel .m-landingPanel--alt .m-landingPanel__title {
  @include breakpoint(large up) {
    top: 19px;
    margin-bottom: 0;
  }
}

.m-featurePanel + .m-featurePanel--small {
  @include breakpoint(medium down) {
    margin-top: 15px;
  }
}

.m-featurePanel--small + .m-featurePanel--small {
  @include breakpoint(medium down) {
    margin-top: -39px;
  }
}

// .m-featurePanel--small + .m-featurePanel--small .m-landingPanel__title {
//   border-top: 0;
// }

.m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list {
  margin: 15px 0 0;

  li {
    margin: 0 0 15px;
  }

  a {
    margin: 0;
    border-bottom: 2px solid $color__highlight;

    @include hover-focus {
      border-bottom: 2px solid $color__highlight;
    }

    &::before {
      display: none;
    }
  }
}

.m-featurePanel--small-alt {
  .m-landingPanel__title {
    @include breakpoint(large up) {
      @include title(45, 45, 700, normal, -0.01rem);
      border: none;
      top: 20px;
    }

    @include breakpoint(xlarge up) {
      @include title(50, 50, 700, normal, -0.01rem);
    }

    @include breakpoint(xxlarge up) {
      @include title(60, 60, 700, normal, -0.01rem);
    }
  }

  .m-landingPanel__imageLink::after {
    @include breakpoint(large up) {
      width: calc(100% - 40px);
    }
  }

  .m-landingPanel--alt .m-landingPanel__imageLink::after {
    @include breakpoint(large up) {
      left: 40px;
      width: calc(100% - 40px);
    }
  }

  .m-landingPanel--alt .m-landingPanel__imageLink p {
    @include breakpoint(large up) {
      left: 60px;
    }
  }

  .m-landingPanel__image {
    @include breakpoint(large up) {
      padding: 0 40px 0 0;
    }
  }

  .m-landingPanel--alt .m-landingPanel__image {
    @include breakpoint(large up) {
      padding: 0 0 0 40px;
    }
  }
}
