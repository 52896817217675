.m-sidebarNavigation {
  @include breakpoint(small down) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.m-sidebarNavigation__itemsWrapper {
  margin-left: 0;
  margin-right: 0;
}
