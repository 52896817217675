.m-drawers {
  &:first-child {
    // margin-top: 0;
  }
}

.m-drawers__content.m-drawers__content {
  padding-top: 10px;
}

.m-drawers--alt .m-drawers__toggle {
  @include hover-focus {
    span {
      border-bottom-color: transparent;
      color: $color__highlight;
    }
  }
}

.m-drawers--alt .m-drawers__toggle span {
  @extend %f-h5;
  display: block;
  color: $color__text;
}

.m-drawers--alt .m-drawers__toggle span.m-drawers__subtitle {
  @extend %f-h11;
  margin-top: 3px;
  color: $color__highlight;

  @include breakpoint(large up) {
    margin-top: 8px;
  }
}

.m-textBlock + .m-drawers,
.m-textBlock + .o-primaryContent .m-drawers {
  margin-top: 0;
}
