// Typography
$base-font-family: "neue";
$title-font-family: "neue-display-random";
$wide-font-family: "neue-display-wide";
$ultra-font-family: "neue-display-ultra";


// Baseline grid height
$base-height: 5;

// Max widths the main column can run to over the breakpoints
// values can either be 'fluid' or a pixel value
// recommended xxlarge is a px value and xsmall is fluid
$main-col-widths: (
  xxlarge: 1290px,
  xlarge: 'fluid',
  large: 'fluid',
  medium: 'fluid',
  small: 'fluid',
  xsmall: 'fluid'
);

$inner-gutters: (
  xxlarge: 40px,
  xlarge: 40px,
  large: 30px,
  medium: 20px,
  small: 20px,
  xsmall: 20px
);

$outer-gutters: (
  xxlarge: 60px,
  xlarge: 60px,
  large: 50px,
  medium: 40px,
  small: 40px,
  xsmall: 40px
);

// How many columns are in each breakpoint
$column-count: (
  xxlarge: 15,
  xlarge: 15,
  large: 15,
  medium: 4,
  small: 4,
  xsmall: 4
);


// Breakpoint information, where each starts and stops
// if a breakpoint is not fluid, then the start value is equal to the main col value plus 2x the gutter at this breakpoint
$grid-breakpoints: (
  xsmall: (start: null, end: 479),
  small: (start: 480, end: 639),
  medium: (start: 640, end: 979),
  large: (start: 980, end: 1159),
  xlarge: (start: 1160, end: 1449),
  xxlarge: (start: 1450, end: null)
);

// Some easing functions
$bezier--bounce: cubic-bezier(0.5, -0.6, 0.5, 1.6);
$bezier--ease-in-out: cubic-bezier(0.5, 0, 0.5, 0);
// $bezier--ease-in-out: cubic-bezier(0.25, 0.1, 0.25, 0);

// Standard curve - movement within screen bounds
$bezier--standard: cubic-bezier(0.4, 0, 0.2, 1);

// Deceleration curve (“Easing out”) - movement from off screen
$bezier--ease-out: cubic-bezier(0, 0, 0.2, 1);

// Acceleration curve (“Easing in”) - movement from on screen
$bezier--ease-in: cubic-bezier(0.4, 0, 1, 1);

// Sharp curve
$bezier--sharp: cubic-bezier(0.4, 0, 0.6, 1);
