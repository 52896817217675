.o-listingFull {
}

.m-moduleHeader + .o-listingFull__inner {
  margin-top: 23px;

  @include breakpoint(large up) {
    margin-top: 63px;
  }
}

.o-listingFull__inner {
  overflow: hidden;
  transition: height 0.3s $bezier--ease-in-out;
}

.o-listingFull__footer {
  margin-top: 8px;

  @include breakpoint(large up) {
    margin-top: 18px;
  }
}

.o-listingFull__footer .a-btn {
  margin-top: 42px;

  @include breakpoint(large up) {
    margin-top: 57px;
  }
}

.o-listingFull__cta {
  @extend %a-cta--dual-line;
}
