.o-stats {
  position: relative;
  margin-top: 45px;
  border-bottom: 1px solid $color__separator;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: map-get($grid-column-gutter, 'xsmall') / 2;
    right: map-get($grid-column-gutter, 'xsmall') / 2;
    border-top: 1px solid $color__separator;
  }

  @include breakpoint(large up) {
    border-bottom: 0;
    
    &::before {
      display: none;
    }
  }
}

.o-stats .m-statsList {
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0;
}

// .o-stats + .o-three-col {
//   margin-top: 25px;
  
//   @include breakpoint(large up) {
//     margin-top: 75px; // 110px - 35px
//   }
// }

.m-landingIntro + .o-stats::before {
  // display: none;
}

.m-statsList__oversized {
  // @include sans-serif(30, 40, 900, normal, -0.01rem);

  // @include breakpoint(small up) {
  //   @include sans-serif(40, 45, 900, normal, -0.01rem);
  // }

  // @include breakpoint(medium up) {
  //   @include sans-serif(65, 70, 900, normal, -0.01rem);
  // }

  @include breakpoint(large up) {
    // @include sans-serif(85, 90, 900, normal, -0.03rem);
    font-size: 6.9vw;
    line-height: 1.2;
  }

  // @include breakpoint(xlarge up) {
    // @include sans-serif(110, 120, 900, normal, -0.03rem);
  // }

  @include breakpoint(xxlarge up) {
    // @include sans-serif(110, 140, 900, normal, -0.03rem);
    font-size: 110px;
    line-height: 120px;
  }
}
