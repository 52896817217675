.s-header-fixed .o-utility-nav__tertiary .m-navList--secondary .m-navList__link--primary {
  line-height: 15px;
}

.o-utility-nav__tertiary .m-navList--secondary > :last-child:after {
  position: absolute;
  top: -7px;
  height: 30px;
  background-color: $color__utilNavSplitter;
}
