.m-ctaPanel {
}

.m-ctaPanel.s-module-bkgd {
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 39px;

  @include breakpoint(medium up){
    padding-top: 69px;
    padding-bottom: 75px;
  }
}

.m-ctaPanel h2 {
  &,
  .pageClass--l0 &, 
  .pageClass--l1 & {
    margin-top: 0;
  }
}

.m-ctaPanel p {
  @extend %f-h15;
  margin-top: 30px;
}

.m-ctaPanel p:first-child {
  margin-top: 15px;

  @include breakpoint(medium up) {
    margin-top: 4px;
  }

  @include breakpoint(large up) {
    margin-top: 19px;
  }
}
