// use this to handle enabling/disabling the borders between modules

// modules that need some space above them without a border or background
.s-module-spacer {
  margin-top: 75px;

  @include breakpoint(large up) {
    margin-top: 145px;
  }

  &.o-three-col {
    margin-top: 65px;
    padding-top: 10px; // so the overflow doesn't cut image off

    @include breakpoint(large up) {
      margin-top: 135px;
    }
  }
}

// modules with borders - new modules should have the class `s-module-border`, reused modules should be added here
.s-module-border {
  @include full-bleed;
  margin-top: 45px;
  padding-top: 50px;
  border-top: 1px solid $color__separator;
  transition: border-top 0.2s $bezier--ease-in-out;

  @include breakpoint(medium up) {
    margin-top: 110px;
    padding-top: 65px;
  }

  .dark & {
    border-top-color: $color__separator--dark;
  }

  .body--highlight & {
    border-top-color: transparent;
  }
}

.s-module-border--mobile {
  @include breakpoint(medium down) {
    margin-top: 45px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid $color__separator;
    transition: border-top 0.2s $bezier--ease-in-out;

    .dark & {
      border-top-color: $color__separator--dark;
    }

    .body--highlight & {
      border-top-color: transparent;
    }
  }

  @include breakpoint(small down) {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.s-module-border.m-featurePanel {
  @include breakpoint(medium up) {
    padding-top: 35px;
  }
}

.s-module-border.m-featurePanel--small {
  @include breakpoint(small down) {
    padding-top: 0;
    border-top: 0;
  }
}


// featurePanel spacing is on the element inside it so we can't collapse the margins.
// Any updates to .m-featurePanel .m-landingPanel will need to be reflected here
.m-featurePanel + .s-module-border {
  margin-top: 7px; // 45px - 38px;

  @include breakpoint(medium up) {
    margin-top: 72px; // 110px - 38px;
  }

  @include breakpoint(large up) {
    margin-top: 35px; // 110px - 75px;
  }
}

.m-featurePanel + .s-module-spacer {
  margin-top: 35px;

  @include breakpoint(medium up) {
    margin-top: 125px;
  }
}

// .m-landingIntro + .s-module-border:not(.o-studyAreas) {
//   padding-top: 0;
//   border-top: 0;
// }

.s-module-border:first-child, // hide border on the frist border modules
.o-heroGallery + .o-three-col.s-module-border, // hide the border between the heroGallery and three col (Milano Home)
.o-studyAreas + .s-module-border, // study areas module has a background on the footer element so hide the border on following border modules
.body--highlight + .s-module-border, // hide border on border modules following background modules
.s-module-bkgd + .s-module-border { // hide border on border modules following background modules
  margin-top: 0;
  border-top: 0;
}

// modules with background (no borders) - new modules should have the class `s-module-bkgd`, reused modules should be added here
.s-module-bkgd {
  @include full-bleed;
  margin-top: 50px;
  padding-top: 51px;
  padding-bottom: 45px;
  background-color: $color__background--light;

  @include breakpoint(medium up){
    margin-top: 110px;
    padding-top: 75px;
    padding-bottom: 110px;
  }
}

.s-module-bkgd.s-module-bkgd--dark {
  background-color: $color__background--dark;
  color: $color__text--dark;
}

.s-module-bkgd.s-module-bkgd--highlight {
  background-color: $color__highlight;
  color: $color__text--dark;
}

.s-module-bkgd.s-module-bkgd--highlight .m-moduleHeader__title {
  color: $color__text;
}

.s-module-bkgd + .s-module-bkgd,
.s-module-bkgd + .m-cta {
  margin-top: 0;
}
