.o-careerOutcomes {
  overflow: hidden;
}

.o-careerOutcomes .m-moduleHeader {
  position: relative;
  z-index: 2;
}

.o-careerOutcomes__items {
  margin-top: -5px;

  @include breakpoint(medium up) {
    margin-top: -20px;
  }
}

.o-careerOutcomes__item {
  text-align: center;

  &:nth-child(3n-1){
    @include breakpoint(medium up) {
      margin-top: 95px;
    }
  }
}

.o-careerOutcomes__item a {
  display: block;
  text-decoration: none;
}

.o-careerOutcomes .flickity-viewport {
  overflow: visible;
}

.o-careerOutcomes .carousel-cell {
  margin-left: -60px;
  margin-right: -60px;
  opacity: 0.2;
  transition: opacity 0.3s $bezier--standard;

  &.is-selected {
    opacity: 1;
    z-index: 5;
  }

  @include breakpoint(medium up){
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    opacity: 1;
  }
}

.o-careerOutcomes__image {
  margin: 0;
  height: 200px;

  @include breakpoint(medium up) {
    height: auto;
  }
}

.o-careerOutcomes__image img {
  display: block;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  transform: scale(0.66);
  transition: transform 0.3s $bezier--standard;

  .is-selected & {
    transform: scale(1);
  }

  @include breakpoint(medium up) {
    transform: scale(1);
  }
}

.o-careerOutcomes__name,
.o-careerOutcomes__role,
.o-careerOutcomes__course {
  display: block;
  opacity: 0;
  transition: opacity 0.3s $bezier--standard;

  .is-selected & {
    opacity: 1;
  }

  @include breakpoint(medium up) {
    opacity: 1;
  }
}

.o-careerOutcomes__name {
  @extend %f-h10;
  margin-top: 25px;

  @include breakpoint(medium up) {
    margin-top: 22px;
  }
}

.o-careerOutcomes__role {
  @extend %f-h8;

  @include breakpoint(large up) {
    margin-top: 10px;
  }
}

.o-careerOutcomes__course {
  @extend %f-h10;

  @include breakpoint(large up) {
    margin-top: 7px;
  }
}

.o-careerOutcomes__content {
  position: relative;
  margin-top: 25px;
  padding-top: 30px;

  @include breakpoint(medium up) {
    margin-top: 49px;
    padding-top: 0;
  }

  @include breakpoint(xlarge up) {
    margin-top: 71px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: map-get($grid-column-gutter, xsmall) / 2;
    right: map-get($grid-column-gutter, xsmall) / 2;
    border-top: 1px solid rgba($color__black--92, 0.15);

    @include breakpoint(medium up) {
      display: none;
    }
  }
}

.o-careerOutcomes__content p {
  @extend %f-h15;

  &:first-child {
    margin-top: 0;
  }
}

.o-careerOutcomes__cta {
  @extend %a-cta--dual-line;
  color: $color__text;

  @include breakpoint(large up) {
    margin-top: 23px;
  }
}
