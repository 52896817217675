.m-statsPanel {
  @include breakpoint(medium down) {
    padding-bottom: 0;
  }
}

.m-statsPanel .m-statsList {
  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}
