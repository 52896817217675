body {
  transition: background-color 0.2s $bezier--ease-in-out, color 0.2s $bezier--ease-in-out;
}

body .body--highlight {
  @include full-bleed;
  background: $color__highlight;
  color: $color__text--dark;
  -webkit-font-smoothing: antialiased;
}

.g-footer {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
