.o-studyAreas {
}

.o-studyAreas__inner {
  position: relative;
}

.o-studyAreas__footer {
  @extend %f-h8;
  @include full-bleed;
  position: sticky;
  bottom: 0;
  z-index: 5;
  margin-top: -1px; // list view has a border on the last item
  background-color: $color__background--light;

  @include breakpoint(medium up) {
  }
}

.o-studyAreas__footer a {
  @include full-bleed;
  display: block;
  padding-top: 21px;
  padding-bottom: 24px;
  background-color: $color__background--light;
  text-decoration: none;
  transition: all 0.3s $bezier--standard;

  @include hover-focus {
    background-color: $color__highlight;
    color: $color__text--dark;
  }

  @include breakpoint(xlarge up) {
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
  }
}

.o-studyAreas .m-moduleHeader__intro {
  margin-bottom: 18px;
}

.o-studyAreas .m-contentList {
  margin-top: 0;
  padding-top: 0;

  .ais-infinite-hits {
    margin-top: 0;
  }
  
  .m-contentFilters + .o-studyAreas__items .ais-infinite-hits {
    margin-top: 11px;
  }
}

.o-studyAreas:not(.o-studyAreas--alt) .m-contentList {
  .ais-infinite-hits--item {
    margin-bottom: 0;
  }
}

.o-studyAreas__more {
}

.o-studyAreas__more-header {
  @include breakpoint(large up){
    padding-top: 20px;
  }
}

.o-studyAreas__more-title {
  @extend %f-h4;
  margin: 0;
}

.o-studyAreas__more-main {
  padding-bottom: 15px;

  @include breakpoint(large up){
    padding-top: 35px;
    padding-bottom: 75px;
  }
}

.o-studyAreas__more p {
  @extend %f-h15;

  &:first-child {
    margin-top: 0;
  }
}

.o-studyAreas__more-cta {
  @extend %a-cta--dual-line;
  margin-top: 20px;
}


.o-studyAreas--alt .o-studyAreas__footer {
  margin-top: 35px;

  @include breakpoint(large up){
    margin-top: 100px;
  }
}

.o-studyAreas--alt .m-contentList--grid-view .a-program.is-active {
  &::before,
  &::after {
    @include breakpoint(xlarge up) {
      bottom: -115px;
    }
  }
}
