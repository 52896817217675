%a-cta--dual-line,
.a-cta--dual-line {
  @include sans-serif(10, 15, 900, normal, 0.05rem);
  border-bottom: 2px solid;
  clear: both;
  color: $color__highlight;
  display: inline-block;
  margin: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3D(0, 0, 0);
  transition: border-bottom ease-in-out 180ms;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    border-top: 2px solid;
  }
}
