.m-listPanel {
  background: $color__ctaBackground;
}

.m-listPanel__title {
  @extend %f-h11;
  display: block;
  padding: 18px 20px 16px;
  color: $color__highlight;
}

.m-listPanel__items{
}

.m-listPanel__item {
  @extend %f-h14;
  border-top: 1px solid $color__separator;
}

.m-listPanel__item a {
  display: block;
  padding: 16px 20px 18px;
  text-decoration: none;

  &:hover {
    color: $color__highlight;
  }
}

.o-twoThirds .m-listPanel {
  @include breakpoint(large up) {
    min-height: calc(100% - 48px);
  }
}
