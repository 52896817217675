.m-post {
  position: relative;
  height: 100%;
}

.m-post a {
  text-decoration: none;
}

.m-post > a:not(.m-post__cta) {
  display: block;
  transition: color ease-in-out 180ms;

  @include hover-focus {
    color: $color__highlight;
  }
}

.m-post > a + .m-post__tag {
  margin-top: 23px;
}

.m-post.m-post--has-cta,
.m-post.m-post--has-link {
  @include breakpoint(medium up){
    padding-bottom: 50px;
  }
}

.m-post__image,
.m-post .a-video__link.a-video__link {
  margin: 0;
}


.m-post__image {
  position: relative;
  width: 100%;

  &::after {
    @include breakpoint(large up) {
      background: $color__highlight;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      transition: transform ease-out 360ms;

      .body--highlight & {
        background: $color__background--dark;
      }
    }
  }

  @include breakpoint(large up) {
    a:hover & {
      &:after {
        transform: translate3D(10px, 10px, 0);
      }

      img {
        transform: translate3D(-10px, -10px, 0);
      }
    }
  }
}

.m-post__image img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  transition: transform ease-out 360ms;
}

.m-post__tag {
  @extend %f-h11;
  display: block;
  color: $color__highlight;
}

.m-post__title {
  @extend %f-h5;
  margin-top: 18px;
  color: inherit;

  &:first-child {
    margin-top: 0;
  }
}

.m-post__title a,
.m-post__quote a {
  display: block;
  text-decoration: none;
  transition: color ease-in-out 180ms;

  &:hover {
    color: $color__highlight;
  }
}

.m-post__tag + .m-post__title {
  margin-top: 11px;
}


.m-post__excerpt {
  @extend %f-h10;
  margin-top: 11px;
}

.m-post__excerpt p {
  &:first-child {
    margin-top: 0;
  }
}

.m-post__cta,
.m-post__link {
  position: relative;
  margin-top: 22px;

  @include breakpoint(medium up){
    position: absolute;
    left: 0;
  }

  .m-post--extra & {
    @include breakpoint(medium up){
      position: static;
    }
  }
}

.m-post__cta {
  @extend %a-cta--dual-line;
  margin-bottom: 4px;

  @include breakpoint(medium up){
    margin-top: 0;
    margin-bottom: 0;
    bottom: 4px;
  }
}

.m-post__link {
  @extend %f-h10;
  margin-left: 17px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: border-bottom ease-out 180ms, color ease-out 180ms;

  @include breakpoint(medium up){
    bottom: 0;
  }

  &:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23000' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
    background-size: 100% auto;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    left: -23px;
    transition: opacity ease-out 180ms;

    @include breakpoint(medium up) {
      top: 4px;
    }

    @include breakpoint(xxlarge up) {
      top: 6px;
    }

    .s-module-bkgd.s-module-bkgd--dark & {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  @include breakpoint(large up) {
    @include hover-focus {
      border-bottom: 1px solid $color__highlight;
      color: $color__highlight;
    }
  }
}

.m-post__slide {
  @include breakpoint(large up) {
    position: relative;
    z-index: 10;
    background-color: $color__background;
    transition: transform 0.3s $bezier--ease-out;

    &::after {
      // content: '';
      // position: absolute;
      // top: 100%;
      // left: 0;
      // right: 0;
      // z-index: 1;
      // height: 92px;
      // background-color: $color__background;
    }
  }
}

.m-post__slide-inner {
  padding-top: 23px;
  background-color: $color__background;

  @include breakpoint(large up) {
  }
}

.m-post__slide-hidden {
  @include breakpoint(large up) {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    min-height: 92px;
    padding-bottom: 20px; // so that if you hover past the cta it doesn't hide straight away
    background-color: $color__background;
    pointer-events: none;

    > * {
      opacity: 0;
      transition: opacity 0.3s $bezier--ease-out;
    }
  }
}

.m-post__quote {
  margin: 0;
  padding: 0;
  border: 0;

  @include breakpoint(large up) {
    margin-top: 6px;
  }
}

.m-post__quote p {
  @extend %f-h5;
}

.m-post__quote cite {
  @extend %f-h10;
  display: block;
  margin-top: 25px;

  @include breakpoint(large up) {
    width: 90%;
    margin-top: 28px;
  }

  &::before {
    content: '\2014';
    margin-right: 5px;
  }
}

.m-post__quote p:first-child::before {
  content: '“';
}

.m-post__quote p:last-of-type::after {
  content: '”';
}

.m-post--extra {
  display: flex;
  flex-flow: column nowrap;

  > * {
    transition: transform 0.3s $bezier--ease-out;
  }
}

.m-post--extra .a-video {
  transition: transform 0.3s $bezier--ease-out;
  // transition: margin-top 0.3s $bezier--ease-out;
}

.m-post--extra .m-post__image,
.m-post--extra .a-video {
  order: 1;
}

.m-post--extra .m-post__slide {
  order: 2;

  > * {
    transition: all 0.3s $bezier--ease-out;
  }
}

.m-post--extra:not(.m-post--video):hover {
  @include breakpoint(large up) {
    > * {
      transform: translateY(-40px);
    }

    .m-post__slide {
      transform: translateY(-150px);
    }

    .m-post__slide-hidden {
      pointer-events: all;

      > * {
        opacity: 1;
        transition-delay: 0.15s;
      }
    }
  }
}

.m-post--video .m-post__slide:hover {
  @include breakpoint(large up) {
    > * {
      transform: translateY(-150px);
      // margin-bottom: 40px;
    }

    .m-post__slide-hidden {
      pointer-events: all;

      > * {
        opacity: 1;
      }
    }

    & + .a-video {
      transform: translateY(-40px);
      // margin-top: -40px;
    }
  }
}

.m-post--video .m-post__slide-hidden {
  @include breakpoint(large up) {
    min-height: 240px;
  }
}

.m-post--feature {
  .m-post__title {
    @extend %f-h4;

    @include breakpoint(large up) {
      margin-top: 34px;
    }
  }
}

.m-post--quote {
}




.body--highlight,
.s-module-bkgd--highlight {
  .m-post > a {
    @include hover-focus{
      color: $color__text;
    }
  }

  .m-post__title,
  .m-post__title a,
  .m-post__quote p {
    color: $color__text;
  }

  .m-post__slide,
  .m-post__slide::after,
  .m-post__slide-inner,
  .m-post__slide-hidden {
    background-color: $color__highlight;
  }

  .m-post__cta,
  .m-post__tag {
    color: $color__text--dark;
  }

  .s-module-bkgd--dark {
    .m-post__title,
    .m-post__title a,
    .m-post__quote p {
      color: $color__text--dark;
    }

    .m-post__cta:hover,
    .m-post__link:hover,
    .m-post__title a:hover {
      color: $color__highlight;
    }
    
    .m-post__image::after {
      background-color: $color__highlight;
    }
  }
}
