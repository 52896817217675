.o-fastFactsDetail {
  padding-top: 28px;
  padding-bottom: 35px;
  border-top: 1px solid $color__separator;
  border-bottom: 1px solid $color__separator;

  @include breakpoint(large up) {
    padding-bottom: 65px;
  }
}

.o-fastFactsDetail__items,
.o-primaryContent ul .o-fastFactsDetail__items {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  @include breakpoint(medium up){
    display: flex;
    flex-flow: row wrap;
  }
}

.o-fastFactsDetail__item,
.o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item {
  float: none !important;
  margin-top: 14px;

  &:first-child {
    margin-top: 0;
  }

  &::before {
    display: none;
  }

  @include breakpoint(medium up){
    width: 50%;
    margin-top: 19px;

    &:nth-child(-n+2){
      margin-top: 0;
    }
  }
}

.o-fastFactsDetail__title,
.o-fastFactsDetail__content {
  display: block;
}

.o-fastFactsDetail__title {
  @extend %f-h11;
  color: $color__highlight;
}

.o-fastFactsDetail__content {
  @extend %f-h5;
  margin-top: 5px;
}

.o-fastFactsDetail__footer {
  @extend %f-h10;
  margin-top: 15px;

  @include breakpoint(large up) {
    margin-top: 29px;
  }
}

.o-fastFactsDetail__footer-link {
  border-bottom: 1px solid;
  text-decoration: none;

  @include hover-focus {
    color: $color__highlight;
  }
}

.o-fastFactsDetail + .add-separator--top::before {
  display: none;
}

.o-primaryContent .m-heroHeading + .o-fastFactsDetail {
  margin-top: 43px;

  @include breakpoint(medium up) {
    margin-top: 66px;
  }
}
