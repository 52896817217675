@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0; }

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block; }

html {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  box-sizing: border-box;
  touch-action: manipulation; }

body {
  line-height: 1; }

blockquote,
q {
  quotes: none; }

ul,
ol {
  list-style: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*::before,
*::after {
  box-sizing: inherit;
  touch-action: inherit; }

.svg-sprite {
  position: absolute;
  z-index: -1;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden; }

@-ms-viewport {
  width: device-width; }

/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/*
  @mixin active

  Combines hover, focus and active styles
*/
.icon--facebook {
  height: 18px;
  width: 18px; }

.icon--twitter {
  height: 18px;
  width: 18px; }

.icon--youtube {
  height: 18px;
  width: 18px; }

.icon--instagram {
  height: 18px;
  width: 18px; }

.icon--arrow-circle-left,
.icon--arrow-circle-right,
.icon--shuffle-circle {
  width: 45px;
  height: 45px; }

.icon--plus-alt {
  width: 13px;
  height: 13px; }

.icon--minus {
  width: 13px;
  height: 3px; }

.design-grid-toggles {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  font-size: 0; }
  .design-grid-toggles::before, .design-grid-toggles::after {
    display: block;
    position: absolute;
    left: 5px;
    bottom: 100%;
    color: #00a449;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap; }
  .design-grid-toggles::before {
    margin-bottom: 3px; }
  .design-grid-toggles::after {
    content: attr(data-env);
    margin-bottom: 17px; }

.design-grid-toggle {
  display: inline-block;
  margin-right: 5px;
  padding: 10px 15px;
  background: #000;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
  transition: all 200ms linear; }

.design-grid-toggle.js-active {
  background-color: #f00; }

.design-grid-toggle svg {
  display: block;
  width: 10px;
  height: 10px; }

.design-grid--baseline {
  display: block;
  position: absolute;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(180deg, transparent, transparent 5px, #ff0000 5px, #ff0000 10px);
  opacity: .25;
  pointer-events: none; }
  .design-grid--baseline.js-hide {
    display: none; }

.design-grid--columns {
  display: block;
  position: fixed;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 60px 10px 0;
  background-color: transparent;
  opacity: .25;
  pointer-events: none; }
  @media screen and (min-width: 40em) {
    .design-grid--columns {
      padding: 60px 20px 0; } }
  @media screen and (min-width: 61.25em) {
    .design-grid--columns {
      padding: 0 30px; } }
  @media screen and (min-width: 72.5em) {
    .design-grid--columns {
      padding: 0 40px; } }
  @media screen and (min-width: 90.625em) {
    .design-grid--columns {
      padding: 0 60px; } }
  .design-grid--columns.js-hide {
    display: none; }
  .design-grid--columns .row {
    height: 100%; }
  .design-grid--columns .columns {
    height: 100%; }
    .design-grid--columns .columns::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #7fffff; }

.cta--double-line {
  display: inline-block;
  position: relative;
  border-bottom: 2px solid #E42A1D;
  color: #E42A1D;
  font-weight: 900;
  line-height: 15px;
  font-size: 10px;
  letter-spacing: 0.05rem;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3D(0, 0, 0); }
  .cta--double-line:after {
    background: #E42A1D;
    bottom: -6px;
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%; }
  .cta--double-line:hover {
    border-bottom-width: 2px; }

.btn--link {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  outline: 0; }

.o-heroFullScreen__title,
.f-h1 {
  font-family: "neue-display-random";
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .o-heroFullScreen__title,
    .f-h1 {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -0.01rem; } }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen__title,
    .f-h1 {
      font-size: 65px;
      line-height: 65px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__title,
    .f-h1 {
      font-size: 85px;
      line-height: 80px;
      letter-spacing: -0.03rem; } }
  @media screen and (min-width: 72.5em) {
    .o-heroFullScreen__title,
    .f-h1 {
      font-size: 110px;
      line-height: 100px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroFullScreen__title,
    .f-h1 {
      font-size: 130px;
      line-height: 125px; } }

.pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title, .m-moduleHeader__title, .o-heroFlyout__title, .o-heroFullScreen__main-title, .o-sectionInfo__title,
.f-h18 {
  font-family: "neue-display-random";
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.01rem; }
  @media screen and (min-width: 40em) {
    .pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title, .m-moduleHeader__title, .o-heroFlyout__title, .o-heroFullScreen__main-title, .o-sectionInfo__title,
    .f-h18 {
      font-size: 50px;
      line-height: 50px; } }
  @media screen and (min-width: 61.25em) {
    .pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title, .m-moduleHeader__title, .o-heroFlyout__title, .o-heroFullScreen__main-title, .o-sectionInfo__title,
    .f-h18 {
      font-size: 55px;
      line-height: 55px; } }
  @media screen and (min-width: 72.5em) {
    .pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title, .m-moduleHeader__title, .o-heroFlyout__title, .o-heroFullScreen__main-title, .o-sectionInfo__title,
    .f-h18 {
      font-size: 70px;
      line-height: 70px;
      letter-spacing: -0.02rem; } }
  @media screen and (min-width: 90.625em) {
    .pageClass--l2 .m-featurePanel:not(.m-featurePanel--small) .m-landingPanel__title, .m-moduleHeader__title, .o-heroFlyout__title, .o-heroFullScreen__main-title, .o-sectionInfo__title,
    .f-h18 {
      font-size: 85px;
      line-height: 85px; } }

.o-heroSidebySide__title,
.f-h2 {
  font-family: "neue-display-random";
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 61.25em) {
    .o-heroSidebySide__title,
    .f-h2 {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -0.01rem; } }
  @media screen and (min-width: 72.5em) {
    .o-heroSidebySide__title,
    .f-h2 {
      font-size: 50px;
      line-height: 50px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroSidebySide__title,
    .f-h2 {
      font-size: 60px;
      line-height: 60px; } }


.f-h3 {
  font-family: "neue-display-random";
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 61.25em) {
    
    .f-h3 {
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -0.01rem; } }
  @media screen and (min-width: 72.5em) {
    
    .f-h3 {
      font-size: 35px;
      line-height: 35px; } }
  @media screen and (min-width: 90.625em) {
    
    .f-h3 {
      font-size: 40px;
      line-height: 40px; } }


.f-h20 {
  font-family: "neue";
  font-size: 30px;
  line-height: 40px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    
    .f-h20 {
      font-size: 32px;
      line-height: 40px; } }
  @media screen and (min-width: 40em) {
    
    .f-h20 {
      font-size: 38px;
      line-height: 45px; } }
  @media screen and (min-width: 61.25em) {
    
    .f-h20 {
      font-size: 50px;
      line-height: 60px; } }

.m-contentFilters__title, .m-post--feature .m-post__title, .o-quoteMedia__main blockquote p, .o-studyAreas__more-title,
.f-h4 {
  font-family: "neue";
  font-size: 22px;
  line-height: 30px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .m-contentFilters__title, .m-post--feature .m-post__title, .o-quoteMedia__main blockquote p, .o-studyAreas__more-title,
    .f-h4 {
      font-size: 25px;
      line-height: 35px; } }
  @media screen and (min-width: 40em) {
    .m-contentFilters__title, .m-post--feature .m-post__title, .o-quoteMedia__main blockquote p, .o-studyAreas__more-title,
    .f-h4 {
      font-size: 30px;
      line-height: 40px; } }
  @media screen and (min-width: 61.25em) {
    .m-contentFilters__title, .m-post--feature .m-post__title, .o-quoteMedia__main blockquote p, .o-studyAreas__more-title,
    .f-h4 {
      font-size: 40px;
      line-height: 50px; } }


.f-h44 {
  font-family: "neue";
  font-size: 25px;
  line-height: 25px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 61.25em) {
    
    .f-h44 {
      font-size: 30px;
      line-height: 35px; } }

.a-accordionCard__title, .m-drawers--alt .m-drawers__toggle span, .m-post__title, .m-post__quote p, .m-postInline__title, .o-fastFactsDetail__content, .o-shuffler__data .o-shuffler__data--large,
.f-h5 {
  font-family: "neue";
  font-size: 18px;
  line-height: 30px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 40em) {
    .a-accordionCard__title, .m-drawers--alt .m-drawers__toggle span, .m-post__title, .m-post__quote p, .m-postInline__title, .o-fastFactsDetail__content, .o-shuffler__data .o-shuffler__data--large,
    .f-h5 {
      font-size: 22px;
      line-height: 35px; } }

.m-landingIntro--large p, .o-heroFullScreen__text, .o-textLongForm__content .a-img + p,
.o-textLongForm__content p:first-child,
.f-h6 {
  font-family: "neue";
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 40em) {
    .m-landingIntro--large p, .o-heroFullScreen__text, .o-textLongForm__content .a-img + p,
    .o-textLongForm__content p:first-child,
    .f-h6 {
      font-size: 18px;
      line-height: 30px; } }
  @media screen and (min-width: 61.25em) {
    .m-landingIntro--large p, .o-heroFullScreen__text, .o-textLongForm__content .a-img + p,
    .o-textLongForm__content p:first-child,
    .f-h6 {
      font-size: 22px;
      line-height: 35px; } }


.f-h7 {
  font-family: "neue";
  font-size: 10px;
  line-height: 15px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03rem; }
  @media screen and (min-width: 40em) {
    
    .f-h7 {
      font-size: 12px;
      line-height: 18px; } }
  @media screen and (min-width: 72.5em) {
    
    .f-h7 {
      font-size: 13px;
      line-height: 20px; } }

.m-contentList--grid-view .a-program--simple .a-program__blockLinkTitle, .o-careerOutcomes__role, .o-studyAreas__footer,
.f-h8 {
  font-family: "neue";
  font-size: 15px;
  line-height: 25px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 72.5em) {
    .m-contentList--grid-view .a-program--simple .a-program__blockLinkTitle, .o-careerOutcomes__role, .o-studyAreas__footer,
    .f-h8 {
      font-size: 17px;
      line-height: 30px; } }
  @media screen and (min-width: 90.625em) {
    .m-contentList--grid-view .a-program--simple .a-program__blockLinkTitle, .o-careerOutcomes__role, .o-studyAreas__footer,
    .f-h8 {
      font-size: 18px;
      line-height: 32px; } }

.o-fastFacts__item,
.f-h8-b {
  font-family: "neue";
  font-size: 15px;
  line-height: 25px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 72.5em) {
    .o-fastFacts__item,
    .f-h8-b {
      font-size: 25px;
      line-height: 30px; } }

.m-contentList--grid-view .a-program__content--alt .a-program__description,
.m-contentList--list-view .a-program__content--alt .a-program__description, .m-ctaPanel p, .m-moduleHeader__intro, .m-postInline__excerpt, .o-careerOutcomes__content p, .o-sectionInfo--alt .o-sectionInfo__content, .o-studyAreas__more p, .o-textLongForm__content p,
.f-h15 {
  font-family: "neue";
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 72.5em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description,
    .m-contentList--list-view .a-program__content--alt .a-program__description, .m-ctaPanel p, .m-moduleHeader__intro, .m-postInline__excerpt, .o-careerOutcomes__content p, .o-sectionInfo--alt .o-sectionInfo__content, .o-studyAreas__more p, .o-textLongForm__content p,
    .f-h15 {
      font-size: 17px;
      line-height: 30px; } }
  @media screen and (min-width: 90.625em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description,
    .m-contentList--list-view .a-program__content--alt .a-program__description, .m-ctaPanel p, .m-moduleHeader__intro, .m-postInline__excerpt, .o-careerOutcomes__content p, .o-sectionInfo--alt .o-sectionInfo__content, .o-studyAreas__more p, .o-textLongForm__content p,
    .f-h15 {
      font-size: 18px;
      line-height: 32px; } }

.m-listPanel__item, .m-pub__title,
.f-h14 {
  font-family: "neue";
  font-size: 13px;
  line-height: 20px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .m-listPanel__item, .m-pub__title,
    .f-h14 {
      line-height: 23px; } }
  @media screen and (min-width: 72.5em) {
    .m-listPanel__item, .m-pub__title,
    .f-h14 {
      font-size: 14px;
      line-height: 25px; } }
  @media screen and (min-width: 90.625em) {
    .m-listPanel__item, .m-pub__title,
    .f-h14 {
      font-size: 15px;
      line-height: 25px; } }

.m-contentList--grid-view .a-program__content--alt .a-program__description p.a-program__note,
.m-contentList--list-view .a-program__content--alt .a-program__description p.a-program__note, .m-post__excerpt, .m-post__link, .m-post__quote cite, .m-pub__subtitle, .m-pub__meta, .m-sidebarModule__events-title, .o-accordionHorizontal__item-title, .o-careerOutcomes__name, .o-careerOutcomes__course, .o-fastFactsDetail__footer, .o-quoteCarousel cite, .o-quoteMedia__main blockquote cite, .o-quotePortrait__main blockquote cite, .o-shuffler__data dd, .o-textLongForm__trigger,
.f-h10 {
  font-family: "neue";
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description p.a-program__note,
    .m-contentList--list-view .a-program__content--alt .a-program__description p.a-program__note, .m-post__excerpt, .m-post__link, .m-post__quote cite, .m-pub__subtitle, .m-pub__meta, .m-sidebarModule__events-title, .o-accordionHorizontal__item-title, .o-careerOutcomes__name, .o-careerOutcomes__course, .o-fastFactsDetail__footer, .o-quoteCarousel cite, .o-quoteMedia__main blockquote cite, .o-quotePortrait__main blockquote cite, .o-shuffler__data dd, .o-textLongForm__trigger,
    .f-h10 {
      line-height: 23px; } }
  @media screen and (min-width: 72.5em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description p.a-program__note,
    .m-contentList--list-view .a-program__content--alt .a-program__description p.a-program__note, .m-post__excerpt, .m-post__link, .m-post__quote cite, .m-pub__subtitle, .m-pub__meta, .m-sidebarModule__events-title, .o-accordionHorizontal__item-title, .o-careerOutcomes__name, .o-careerOutcomes__course, .o-fastFactsDetail__footer, .o-quoteCarousel cite, .o-quoteMedia__main blockquote cite, .o-quotePortrait__main blockquote cite, .o-shuffler__data dd, .o-textLongForm__trigger,
    .f-h10 {
      font-size: 14px;
      line-height: 25px; } }
  @media screen and (min-width: 90.625em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description p.a-program__note,
    .m-contentList--list-view .a-program__content--alt .a-program__description p.a-program__note, .m-post__excerpt, .m-post__link, .m-post__quote cite, .m-pub__subtitle, .m-pub__meta, .m-sidebarModule__events-title, .o-accordionHorizontal__item-title, .o-careerOutcomes__name, .o-careerOutcomes__course, .o-fastFactsDetail__footer, .o-quoteCarousel cite, .o-quoteMedia__main blockquote cite, .o-quotePortrait__main blockquote cite, .o-shuffler__data dd, .o-textLongForm__trigger,
    .f-h10 {
      font-size: 15px;
      line-height: 25px; } }

.m-drawers--alt .m-drawers__toggle span.m-drawers__subtitle, .m-listPanel__title, .m-post__tag, .m-postInline__meta, .m-sidebarModule__events-meta, .o-fastFactsDetail__title, .o-quoteCarousel__nav-item, .o-shuffler__data dt, .o-shuffler__nav, .o-three-col__nav-item,
.f-h11 {
  font-family: "neue";
  font-size: 8px;
  line-height: 10px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03rem;
  text-transform: uppercase; }
  @media screen and (min-width: 40em) {
    .m-drawers--alt .m-drawers__toggle span.m-drawers__subtitle, .m-listPanel__title, .m-post__tag, .m-postInline__meta, .m-sidebarModule__events-meta, .o-fastFactsDetail__title, .o-quoteCarousel__nav-item, .o-shuffler__data dt, .o-shuffler__nav, .o-three-col__nav-item,
    .f-h11 {
      font-size: 10px;
      line-height: 15px; } }

.o-quoteCarousel blockquote p, .o-quotePortrait__main blockquote p,
.f-h19 {
  font-family: "neue";
  font-size: 22px;
  line-height: 30px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .o-quoteCarousel blockquote p, .o-quotePortrait__main blockquote p,
    .f-h19 {
      font-size: 25px;
      line-height: 35px; } }
  @media screen and (min-width: 90.625em) {
    .o-quoteCarousel blockquote p, .o-quotePortrait__main blockquote p,
    .f-h19 {
      font-size: 30px;
      line-height: 40px; } }

@media screen and (min-width: 61.25em) {
  .g-breadcrumbs {
    max-width: calc(100% - 80px); } }

@media screen and (min-width: 72.5em) {
  .g-breadcrumbs {
    max-width: calc(100% - 120px); } }

@media screen and (min-width: 90.625em) {
  .g-breadcrumbs {
    max-width: 80%; } }

.g-header--has-utility-on-mobile {
  top: 0; }

@media screen and (max-width: 39.9375em) {
  .g-header--college.g-header--has-utility-on-mobile + main {
    padding-top: 70px; } }

@media print, screen and (min-width: 40em) {
  .g-header--college.g-header--has-utility-on-mobile + main {
    padding-top: 85px; } }

.a-btn--grey--light {
  border: 2px solid #D9D9D9;
  color: #8C8C8C; }
  @media screen and (min-width: 61.25em) {
    .a-btn--grey--light:hover, .a-btn--grey--light:active {
      background: #D9D9D9;
      border: 2px solid #D9D9D9;
      color: #FFFFFF;
      cursor: pointer; } }
  .body--highlight .a-btn--grey--light {
    border: 2px solid #FFFFFF;
    color: #FFFFFF; }
    .body--highlight .a-btn--grey--light:hover, .body--highlight .a-btn--grey--light:active {
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      color: #E42A1D; }

.a-accordionCard__cta, .m-moduleHeader__cta, .m-post__cta, .o-accordionHorizontal__footer-cta, .o-careerOutcomes__cta, .o-fiveCol__footer-cta, .o-listingFull__cta, .o-quoteCarousel__cta, .o-quotePortrait__cta, .o-sectionInfo__cta, .o-studyAreas__more-cta, .o-three-col__footer-cta, .o-twoThirds__cta,
.a-cta--dual-line {
  font-family: "neue";
  font-size: 10px;
  line-height: 15px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.05rem;
  border-bottom: 2px solid;
  clear: both;
  color: #E42A1D;
  display: inline-block;
  margin: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transform: translate3D(0, 0, 0);
  transition: border-bottom ease-in-out 180ms; }
  .a-accordionCard__cta:after, .m-moduleHeader__cta:after, .m-post__cta:after, .o-accordionHorizontal__footer-cta:after, .o-careerOutcomes__cta:after, .o-fiveCol__footer-cta:after, .o-listingFull__cta:after, .o-quoteCarousel__cta:after, .o-quotePortrait__cta:after, .o-sectionInfo__cta:after, .o-studyAreas__more-cta:after, .o-three-col__footer-cta:after, .o-twoThirds__cta:after,
  .a-cta--dual-line:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    border-top: 2px solid; }

.a-accordionCard {
  height: 100%;
  padding-top: 18px;
  padding-bottom: 12px;
  border-top: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard {
      min-height: 490px;
      padding-top: 0;
      padding-bottom: 0;
      border-top: 0; }
      .a-accordionCard:hover .a-accordionCard__hover-off, .a-accordionCard:active .a-accordionCard__hover-off {
        opacity: 0;
        pointer-events: none; }
      .a-accordionCard:hover .a-accordionCard__hover-on, .a-accordionCard:active .a-accordionCard__hover-on {
        transition-delay: 0.1s; }
      .a-accordionCard:hover .a-accordionCard__hover-on,
      .a-accordionCard:hover .a-accordionCard__trigger .a-accordionCard__cta,
      .a-accordionCard:hover .a-accordionCard__trigger .a-accordionCard__link, .a-accordionCard:active .a-accordionCard__hover-on,
      .a-accordionCard:active .a-accordionCard__trigger .a-accordionCard__cta,
      .a-accordionCard:active .a-accordionCard__trigger .a-accordionCard__link {
        opacity: 1;
        pointer-events: all; } }

.a-accordionCard a {
  text-decoration: none; }

.a-accordionCard__body,
.a-accordionCard__title a,
.a-accordionCard__subtitle,
.a-accordionCard__secondary li a,
.a-accordionCard__body .a-accordionCard__link,
.a-accordionCard__body .a-accordionCard__cta,
.a-accordionCard__body .a-accordionCard__cta:after {
  transition: all ease-in-out 180ms; }

.a-accordionCard__body {
  font-family: "neue";
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  position: relative; }
  .a-accordionCard__body p {
    margin-top: 0;
    padding-top: 15px; }
  @media screen and (min-width: 40em) {
    .a-accordionCard__body {
      font-family: "neue";
      font-size: 14px;
      line-height: 25px;
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0; }
      .a-accordionCard__body p {
        margin-top: 22px;
        padding-top: 0; } }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__body {
      display: block;
      height: 100%;
      min-height: 450px;
      padding: 28px 30px 79px;
      background: #F1F2F2; }
      .a-accordionCard__body .a-accordionCard__cta,
      .a-accordionCard__body .a-accordionCard__link {
        position: absolute; }
      .a-accordionCard__body .a-accordionCard__cta {
        margin: 0; } }

.a-accordionCard__trigger {
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__trigger {
      display: block;
      position: relative;
      cursor: default; } }
  .a-accordionCard__trigger:hover .a-accordionCard__title, .a-accordionCard__trigger:active .a-accordionCard__title {
    color: #E42A1D; }
    @media screen and (min-width: 61.25em) {
      .a-accordionCard__trigger:hover .a-accordionCard__title, .a-accordionCard__trigger:active .a-accordionCard__title {
        color: inherit; } }
  .a-accordionCard__trigger .a-accordionCard__cta,
  .a-accordionCard__trigger .a-accordionCard__link {
    display: none; }
    @media screen and (min-width: 61.25em) {
      .a-accordionCard__trigger .a-accordionCard__cta,
      .a-accordionCard__trigger .a-accordionCard__link {
        display: inline-block;
        top: 100%;
        margin-top: 12px;
        opacity: 0; } }

.a-accordionCard__subtitle {
  display: block;
  color: #8c8c8c;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  order: 2; }

.a-accordionCard__title {
  display: block;
  margin: 0;
  padding-right: 35px;
  order: 1; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__title {
      padding-right: 0; } }
  .a-accordionCard__title a {
    pointer-events: none; }
    @media screen and (min-width: 61.25em) {
      .a-accordionCard__title a {
        pointer-events: all; } }
    .a-accordionCard__title a:hover, .a-accordionCard__title a:active {
      color: #E42A1D; }

.a-accordionCard__title a:hover, .a-accordionCard__title a:active {
  color: #E42A1D; }

@media screen and (min-width: 61.25em) {
  .a-accordionCard__subtitle + .a-accordionCard__title {
    margin-top: 15px; } }

.a-accordionCard__inner {
  order: 3;
  height: 0;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.5, 0, 0.5, 0); }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__inner {
      height: auto;
      overflow: visible; } }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__inner .a-accordionCard__cta,
    .a-accordionCard__inner .a-accordionCard__link {
      bottom: 45px; } }

.a-accordionCard__link {
  border-bottom: 1px solid transparent;
  display: inline-block;
  margin: -1px 0 0 20px;
  padding: 1px 0 1px;
  position: relative;
  text-decoration: none;
  transition: border-bottom ease-in-out 180ms, color ease-in-out 180ms; }
  .a-accordionCard__link:hover, .a-accordionCard__link:active {
    color: #E42A1D; }
  .a-accordionCard__link:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: -25px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23000' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
    background-size: 100% auto;
    transition: opacity ease-in-out 180ms; }
    @media screen and (min-width: 61.25em) {
      .a-accordionCard__link:before {
        top: 6px; } }

.a-accordionCard--dark {
  color: #FFFFFF;
  background: #212121; }

.a-accordionCard__cta,
.a-accordionCard__link {
  margin-top: 15px; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__cta,
    .a-accordionCard__link {
      margin-top: 0; } }

@media screen and (min-width: 61.25em) {
  .a-accordionCard__hover-off {
    transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1); } }

@media screen and (min-width: 61.25em) {
  .a-accordionCard__hover-on {
    position: absolute;
    bottom: 0;
    min-height: 172px;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1); } }

.a-accordionCard__hover-on,
.a-accordionCard__secondary {
  margin-top: 30px;
  padding-bottom: 33px; }

.a-accordionCard__inner .a-accordionCard__subtitle {
  margin-bottom: 17px;
  color: #191919; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__inner .a-accordionCard__subtitle {
      margin-bottom: 19px; } }

@media screen and (min-width: 61.25em) {
  .a-accordionCard__inner ul {
    display: flex;
    flex-flow: row wrap; } }

.a-accordionCard__inner li {
  margin-top: 15px; }
  .a-accordionCard__inner li:first-child {
    margin-top: 0; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard__inner li {
      width: 50%;
      margin-top: 0; }
      .a-accordionCard__inner li:nth-child(2n+1) {
        padding-right: 10px; } }

.a-accordionCard__inner li a {
  border-bottom: 1px solid; }
  .a-accordionCard__inner li a:hover, .a-accordionCard__inner li a:active {
    color: #E42A1D;
    border-bottom-color: transparent; }

.a-accordionCard .icon--minus,
.a-accordionCard .icon--plus-alt {
  position: absolute;
  right: 0;
  color: #D9D9D9;
  pointer-events: none; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard .icon--minus,
    .a-accordionCard .icon--plus-alt {
      display: none; } }

.a-accordionCard .icon--minus {
  top: 13px; }

.a-accordionCard .icon--plus-alt {
  top: 8px; }

.a-accordionCard--active .icon--plus-alt {
  display: none; }

.o-three-col__item .a-accordionCard {
  width: 100%;
  padding-left: 0;
  padding-right: 0; }

.a-accordionCard--no-hover .a-accordionCard__cta,
.a-accordionCard--no-hover .a-accordionCard__link {
  position: relative;
  bottom: auto; }
  @media screen and (min-width: 61.25em) {
    .a-accordionCard--no-hover .a-accordionCard__cta,
    .a-accordionCard--no-hover .a-accordionCard__link {
      margin-top: 30px; } }

@media screen and (min-width: 61.25em) {
  .a-accordionCard--no-hover .a-accordionCard__secondary {
    position: absolute;
    bottom: 0;
    padding-bottom: 53px; } }

.m-contentList--grid-view .a-program--simple {
  border-top: 1px solid #D9D9D9; }
  .m-contentList--grid-view .a-program--simple.is-active::before, .m-contentList--grid-view .a-program--simple.is-active::after {
    bottom: -1px; }
  .m-contentList--grid-view .a-program--simple .a-program__blockLink {
    padding-top: 13px;
    padding-bottom: 18px; }
    @media screen and (min-width: 40em) {
      .m-contentList--grid-view .a-program--simple .a-program__blockLink {
        padding-top: 27px;
        padding-bottom: 31px; } }
  .m-contentList--grid-view .a-program--simple .a-program__blockLinkTitle {
    position: relative;
    margin-top: 0;
    padding-right: 25px; }
  .m-contentList--grid-view .a-program--simple .a-program__blockLinkSubTitle {
    display: none; }
  .m-contentList--grid-view .a-program--simple .icon--chevron-down-alt {
    position: absolute;
    top: 10px;
    right: 0;
    width: 12px;
    height: 8px; }
    @media screen and (min-width: 72.5em) {
      .m-contentList--grid-view .a-program--simple .icon--chevron-down-alt {
        top: 15px; } }

.a-program__description .a-program__description-list {
  padding-left: 1.25em;
  list-style-type: square; }

.m-contentList--list-view .a-program--simple .icon--chevron-down-alt {
  display: none; }

.m-contentList--grid-view .a-program__content--alt,
.m-contentList--list-view .a-program__content--alt {
  margin-bottom: 0;
  border-bottom: 0; }
  @media screen and (min-width: 61.25em) {
    .m-contentList--grid-view .a-program__content--alt,
    .m-contentList--list-view .a-program__content--alt {
      padding-bottom: 95px; } }
  .m-contentList--grid-view .a-program__content--alt .a-program__contentTitle,
  .m-contentList--list-view .a-program__content--alt .a-program__contentTitle {
    display: block;
    margin-top: 0; }
  .m-contentList--grid-view .a-program__content--alt .a-program__description,
  .m-contentList--list-view .a-program__content--alt .a-program__description {
    margin-top: 10px; }
    @media screen and (min-width: 61.25em) {
      .m-contentList--grid-view .a-program__content--alt .a-program__description,
      .m-contentList--list-view .a-program__content--alt .a-program__description {
        margin-top: 30px; } }
  .m-contentList--grid-view .a-program__content--alt .a-program__description ul,
  .m-contentList--list-view .a-program__content--alt .a-program__description ul {
    margin-top: 17px; }
  .m-contentList--grid-view .a-program__content--alt .a-program__description li a,
  .m-contentList--list-view .a-program__content--alt .a-program__description li a {
    margin-top: 0; }
  @media screen and (min-width: 61.25em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description li a::before,
    .m-contentList--list-view .a-program__content--alt .a-program__description li a::before {
      top: 4px; } }
  @media screen and (min-width: 72.5em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description li a::before,
    .m-contentList--list-view .a-program__content--alt .a-program__description li a::before {
      top: 9px; } }
  @media screen and (min-width: 90.625em) {
    .m-contentList--grid-view .a-program__content--alt .a-program__description li a::before,
    .m-contentList--list-view .a-program__content--alt .a-program__description li a::before {
      top: 10px; } }
  .m-contentList--grid-view .a-program__content--alt .a-program__description h5,
  .m-contentList--list-view .a-program__content--alt .a-program__description h5 {
    margin-top: 13px; }

.m-contentList--grid-view .a-program--alt .icon--plus-alt {
  display: none; }

@media screen and (min-width: 61.25em) {
  .m-contentList--list-view .a-program--alt .a-program__blockLinkTitle {
    width: 86%; } }

.m-contentList--list-view .a-program--alt .a-program__blockLink {
  position: relative;
  padding-right: 25px; }
  .m-contentList--list-view .a-program--alt .a-program__blockLink:hover .icon--plus-alt {
    color: #585858; }

.m-contentList--list-view .a-program--alt .icon--plus-alt {
  position: absolute;
  top: 50%;
  right: 0;
  color: #D9D9D9;
  transform: translateY(-50%); }

.o-primaryContent ul .a-program ul,
.o-primaryContent ul .a-program ol,
.o-primaryContent ul .a-program__description ul,
.o-primaryContent ul .a-program__description ol {
  padding-left: 0; }
  .o-primaryContent ul .a-program ul li,
  .o-primaryContent ul .a-program ol li,
  .o-primaryContent ul .a-program__description ul li,
  .o-primaryContent ul .a-program__description ol li {
    margin-top: 0; }
  .o-primaryContent ul .a-program ul li::before,
  .o-primaryContent ul .a-program ol li::before,
  .o-primaryContent ul .a-program__description ul li::before,
  .o-primaryContent ul .a-program__description ol li::before {
    display: none; }
  .o-primaryContent ul .a-program ul li a,
  .o-primaryContent ul .a-program ol li a,
  .o-primaryContent ul .a-program__description ul li a,
  .o-primaryContent ul .a-program__description ol li a {
    border: 0; }
  .o-primaryContent ul .a-program ul li:first-child a,
  .o-primaryContent ul .a-program ol li:first-child a,
  .o-primaryContent ul .a-program__description ul li:first-child a,
  .o-primaryContent ul .a-program__description ol li:first-child a {
    margin-top: 0; }

.o-primaryContent .a-table ul,
.o-primaryContent .a-table ol,
.o-primaryContent .a-table li,
.a-table p {
  margin-top: 15px; }
  .o-primaryContent .a-table ul:first-child,
  .o-primaryContent .a-table ol:first-child,
  .o-primaryContent .a-table li:first-child,
  .a-table p:first-child {
    margin-top: 0; }

.o-primaryContent .a-table ul li::before {
  top: 8px; }

@media screen and (min-width: 61.25em) {
  .m-cards {
    flex-flow: row wrap; } }

.m-collegeList__item {
  margin-top: 19px;
  padding-top: 16px; }
  @media screen and (min-width: 61.25em) {
    .m-collegeList__item {
      margin-top: 0;
      padding-top: 0; } }

@media screen and (max-width: 61.1875em) {
  .m-collegeList__toggle {
    font-size: 16px;
    line-height: 25px; } }

@media screen and (max-width: 61.1875em) {
  .m-collegeList__content {
    padding-top: 17px; } }

@media screen and (max-width: 61.1875em) {
  .m-collegeList__cta {
    margin-top: 20px;
    margin-bottom: 20px; } }

.m-contentFilters--simple,
.m-contentFilters--title {
  margin: 0;
  border-top: 0; }

.m-contentList--list-view .m-contentFilters--title {
  margin-top: 26px; }
  @media screen and (min-width: 40em) {
    .m-contentList--list-view .m-contentFilters--title {
      margin-top: 107px; } }

.m-contentFilters--title:first-child {
  margin-top: 0; }

.m-contentFilters__title {
  margin-top: 0;
  margin-bottom: 15px; }
  @media screen and (min-width: 40em) {
    .m-contentFilters__title {
      margin-bottom: 41px; } }

.m-contentFilters__displayOptions {
  margin-top: 18px; }

@media screen and (min-width: 40em) {
  .pageClass--l2 .m-contentList__noResults {
    padding-right: 0;
    padding-left: 0; } }

.pageClass--l2 h4 + .m-contentList {
  margin-top: 20px; }
  @media screen and (min-width: 61.25em) {
    .pageClass--l2 h4 + .m-contentList {
      margin-top: 30px; } }

.m-contentList--list-view .ais-infinite-hits--showmore {
  margin-top: 0; }

.m-contentList--list-view .ais-infinite-hits--showmoreButton {
  margin-top: 80px; }

.m-contentFiltersAjax {
  width: 100%; }

@media screen and (min-width: 40em) {
  .m-contentFilters__items.m-contentFilters__items--vertical {
    column-count: 3;
    column-gap: 40px;
    padding-right: 55px !important; } }

@media screen and (min-width: 40em) {
  .m-contentFilters__items.m-contentFilters__items--vertical .m-contentFilters__item {
    float: none;
    width: auto;
    padding: 0; } }

.m-contentFilters__list.is-active {
  max-height: 800px; }

.m-courses {
  padding-bottom: 0; }

.m-courses .m-moduleHeader__intro {
  margin-bottom: 0; }

.m-ctaPanel.s-module-bkgd {
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 39px; }
  @media screen and (min-width: 40em) {
    .m-ctaPanel.s-module-bkgd {
      padding-top: 69px;
      padding-bottom: 75px; } }

.m-ctaPanel h2,
.pageClass--l0 .m-ctaPanel h2,
.pageClass--l1 .m-ctaPanel h2 {
  margin-top: 0; }

.m-ctaPanel p {
  margin-top: 30px; }

.m-ctaPanel p:first-child {
  margin-top: 15px; }
  @media screen and (min-width: 40em) {
    .m-ctaPanel p:first-child {
      margin-top: 4px; } }
  @media screen and (min-width: 61.25em) {
    .m-ctaPanel p:first-child {
      margin-top: 19px; } }

.m-drawers__content.m-drawers__content {
  padding-top: 10px; }

.m-drawers--alt .m-drawers__toggle:hover span, .m-drawers--alt .m-drawers__toggle:active span {
  border-bottom-color: transparent;
  color: #E42A1D; }

.m-drawers--alt .m-drawers__toggle span {
  display: block;
  color: #191919; }

.m-drawers--alt .m-drawers__toggle span.m-drawers__subtitle {
  margin-top: 3px;
  color: #E42A1D; }
  @media screen and (min-width: 61.25em) {
    .m-drawers--alt .m-drawers__toggle span.m-drawers__subtitle {
      margin-top: 8px; } }

.m-textBlock + .m-drawers,
.m-textBlock + .o-primaryContent .m-drawers {
  margin-top: 0; }

.m-featurePanel .m-landingPanel {
  padding-bottom: 0; }

@media screen and (min-width: 61.25em) {
  .m-featurePanel .m-landingPanel .row {
    align-items: flex-start; } }

.m-featurePanel--link-list .m-landingPanel__content .f-h11,
.m-landingPanel__content .m-featurePanel--link-list .f-h11 {
  color: #E42A1D; }

.m-featurePanel .m-landingPanel__content ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none; }

.m-featurePanel .m-landingPanel__content ul li::before {
  display: none; }

.m-landingPanel__content .m-featurePanel--bullet-list ul {
  margin: 30px 0 0;
  padding: 0 0 0 25px; }

.m-landingPanel__content .m-featurePanel--bullet-list ul li::before {
  display: inline; }

@media screen and (min-width: 61.25em) {
  .pageClass--l2 .m-featurePanel .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__title {
    transform: translateX(-40px); } }

@media screen and (min-width: 61.25em) {
  .pageClass--l2 .m-featurePanel .m-landingPanel__title,
  .pageClass--l2 .m-featurePanel .m-landingPanel--alt .m-landingPanel__title {
    top: 19px;
    margin-bottom: 0; } }

@media screen and (max-width: 61.1875em) {
  .m-featurePanel + .m-featurePanel--small {
    margin-top: 15px; } }

@media screen and (max-width: 61.1875em) {
  .m-featurePanel--small + .m-featurePanel--small {
    margin-top: -39px; } }

.m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list {
  margin: 15px 0 0; }
  .m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list li {
    margin: 0 0 15px; }
  .m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list a {
    margin: 0;
    border-bottom: 2px solid #E42A1D; }
    .m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list a:hover, .m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list a:active {
      border-bottom: 2px solid #E42A1D; }
    .m-featurePanel .m-landingPanel__content .m-landingPanel__cta-list a::before {
      display: none; }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel__title {
    font-family: "neue-display-random";
    -webkit-font-feature-settings: 'liga' 1;
    -o-font-feature-settings: 'liga' 1;
    -moz-font-feature-settings: 'liga' 1;
    -ms-font-feature-settings: 'liga' 1;
    font-feature-settings: 'liga' 1;
    font-size: 45px;
    line-height: 45px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.01rem;
    border: none;
    top: 20px; } }

@media screen and (min-width: 72.5em) {
  .m-featurePanel--small-alt .m-landingPanel__title {
    font-family: "neue-display-random";
    -webkit-font-feature-settings: 'liga' 1;
    -o-font-feature-settings: 'liga' 1;
    -moz-font-feature-settings: 'liga' 1;
    -ms-font-feature-settings: 'liga' 1;
    font-feature-settings: 'liga' 1;
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.01rem; } }

@media screen and (min-width: 90.625em) {
  .m-featurePanel--small-alt .m-landingPanel__title {
    font-family: "neue-display-random";
    -webkit-font-feature-settings: 'liga' 1;
    -o-font-feature-settings: 'liga' 1;
    -moz-font-feature-settings: 'liga' 1;
    -ms-font-feature-settings: 'liga' 1;
    font-feature-settings: 'liga' 1;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.01rem; } }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel__imageLink::after {
    width: calc(100% - 40px); } }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink::after {
    left: 40px;
    width: calc(100% - 40px); } }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink p {
    left: 60px; } }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel__image {
    padding: 0 40px 0 0; } }

@media screen and (min-width: 61.25em) {
  .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__image {
    padding: 0 0 0 40px; } }

.m-highlightsGrid {
  display: block;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 43px; }
  .m-highlightsGrid.add-separator--top:before {
    width: 100%; }
  @media screen and (min-width: 40em) {
    .m-highlightsGrid {
      padding-top: 22px;
      padding-bottom: 66px; } }

@media screen and (min-width: 61.25em) {
  .m-highlightsGrid h2 {
    margin-top: 45px; } }

.m-highlightsGrid__items,
.o-primaryContent ul .m-highlightsGrid__items {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  list-style: none; }
  @media screen and (min-width: 40em) {
    .m-highlightsGrid__items,
    .o-primaryContent ul .m-highlightsGrid__items {
      display: flex;
      flex-flow: row wrap;
      margin-top: 29px; } }

.m-highlightsGrid li,
.o-primaryContent ul .m-highlightsGrid li {
  float: none !important;
  margin-top: 20px; }
  .m-highlightsGrid li:first-child,
  .o-primaryContent ul .m-highlightsGrid li:first-child {
    margin-top: 0; }
  .m-highlightsGrid li::before,
  .o-primaryContent ul .m-highlightsGrid li::before {
    display: none; }
  @media screen and (min-width: 40em) {
    .m-highlightsGrid li,
    .o-primaryContent ul .m-highlightsGrid li {
      width: 50%;
      margin-top: 29px; }
      .m-highlightsGrid li:nth-child(-n+2),
      .o-primaryContent ul .m-highlightsGrid li:nth-child(-n+2) {
        margin-top: 0; } }

.m-highlightsGrid__title {
  margin-top: 0; }

.o-primaryContent.m-textBlock .m-highlightsGrid li::before {
  display: none; }

.m-landingIntro--large {
  margin-bottom: -7px; }
  @media screen and (min-width: 40em) {
    .m-landingIntro--large {
      margin-bottom: -8px; } }
  @media screen and (min-width: 61.25em) {
    .m-landingIntro--large {
      margin-bottom: -9px; } }

.body--highlight .m-landingIntro p a {
  border-color: #FFFFFF; }

.m-listPanel {
  background: #F1F2F2; }

.m-listPanel__title {
  display: block;
  padding: 18px 20px 16px;
  color: #E42A1D; }

.m-listPanel__item {
  border-top: 1px solid #D9D9D9; }

.m-listPanel__item a {
  display: block;
  padding: 16px 20px 18px;
  text-decoration: none; }
  .m-listPanel__item a:hover {
    color: #E42A1D; }

@media screen and (min-width: 61.25em) {
  .o-twoThirds .m-listPanel {
    min-height: calc(100% - 48px); } }

.m-moduleHeader__title-wrap {
  float: none; }

.m-moduleHeader__title {
  margin-top: 0;
  color: #E42A1D; }
  .body--highlight .m-moduleHeader__title {
    color: #FFFFFF; }
  .m-moduleHeader__title.m-moduleHeader__title--has-anchor::before {
    display: block;
    content: " ";
    margin-top: -75px;
    height: 75px;
    visibility: hidden;
    pointer-events: none; }
    @media screen and (min-width: 61.25em) {
      .m-moduleHeader__title.m-moduleHeader__title--has-anchor::before {
        margin-top: -150px;
        height: 150px; } }

.m-moduleHeader__intro {
  float: none;
  margin-top: 18px;
  margin-bottom: 45px; }
  @media screen and (min-width: 61.25em) {
    .m-moduleHeader__intro {
      margin-top: 31px; } }

.m-moduleHeader__intro p:first-child {
  margin-top: 0; }

.m-moduleHeader__cta {
  margin-top: 22px; }

@media screen and (max-width: 72.4375em) {
  .m-navList__container .row {
    margin: 0; } }

@media screen and (max-width: 39.9375em) {
  .m-navList--tertiary {
    padding-left: 10px;
    padding-right: 10px; }
    .m-navList--tertiary .m-navList__item {
      margin: 0 5px; } }

.m-pageHeader + .xsmall-show.small-show.medium-show {
  margin-top: 30px;
  margin-bottom: -25px; }

.m-post {
  position: relative;
  height: 100%; }

.m-post a {
  text-decoration: none; }

.m-post > a:not(.m-post__cta) {
  display: block;
  transition: color ease-in-out 180ms; }
  .m-post > a:not(.m-post__cta):hover, .m-post > a:not(.m-post__cta):active {
    color: #E42A1D; }

.m-post > a + .m-post__tag {
  margin-top: 23px; }

@media screen and (min-width: 40em) {
  .m-post.m-post--has-cta,
  .m-post.m-post--has-link {
    padding-bottom: 50px; } }

.m-post__image,
.m-post .a-video__link.a-video__link {
  margin: 0; }

.m-post__image {
  position: relative;
  width: 100%; }
  @media screen and (min-width: 61.25em) {
    .m-post__image::after {
      background: #E42A1D;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      transition: transform ease-out 360ms; }
      .body--highlight .m-post__image::after {
        background: #191919; } }
  @media screen and (min-width: 61.25em) {
    a:hover .m-post__image:after {
      transform: translate3D(10px, 10px, 0); }
    a:hover .m-post__image img {
      transform: translate3D(-10px, -10px, 0); } }

.m-post__image img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  transition: transform ease-out 360ms; }

.m-post__tag {
  display: block;
  color: #E42A1D; }

.m-post__title {
  margin-top: 18px;
  color: inherit; }
  .m-post__title:first-child {
    margin-top: 0; }

.m-post__title a,
.m-post__quote a {
  display: block;
  text-decoration: none;
  transition: color ease-in-out 180ms; }
  .m-post__title a:hover,
  .m-post__quote a:hover {
    color: #E42A1D; }

.m-post__tag + .m-post__title {
  margin-top: 11px; }

.m-post__excerpt {
  margin-top: 11px; }

.m-post__excerpt p:first-child {
  margin-top: 0; }

.m-post__cta,
.m-post__link {
  position: relative;
  margin-top: 22px; }
  @media screen and (min-width: 40em) {
    .m-post__cta,
    .m-post__link {
      position: absolute;
      left: 0; } }
  @media screen and (min-width: 40em) {
    .m-post--extra .m-post__cta, .m-post--extra
    .m-post__link {
      position: static; } }

.m-post__cta {
  margin-bottom: 4px; }
  @media screen and (min-width: 40em) {
    .m-post__cta {
      margin-top: 0;
      margin-bottom: 0;
      bottom: 4px; } }

.m-post__link {
  margin-left: 17px;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: border-bottom ease-out 180ms, color ease-out 180ms; }
  @media screen and (min-width: 40em) {
    .m-post__link {
      bottom: 0; } }
  .m-post__link:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23000' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E");
    background-size: 100% auto;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    left: -23px;
    transition: opacity ease-out 180ms; }
    @media screen and (min-width: 40em) {
      .m-post__link:before {
        top: 4px; } }
    @media screen and (min-width: 90.625em) {
      .m-post__link:before {
        top: 6px; } }
    .s-module-bkgd.s-module-bkgd--dark .m-post__link:before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E"); }
  @media screen and (min-width: 61.25em) {
    .m-post__link:hover, .m-post__link:active {
      border-bottom: 1px solid #E42A1D;
      color: #E42A1D; } }

@media screen and (min-width: 61.25em) {
  .m-post__slide {
    position: relative;
    z-index: 10;
    background-color: #FFFFFF;
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.m-post__slide-inner {
  padding-top: 23px;
  background-color: #FFFFFF; }

@media screen and (min-width: 61.25em) {
  .m-post__slide-hidden {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    min-height: 92px;
    padding-bottom: 20px;
    background-color: #FFFFFF;
    pointer-events: none; }
    .m-post__slide-hidden > * {
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1); } }

.m-post__quote {
  margin: 0;
  padding: 0;
  border: 0; }
  @media screen and (min-width: 61.25em) {
    .m-post__quote {
      margin-top: 6px; } }

.m-post__quote cite {
  display: block;
  margin-top: 25px; }
  @media screen and (min-width: 61.25em) {
    .m-post__quote cite {
      width: 90%;
      margin-top: 28px; } }
  .m-post__quote cite::before {
    content: '\2014';
    margin-right: 5px; }

.m-post__quote p:first-child::before {
  content: '“'; }

.m-post__quote p:last-of-type::after {
  content: '”'; }

.m-post--extra {
  display: flex;
  flex-flow: column nowrap; }
  .m-post--extra > * {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); }

.m-post--extra .a-video {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); }

.m-post--extra .m-post__image,
.m-post--extra .a-video {
  order: 1; }

.m-post--extra .m-post__slide {
  order: 2; }
  .m-post--extra .m-post__slide > * {
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1); }

@media screen and (min-width: 61.25em) {
  .m-post--extra:not(.m-post--video):hover > * {
    transform: translateY(-40px); }
  .m-post--extra:not(.m-post--video):hover .m-post__slide {
    transform: translateY(-150px); }
  .m-post--extra:not(.m-post--video):hover .m-post__slide-hidden {
    pointer-events: all; }
    .m-post--extra:not(.m-post--video):hover .m-post__slide-hidden > * {
      opacity: 1;
      transition-delay: 0.15s; } }

@media screen and (min-width: 61.25em) {
  .m-post--video .m-post__slide:hover > * {
    transform: translateY(-150px); }
  .m-post--video .m-post__slide:hover .m-post__slide-hidden {
    pointer-events: all; }
    .m-post--video .m-post__slide:hover .m-post__slide-hidden > * {
      opacity: 1; }
  .m-post--video .m-post__slide:hover + .a-video {
    transform: translateY(-40px); } }

@media screen and (min-width: 61.25em) {
  .m-post--video .m-post__slide-hidden {
    min-height: 240px; } }

@media screen and (min-width: 61.25em) {
  .m-post--feature .m-post__title {
    margin-top: 34px; } }

.body--highlight .m-post > a:hover, .body--highlight .m-post > a:active,
.s-module-bkgd--highlight .m-post > a:hover,
.s-module-bkgd--highlight .m-post > a:active {
  color: #191919; }

.body--highlight .m-post__title,
.body--highlight .m-post__title a,
.body--highlight .m-post__quote p,
.s-module-bkgd--highlight .m-post__title,
.s-module-bkgd--highlight .m-post__title a,
.s-module-bkgd--highlight .m-post__quote p {
  color: #191919; }

.body--highlight .m-post__slide,
.body--highlight .m-post__slide::after,
.body--highlight .m-post__slide-inner,
.body--highlight .m-post__slide-hidden,
.s-module-bkgd--highlight .m-post__slide,
.s-module-bkgd--highlight .m-post__slide::after,
.s-module-bkgd--highlight .m-post__slide-inner,
.s-module-bkgd--highlight .m-post__slide-hidden {
  background-color: #E42A1D; }

.body--highlight .m-post__cta,
.body--highlight .m-post__tag,
.s-module-bkgd--highlight .m-post__cta,
.s-module-bkgd--highlight .m-post__tag {
  color: #FFFFFF; }

.body--highlight .s-module-bkgd--dark .m-post__title,
.body--highlight .s-module-bkgd--dark .m-post__title a,
.body--highlight .s-module-bkgd--dark .m-post__quote p,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__title,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__title a,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__quote p {
  color: #FFFFFF; }

.body--highlight .s-module-bkgd--dark .m-post__cta:hover,
.body--highlight .s-module-bkgd--dark .m-post__link:hover,
.body--highlight .s-module-bkgd--dark .m-post__title a:hover,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__cta:hover,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__link:hover,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__title a:hover {
  color: #E42A1D; }

.body--highlight .s-module-bkgd--dark .m-post__image::after,
.s-module-bkgd--highlight .s-module-bkgd--dark .m-post__image::after {
  background-color: #E42A1D; }

.m-postInline {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px solid #D9D9D9; }
  .m-postInline:first-child {
    margin-top: 0; }
  @media screen and (max-width: 61.1875em) {
    .m-postInline:first-child {
      padding-top: 0;
      border-top: 0; } }
  @media screen and (min-width: 61.25em) {
    .m-postInline {
      margin-top: 35px;
      padding-top: 35px; } }

.m-postInline > a {
  display: block; }
  .m-postInline > a,
  .m-postInline > a .m-postInline__title {
    transition: color ease-in-out 180ms; }
  .m-postInline > a:hover,
  .m-postInline > a:hover .m-postInline__title, .m-postInline > a:active,
  .m-postInline > a:active .m-postInline__title {
    color: #E42A1D; }

.m-postInline__image {
  margin: 0; }

.m-postInline__image img {
  display: block;
  max-width: 100%; }

.m-postInline__meta {
  display: block;
  margin-top: 22px;
  color: #8c8c8c; }
  @media screen and (min-width: 61.25em) {
    .m-postInline__meta {
      margin-top: 0; } }

.m-postInline__title {
  margin-top: 11px;
  color: #191919; }
  .dark .m-postInline__title {
    color: #FFFFFF; }

.m-postInline__excerpt {
  margin-top: 4px; }

@media screen and (min-width: 61.25em) {
  .o-twoThirds .m-postInline__image {
    width: 30.4%; } }

@media screen and (min-width: 61.25em) {
  .o-twoThirds .m-postInline__main {
    width: 69.6%; } }

.o-twoThirds .m-postInline:last-child,
.o-listingFull .m-postInline:last-child {
  padding-bottom: 30px;
  border-bottom: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-twoThirds .m-postInline:last-child,
    .o-listingFull .m-postInline:last-child {
      padding-bottom: 35px; } }

@media screen and (max-width: 39.9375em) {
  .o-twoThirds .m-postInline:last-child {
    padding-bottom: 0;
    border-bottom: 0; } }

.m-pub > a {
  display: block;
  text-decoration: none; }
  .m-pub > a:hover .m-pub__title, .m-pub > a:active .m-pub__title {
    color: #E42A1D; }

.m-pub__image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px; }

.m-pub__image .a-img {
  display: inline-block;
  position: relative;
  max-height: 275px;
  margin: 0; }
  .m-pub__image .a-img::after {
    background: #E42A1D;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    transition: transform ease-in-out 360ms; }
  a:hover .m-pub__image .a-img:after {
    transform: translate3D(10px, 10px, 0); }
  a:hover .m-pub__image .a-img img {
    box-shadow: 0 0 0 0 transparent;
    transform: translate3D(-10px, -10px, 0); }

.m-pub__image img {
  display: block;
  position: relative;
  z-index: 2;
  max-width: 100%;
  margin: 0 auto;
  object-fit: contain;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
  transition: transform ease-in-out 360ms, box-shadow ease-in-out 360ms; }

.m-pub__title {
  margin: 22px 0 0;
  color: inherit; }

.m-pub__subtitle {
  margin: 0; }

.m-pub__meta {
  display: block;
  margin-top: 5px;
  color: #8c8c8c; }
  @media screen and (min-width: 30em) {
    .m-pub__meta {
      line-height: 20px; } }

.body--highlight .m-pub__title,
.s-module-bkgd--highlight .m-pub__title {
  color: #191919; }

.body--highlight .m-pub__meta,
.s-module-bkgd--highlight .m-pub__meta {
  color: rgba(255, 255, 255, 0.4); }

.body--highlight .m-pub__image .a-img::after,
.s-module-bkgd--highlight .m-pub__image .a-img::after {
  background-color: #191919; }

.body--highlight a:hover .m-pub__title, .body--highlight a:active .m-pub__title,
.s-module-bkgd--highlight a:hover .m-pub__title,
.s-module-bkgd--highlight a:active .m-pub__title {
  color: #191919; }

.m-sidebarModule {
  margin-bottom: 35px; }
  @media screen and (min-width: 61.25em) {
    .m-sidebarModule {
      margin-bottom: 50px; } }

.m-sidebarModule__content iframe {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important; }

@media screen and (min-width: 61.25em) {
  .m-sidebarModule__events {
    padding-bottom: 23px;
    border-bottom: 1px solid #D9D9D9; } }

.m-sidebarModule__events li {
  margin-top: 23px;
  padding-top: 18px;
  border-top: 1px solid #D9D9D9; }

.m-sidebarModule__events a {
  display: block;
  text-decoration: none; }
  .m-sidebarModule__events a:hover, .m-sidebarModule__events a:active {
    color: #E42A1D; }

.m-sidebarModule__events-meta {
  display: block;
  color: #8c8c8c; }

.m-sidebarModule__events-title {
  margin: 0; }

@media screen and (max-width: 61.1875em) {
  .m-sidebarNavigation + .m-sidebarModule {
    margin-top: 35px; } }

.m-sidebar-dropzone {
  margin-top: 40px; }
  @media screen and (min-width: 61.25em) {
    .m-sidebar-dropzone {
      margin-top: 0; } }

@media screen and (max-width: 39.9375em) {
  .m-sidebarNavigation {
    margin-left: -10px;
    margin-right: -10px; } }

.m-sidebarNavigation__itemsWrapper {
  margin-left: 0;
  margin-right: 0; }

@media screen and (max-width: 61.1875em) {
  .m-statsPanel {
    padding-bottom: 0; } }

@media screen and (max-width: 61.1875em) {
  .m-statsPanel .m-statsList {
    margin-bottom: 0; } }

.m-textBlock:last-child {
  padding-bottom: 0; }

@media screen and (min-width: 61.25em) {
  .m-textBlock h2 {
    margin-top: 45px; } }

.m-textBlock.o-primaryContent h5 + ul {
  margin-top: 0; }

.m-textBlock.o-primaryContent ul li {
  margin-top: 0; }

.m-textBlock.o-primaryContent .inline-cta {
  display: inline-block;
  margin-top: 0;
  color: #E42A1D; }
  .m-textBlock.o-primaryContent .inline-cta::before {
    top: 7px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='none' stroke='%23E82E21' stroke-width='56.8889' stroke-miterlimit='4' stroke-linecap='butt' stroke-linejoin='miter' d='M432.356 113.778l369.778 369.778-369.778 369.778'%3E%3C/path%3E%3C/svg%3E"); }

[data-ux-pagebuilder] > ul > li:first-child .m-textBlock.add-separator--top:first-child,
[data-ux-pagebuilder] > ul > li:first-child span + .m-textBlock.add-separator--top {
  padding-top: 0; }
  [data-ux-pagebuilder] > ul > li:first-child .m-textBlock.add-separator--top:first-child:before,
  [data-ux-pagebuilder] > ul > li:first-child span + .m-textBlock.add-separator--top:before {
    display: none; }

.m-workList__cta {
  margin-bottom: 0; }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__inner {
    position: relative; }
    .o-accordionHorizontal__inner::before, .o-accordionHorizontal__inner::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: 1px solid #D9D9D9;
      z-index: 5; }
    .o-accordionHorizontal__inner::before {
      left: 0; }
    .o-accordionHorizontal__inner::after {
      right: 0; } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__wrapper {
    max-width: 9999px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.o-accordionHorizontal__footer {
  margin-top: 20px; }
  @media screen and (min-width: 61.25em) {
    .o-accordionHorizontal__footer {
      margin-top: 23px; } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__items {
    position: relative;
    display: flex;
    flex-flow: row nowrap; } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__item {
    display: flex;
    flex-flow: row-reverse nowrap;
    flex-shrink: 0;
    width: 80px;
    border-left: 1px solid #D9D9D9;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 0);
    box-shadow: inset 10px 0 10px -10px transparent; }
    .o-accordionHorizontal__item:hover, .o-accordionHorizontal__item:active {
      width: 200px;
      background: #F1F2F2;
      box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, 0.3); }
    .o-accordionHorizontal__item:first-child {
      border-left: 0;
      box-shadow: none; }
      .o-accordionHorizontal__item:first-child:hover, .o-accordionHorizontal__item:first-child:active {
        box-shadow: none; }
    .o-accordionHorizontal__item:last-child {
      border-right: 1px solid #D9D9D9; } }

.o-accordionHorizontal__item-title {
  display: none; }
  @media screen and (min-width: 61.25em) {
    .o-accordionHorizontal__item-title {
      display: block;
      width: 80px;
      transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 0); } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__item-trigger {
    display: block;
    width: 605px;
    height: 605px;
    padding: 27px 45px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transform: translateX(80px) rotate(90deg);
    transform-origin: left top; } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal__item-content {
    pointer-events: none; } }

@media screen and (min-width: 61.25em) {
  .a-accordionHorizontal--active.o-accordionHorizontal__item {
    background: #F1F2F2;
    box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, 0.3); }
    .a-accordionHorizontal--active.o-accordionHorizontal__item:first-child {
      box-shadow: none; } }

@media screen and (min-width: 61.25em) {
  .a-accordionHorizontal--active .o-accordionHorizontal__item-title {
    opacity: 0; } }

@media screen and (min-width: 61.25em) {
  .a-accordionHorizontal--active .o-accordionHorizontal__item-content {
    pointer-events: all; } }

@media screen and (min-width: 61.25em) {
  .o-accordionHorizontal .a-accordionCard .a-accordionCard__body {
    background: transparent; }
  .o-accordionHorizontal .a-accordionCard:hover .a-accordionCard__body, .o-accordionHorizontal .a-accordionCard:active .a-accordionCard__body {
    background: transparent;
    color: #191919; }
    .o-accordionHorizontal .a-accordionCard:hover .a-accordionCard__body .a-accordionCard__cta, .o-accordionHorizontal .a-accordionCard:active .a-accordionCard__body .a-accordionCard__cta {
      color: #E42A1D;
      border-color: #E42A1D; }
      .o-accordionHorizontal .a-accordionCard:hover .a-accordionCard__body .a-accordionCard__cta:after, .o-accordionHorizontal .a-accordionCard:active .a-accordionCard__body .a-accordionCard__cta:after {
        background-color: #E42A1D; }
    .o-accordionHorizontal .a-accordionCard:hover .a-accordionCard__body .a-accordionCard__subtitle, .o-accordionHorizontal .a-accordionCard:active .a-accordionCard__body .a-accordionCard__subtitle {
      color: #8c8c8c; }
    .o-accordionHorizontal .a-accordionCard:hover .a-accordionCard__body .a-accordionCard__hover-on .a-accordionCard__subtitle, .o-accordionHorizontal .a-accordionCard:active .a-accordionCard__body .a-accordionCard__hover-on .a-accordionCard__subtitle {
      color: #191919; } }

.o-careerOutcomes {
  overflow: hidden; }

.o-careerOutcomes .m-moduleHeader {
  position: relative;
  z-index: 2; }

.o-careerOutcomes__items {
  margin-top: -5px; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__items {
      margin-top: -20px; } }

.o-careerOutcomes__item {
  text-align: center; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__item:nth-child(3n-1) {
      margin-top: 95px; } }

.o-careerOutcomes__item a {
  display: block;
  text-decoration: none; }

.o-careerOutcomes .flickity-viewport {
  overflow: visible; }

.o-careerOutcomes .carousel-cell {
  margin-left: -60px;
  margin-right: -60px;
  opacity: 0.2;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .o-careerOutcomes .carousel-cell.is-selected {
    opacity: 1;
    z-index: 5; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes .carousel-cell {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      opacity: 1; } }

.o-careerOutcomes__image {
  margin: 0;
  height: 200px; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__image {
      height: auto; } }

.o-careerOutcomes__image img {
  display: block;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  transform: scale(0.66);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .is-selected .o-careerOutcomes__image img {
    transform: scale(1); }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__image img {
      transform: scale(1); } }

.o-careerOutcomes__name,
.o-careerOutcomes__role,
.o-careerOutcomes__course {
  display: block;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .is-selected .o-careerOutcomes__name, .is-selected
  .o-careerOutcomes__role, .is-selected
  .o-careerOutcomes__course {
    opacity: 1; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__name,
    .o-careerOutcomes__role,
    .o-careerOutcomes__course {
      opacity: 1; } }

.o-careerOutcomes__name {
  margin-top: 25px; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__name {
      margin-top: 22px; } }

@media screen and (min-width: 61.25em) {
  .o-careerOutcomes__role {
    margin-top: 10px; } }

@media screen and (min-width: 61.25em) {
  .o-careerOutcomes__course {
    margin-top: 7px; } }

.o-careerOutcomes__content {
  position: relative;
  margin-top: 25px;
  padding-top: 30px; }
  @media screen and (min-width: 40em) {
    .o-careerOutcomes__content {
      margin-top: 49px;
      padding-top: 0; } }
  @media screen and (min-width: 72.5em) {
    .o-careerOutcomes__content {
      margin-top: 71px; } }
  .o-careerOutcomes__content::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    border-top: 1px solid rgba(20, 20, 20, 0.15); }
    @media screen and (min-width: 40em) {
      .o-careerOutcomes__content::before {
        display: none; } }

.o-careerOutcomes__content p:first-child {
  margin-top: 0; }

.o-careerOutcomes__cta {
  color: #191919; }
  @media screen and (min-width: 61.25em) {
    .o-careerOutcomes__cta {
      margin-top: 23px; } }

.o-fastFacts {
  margin-top: 30px;
  margin-bottom: 10px; }
  @media screen and (min-width: 61.25em) {
    .o-fastFacts {
      margin-top: 65px;
      margin-bottom: 43px; } }

@media screen and (min-width: 40em) {
  .o-fastFacts__items {
    display: flex;
    flex-flow: row nowrap; } }

.o-fastFacts__item {
  float: none !important;
  margin-top: 23px; }
  .o-fastFacts__item:first-child {
    margin-top: 0; }
  @media screen and (min-width: 40em) {
    .o-fastFacts__item {
      margin-top: 0; } }

.o-fastFacts__item-inner {
  padding-top: 20px;
  border-top: 1px solid #D9D9D9; }
  @media screen and (min-width: 40em) {
    .o-fastFacts__item-inner {
      padding-top: 0;
      border-top: 0; } }

.o-fastFacts__item:first-child .o-fastFacts__item-inner {
  padding-top: 0;
  border-top: 0; }

.o-fastFactsDetail {
  padding-top: 28px;
  padding-bottom: 35px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-fastFactsDetail {
      padding-bottom: 65px; } }

.o-fastFactsDetail__items,
.o-primaryContent ul .o-fastFactsDetail__items {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }
  @media screen and (min-width: 40em) {
    .o-fastFactsDetail__items,
    .o-primaryContent ul .o-fastFactsDetail__items {
      display: flex;
      flex-flow: row wrap; } }

.o-fastFactsDetail__item,
.o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item {
  float: none !important;
  margin-top: 14px; }
  .o-fastFactsDetail__item:first-child,
  .o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item:first-child {
    margin-top: 0; }
  .o-fastFactsDetail__item::before,
  .o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item::before {
    display: none; }
  @media screen and (min-width: 40em) {
    .o-fastFactsDetail__item,
    .o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item {
      width: 50%;
      margin-top: 19px; }
      .o-fastFactsDetail__item:nth-child(-n+2),
      .o-primaryContent ul .o-fastFactsDetail__items .o-fastFactsDetail__item:nth-child(-n+2) {
        margin-top: 0; } }

.o-fastFactsDetail__title,
.o-fastFactsDetail__content {
  display: block; }

.o-fastFactsDetail__title {
  color: #E42A1D; }

.o-fastFactsDetail__content {
  margin-top: 5px; }

.o-fastFactsDetail__footer {
  margin-top: 15px; }
  @media screen and (min-width: 61.25em) {
    .o-fastFactsDetail__footer {
      margin-top: 29px; } }

.o-fastFactsDetail__footer-link {
  border-bottom: 1px solid;
  text-decoration: none; }
  .o-fastFactsDetail__footer-link:hover, .o-fastFactsDetail__footer-link:active {
    color: #E42A1D; }

.o-fastFactsDetail + .add-separator--top::before {
  display: none; }

.o-primaryContent .m-heroHeading + .o-fastFactsDetail {
  margin-top: 43px; }
  @media screen and (min-width: 40em) {
    .o-primaryContent .m-heroHeading + .o-fastFactsDetail {
      margin-top: 66px; } }

@media screen and (min-width: 40em) {
  .o-featuredPosts__twoThirds {
    margin-top: 37px; } }

@media screen and (min-width: 61.25em) {
  .o-featuredPosts__twoThirds {
    margin-top: 42px; } }

@media screen and (min-width: 40em) {
  .o-featuredPosts__threeCol {
    margin-top: 37px; } }

@media screen and (min-width: 61.25em) {
  .o-featuredPosts__threeCol {
    margin-top: 103px; } }

@media screen and (max-width: 39.9375em) {
  .o-featuredPosts .m-post {
    margin-top: 45px; } }

.o-fiveCol {
  overflow: hidden; }

.o-fiveCol .flickity-viewport {
  overflow: visible; }

.o-fiveCol__footer {
  margin-top: 20px; }
  @media screen and (min-width: 61.25em) {
    .o-fiveCol__footer {
      margin-top: 23px; } }

.o-fiveCol__footer-cta {
  margin-bottom: 4px; }

.body--highlight .o-fiveCol__footer-cta,
.s-module-bkgd--highlight .o-fiveCol__footer-cta {
  color: #191919; }

.o-heroFlyout {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: -200px;
  padding-top: 46px; }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout {
      display: block;
      min-height: 2765px;
      padding-top: 0;
      margin-bottom: 0; } }

@media screen and (min-width: 61.25em) {
  .o-heroFlyout__wrapper {
    height: 2765px;
    padding-bottom: 300px; } }

.o-heroFlyout__title-wrap {
  position: relative;
  z-index: 5;
  margin-bottom: -8px; }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout__title-wrap {
      position: sticky;
      bottom: 44px;
      margin-bottom: 0; } }

.o-heroFlyout__title {
  float: none !important;
  margin: 0; }

.o-heroFlyout__title span {
  color: #E42A1D; }

.o-heroFlyout__inner {
  position: relative;
  height: 985px; }
  @media screen and (min-width: 40em) {
    .o-heroFlyout__inner {
      height: 1200px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout__inner {
      position: sticky;
      top: 95px;
      height: calc(100vh - 95px);
      margin-bottom: 500px; } }

.o-heroFlyout__images {
  height: 985px; }
  @media screen and (min-width: 40em) {
    .o-heroFlyout__images {
      height: 1200px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout__images {
      position: relative;
      height: 2265px; } }

.o-heroFlyout__images .a-img {
  position: absolute;
  margin: 0;
  float: none !important;
  opacity: 0; }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout__images .a-img {
      top: 25%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }
      .o-heroFlyout__images .a-img:nth-child(1) {
        top: 25%;
        left: 50%;
        z-index: 6;
        animation-delay: 1200ms; }
        .o-heroFlyout__images .a-img:nth-child(1) img {
          max-width: 440px; }
      .o-heroFlyout__images .a-img:nth-child(2) {
        z-index: 1;
        animation-delay: 200ms; }
        .o-heroFlyout__images .a-img:nth-child(2) img {
          max-width: 440px; }
      .o-heroFlyout__images .a-img:nth-child(3) {
        z-index: 4;
        animation-delay: 800ms; }
        .o-heroFlyout__images .a-img:nth-child(3) img {
          max-width: 365px; }
      .o-heroFlyout__images .a-img:nth-child(4) {
        z-index: 3;
        animation-delay: 600ms; }
        .o-heroFlyout__images .a-img:nth-child(4) img {
          max-width: 580px; }
      .o-heroFlyout__images .a-img:nth-child(5) {
        z-index: 5;
        animation-delay: 1000ms; }
        .o-heroFlyout__images .a-img:nth-child(5) img {
          max-width: 520px; }
      .o-heroFlyout__images .a-img:nth-child(6) {
        top: auto;
        z-index: 2;
        animation-delay: 400ms; }
        .o-heroFlyout__images .a-img:nth-child(6) img {
          max-width: 440px; } }
  @media screen and (max-width: 61.1875em) {
    .o-heroFlyout__images .a-img:nth-child(1) {
      top: 200px;
      left: 30px;
      z-index: 3; }
    .o-heroFlyout__images .a-img:nth-child(2) {
      top: 0;
      left: -30px;
      z-index: 2; }
    .o-heroFlyout__images .a-img:nth-child(3) {
      top: 80px;
      right: -30px;
      z-index: 1; }
    .o-heroFlyout__images .a-img:nth-child(4) {
      top: 406px;
      right: -30px;
      z-index: 4; }
    .o-heroFlyout__images .a-img:nth-child(5) {
      top: 475px;
      left: -30px;
      z-index: 5; }
    .o-heroFlyout__images .a-img:nth-child(6) {
      top: 605px;
      right: 30px;
      z-index: 6; } }
  @media screen and (max-width: 39.9375em) {
    .o-heroFlyout__images .a-img:nth-child(1) {
      left: 20px; }
    .o-heroFlyout__images .a-img:nth-child(2) {
      left: -20px; }
    .o-heroFlyout__images .a-img:nth-child(3) {
      right: -10px;
      padding-right: 0; }
    .o-heroFlyout__images .a-img:nth-child(4) {
      right: -10px;
      padding-right: 0; }
    .o-heroFlyout__images .a-img:nth-child(5) {
      left: -20px; }
    .o-heroFlyout__images .a-img:nth-child(6) {
      right: 20px; } }

.o-heroFlyout__images .a-img img {
  display: block;
  width: 100%;
  max-width: 100%; }

.o-heroFlyout--active .o-heroFlyout__images .a-img {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.1, 0.3, 0.25, 1); }
  @media screen and (min-width: 61.25em) {
    .o-heroFlyout--active .o-heroFlyout__images .a-img:nth-child(n+2) {
      left: auto; } }

.o-heroFullScreen {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -70px;
  padding-bottom: 25px; }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .o-heroFullScreen {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroFullScreen {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen {
      margin-top: -85px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen {
      margin-top: -100px;
      padding-bottom: 74px; } }

.o-heroFullScreen .icon--chevron-thin-down {
  display: none; }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen .icon--chevron-thin-down {
      display: block;
      position: fixed;
      bottom: 50px;
      right: 60px;
      z-index: 30;
      width: 30px;
      height: 30px;
      fill: #FFFFFF;
      cursor: pointer; } }

.o-heroFullScreen__main {
  position: relative; }

.o-heroFullScreen__main-image .a-img {
  position: relative;
  width: 100vw;
  min-height: 240px;
  margin: 0 -10px; }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen__main-image .a-img {
      margin: 0 -20px;
      height: 100vh;
      min-height: 800px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__main-image .a-img {
      position: relative;
      height: calc(100vh - 60px);
      margin: 0 -30px;
      overflow: hidden; } }
  @media screen and (min-width: 72.5em) {
    .o-heroFullScreen__main-image .a-img {
      margin: 0 -40px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroFullScreen__main-image .a-img {
      margin: 0 -60px; } }

.o-heroFullScreen__main-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 110px;
  margin-left: -10px;
  margin-right: -10px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
  pointer-events: none; }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen__main-image::before {
      height: 150px;
      margin-left: -20px;
      margin-right: -20px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__main-image::before {
      margin-left: -30px;
      margin-right: -30px; } }
  @media screen and (min-width: 72.5em) {
    .o-heroFullScreen__main-image::before {
      margin-left: -40px;
      margin-right: -40px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroFullScreen__main-image::before {
      margin-left: -60px;
      margin-right: -60px; } }

.o-heroFullScreen__main-image .a-img__image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.o-heroFullScreen__main-title {
  position: absolute;
  bottom: 13px; }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__main-title {
      position: sticky;
      bottom: 50px; } }

.o-heroFullScreen__main-title span {
  display: block;
  float: none !important; }

.o-heroFullScreen__title {
  position: absolute;
  bottom: 12px;
  z-index: 26;
  float: none !important;
  margin-top: 0;
  margin-bottom: 0;
  color: #FFFFFF; }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__title {
      position: sticky;
      bottom: 46px; } }

.o-heroFullScreen__text {
  position: relative;
  z-index: 27;
  padding-top: 32px;
  padding-bottom: 34px; }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__text {
      padding-top: 83px;
      padding-bottom: 107px; } }

@media screen and (min-width: 61.25em) {
  .o-heroFullScreen__text--last {
    padding-bottom: 69px; } }

.o-heroFullScreen__text p:first-child {
  margin-top: 0; }

.o-heroFullScreen__item {
  position: relative; }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__item {
      padding-bottom: 46px; } }

.o-heroFullScreen__item .a-video.is-active {
  position: relative;
  z-index: 25; }

.o-heroFullScreen__item .a-video__link,
.o-heroFullScreen__item .a-video__figure {
  float: none !important; }

.o-heroFullScreen__item .a-img,
.o-heroFullScreen__item .a-video,
.o-heroFullScreen__item .a-video__link {
  margin: 0; }

.o-heroFullScreen__item .a-img__image,
.o-heroFullScreen__item .a-video {
  display: block;
  width: calc(100% + 20px);
  margin-right: -20px; }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen__item .a-img__image,
    .o-heroFullScreen__item .a-video {
      width: calc(100% + 30px);
      margin-right: -30px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__item .a-img__image,
    .o-heroFullScreen__item .a-video {
      width: calc(100% + 45px);
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      margin-right: -45px; } }
  @media screen and (min-width: 72.5em) {
    .o-heroFullScreen__item .a-img__image,
    .o-heroFullScreen__item .a-video {
      width: calc(100% + 60px);
      margin-right: -60px; } }
  @media screen and (min-width: 90.625em) {
    .o-heroFullScreen__item .a-img__image,
    .o-heroFullScreen__item .a-video {
      width: calc(100% + 80px);
      min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
      margin-right: -80px; } }
  @media screen and (min-width: 108.75em) {
    .o-heroFullScreen__item .a-img__image,
    .o-heroFullScreen__item .a-video {
      min-height: auto; } }

.o-heroFullScreen__item .a-video {
  min-height: 0; }

.o-heroFullScreen__item .a-img__image {
  display: block;
  max-width: 100%; }

.o-heroFullScreen__item:nth-child(even) .o-heroFullScreen__title {
  left: 15px; }

@media screen and (min-width: 61.25em) {
  .o-heroFullScreen__item:nth-child(even) .o-heroFullScreen__title span {
    float: right !important;
    text-align: right; } }

.o-heroFullScreen__item:nth-child(odd) .o-heroFullScreen__title span {
  left: 0; }

.o-heroFullScreen__item:nth-child(odd) .a-img,
.o-heroFullScreen__item:nth-child(odd) .a-video {
  left: 0; }

.o-heroFullScreen__item:nth-child(odd) .a-img__image,
.o-heroFullScreen__item:nth-child(odd) .a-video {
  margin-right: 0;
  margin-left: -20px; }
  @media screen and (min-width: 40em) {
    .o-heroFullScreen__item:nth-child(odd) .a-img__image,
    .o-heroFullScreen__item:nth-child(odd) .a-video {
      margin-left: -40px; } }
  @media screen and (min-width: 61.25em) {
    .o-heroFullScreen__item:nth-child(odd) .a-img__image,
    .o-heroFullScreen__item:nth-child(odd) .a-video {
      margin-left: -60px; } }

.o-heroFullScreen__item:nth-child(odd) .a-video__figure {
  padding-left: 0; }

.o-heroFullScreen__item:nth-child(even) .a-video__figure {
  padding-right: 0; }

.dark .o-heroFullScreen,
.dark .o-heroFullScreen__item,
.dark .o-heroFullScreen__text {
  background-color: #191919;
  color: #FFFFFF; }

.o-heroFullScreen,
.o-heroFullScreen__item,
.o-heroFullScreen__text {
  background-color: #FFFFFF;
  color: #191919;
  transition: background-color 0.2s cubic-bezier(0.5, 0, 0.5, 0), color 0.2s cubic-bezier(0.5, 0, 0.5, 0); }

.o-heroGallery {
  margin-top: 19px; }
  @media screen and (min-width: 40em) {
    .o-heroGallery {
      margin-top: 39px;
      margin-bottom: 60px; } }

.o-heroGallery__header {
  position: sticky;
  top: 107px;
  z-index: 5; }
  @media screen and (min-width: 61.25em) {
    .o-heroGallery__header {
      top: 140px; } }

.o-heroGallery__title {
  font-family: "neue-display-random";
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga' 1;
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0; }
  @media screen and (min-width: 30em) {
    .o-heroGallery__title {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -0.01rem; } }
  @media screen and (min-width: 40em) {
    .o-heroGallery__title {
      font-size: 50px;
      line-height: 50px; } }

.o-heroGallery__title span {
  color: #E42A1D; }

.o-heroGallery__images {
  margin-top: 21px; }
  @media screen and (min-width: 61.25em) {
    .o-heroGallery__images {
      margin-top: 50px; } }

.o-heroGallery__image {
  position: relative; }
  .o-heroGallery__image:nth-child(1) {
    z-index: 1; }
  .o-heroGallery__image:nth-child(2) {
    z-index: 2;
    margin-top: -3%; }
    @media screen and (min-width: 61.25em) {
      .o-heroGallery__image:nth-child(2) {
        margin-top: -13%; } }
  .o-heroGallery__image:nth-child(3) {
    z-index: 3;
    margin-top: -3%; }
    @media screen and (min-width: 61.25em) {
      .o-heroGallery__image:nth-child(3) {
        margin-top: -16%; } }

.o-heroGallery__image .columns {
  float: none; }

.o-heroGallery__images .a-img {
  margin: 0; }

.o-heroGallery__images .a-img img {
  display: block;
  max-width: 100%; }

.o-heroGallery__image--right .a-img {
  display: block;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px); }
  @media screen and (min-width: 30em) {
    .o-heroGallery__image--right .a-img {
      margin: 0 0 0 -30px;
      width: calc(100% + 30px); } }
  @media screen and (min-width: 61.25em) {
    .o-heroGallery__image--right .a-img {
      margin: 0;
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      width: calc(100% + 45px); } }
  @media screen and (min-width: 72.5em) {
    .o-heroGallery__image--right .a-img {
      width: calc(100% + 60px); } }
  @media screen and (min-width: 90.625em) {
    .o-heroGallery__image--right .a-img {
      min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
      width: 100%; } }
  @media screen and (min-width: 108.75em) {
    .o-heroGallery__image--right .a-img {
      min-height: auto; } }

@media screen and (min-width: 40em) {
  .o-heroSidebySide {
    margin-bottom: 50px; } }

@media screen and (min-width: 61.25em) {
  .o-heroSidebySide {
    margin-bottom: 75px; } }

.o-heroSidebySide__inner {
  display: flex;
  flex-flow: column-reverse nowrap; }
  @media screen and (min-width: 61.25em) {
    .o-heroSidebySide__inner {
      flex-flow: row nowrap;
      align-items: center; } }

.o-heroSidebySide__main {
  position: relative;
  z-index: 5; }

@media screen and (min-width: 61.25em) {
  .o-heroSidebySide__title {
    width: calc(100% + 80px); } }

.o-heroSidebySide__title strong {
  color: #E42A1D; }

.o-heroSidebySide__media .a-img,
.o-heroSidebySide__media .a-video__link {
  margin: 0; }

.o-heroSidebySide__media img {
  display: block;
  max-width: 100%; }

.o-heroSidebySide__media--right .a-img,
.o-heroSidebySide__media--right .a-video {
  display: block;
  margin: 0 0 22px -20px;
  width: calc(100% + 40px); }
  @media screen and (min-width: 30em) {
    .o-heroSidebySide__media--right .a-img,
    .o-heroSidebySide__media--right .a-video {
      margin: 0 0 22px -30px;
      width: calc(100% + 30px); } }
  @media screen and (min-width: 61.25em) {
    .o-heroSidebySide__media--right .a-img,
    .o-heroSidebySide__media--right .a-video {
      margin: 0;
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      width: calc(100% + 45px); } }
  @media screen and (min-width: 72.5em) {
    .o-heroSidebySide__media--right .a-img,
    .o-heroSidebySide__media--right .a-video {
      width: calc(100% + 60px); } }
  @media screen and (min-width: 90.625em) {
    .o-heroSidebySide__media--right .a-img,
    .o-heroSidebySide__media--right .a-video {
      min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
      width: 100%; } }
  @media screen and (min-width: 108.75em) {
    .o-heroSidebySide__media--right .a-img,
    .o-heroSidebySide__media--right .a-video {
      min-height: auto; } }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst {
    display: flex;
    flex-flow: column-reverse; } }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst .o-landing__copy {
    background-color: #FFFFFF; }
    .dark .o-heroTitleFirst .o-landing__copy {
      background-color: #191919; }
    .body--highlight .o-heroTitleFirst .o-landing__copy {
      background-color: #E42A1D; } }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst .o-landing__title {
    margin-top: 0;
    margin-bottom: 0; } }

@media screen and (max-width: 39.9375em) {
  .o-heroTitleFirst .o-landing__title-wrapper {
    padding-bottom: 0; } }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst .o-landing__title-wrapper {
    margin-top: -35px; } }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst .o-landing__copy:first-child + .o-landing__media {
    margin-bottom: 0; } }

.o-heroTitleFirst .o-landing__media img {
  display: block;
  max-width: 100%;
  margin: 0; }

@media screen and (max-width: 61.1875em) {
  .o-heroTitleFirst .o-landing__mediaImageRight {
    margin-bottom: 0; } }

.o-multimediaGallery {
  overflow: hidden; }
  @media screen and (max-width: 39.9375em) {
    .o-multimediaGallery {
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px; } }

@media screen and (min-width: 61.25em) {
  .o-multimediaGallery .m-moduleHeader__intro {
    margin-bottom: 0; } }

.o-multimediaGallery .flickity-viewport {
  overflow: visible; }

.o-multimediaGallery__items {
  position: relative;
  z-index: 1; }

.o-multimediaGallery__item {
  width: calc(100% - 20px); }
  @media screen and (min-width: 61.25em) {
    .o-multimediaGallery__item {
      position: relative;
      z-index: 2;
      float: none !important;
      width: auto;
      margin-bottom: 40px;
      pointer-events: none; }
      .o-multimediaGallery__item > * {
        pointer-events: all; }
      .o-multimediaGallery__item .columns {
        float: none !important; }
      .o-multimediaGallery__item:nth-child(1) .columns {
        margin-top: -40px; } }

.o-multimediaGallery__footer {
  position: relative;
  z-index: 2;
  margin-top: 41px; }
  @media screen and (min-width: 61.25em) {
    .o-multimediaGallery__footer {
      margin-top: 38px; } }

.o-multimediaGallery__items .m-post {
  width: 100%;
  height: auto; }

.o-nav__college-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.m-moduleHeader + .o-listingFull__inner {
  margin-top: 23px; }
  @media screen and (min-width: 61.25em) {
    .m-moduleHeader + .o-listingFull__inner {
      margin-top: 63px; } }

.o-listingFull__inner {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.5, 0, 0.5, 0); }

.o-listingFull__footer {
  margin-top: 8px; }
  @media screen and (min-width: 61.25em) {
    .o-listingFull__footer {
      margin-top: 18px; } }

.o-listingFull__footer .a-btn {
  margin-top: 42px; }
  @media screen and (min-width: 61.25em) {
    .o-listingFull__footer .a-btn {
      margin-top: 57px; } }

@media screen and (min-width: 61.25em) {
  .o-primaryContent .a-video__link {
    margin-top: 60px; } }

.m-courses ul li ul li:before,
.m-composedList__description ul li ul li:before,
.m-featurePanel .m-landingPanel__content ul li ul li:before,
.m-heroHeading ul li ul li:before,
.m-textBlock.o-primaryContent ul li ul li:before,
.m-courses ol li ul li:before,
.m-composedList__description ol li ul li:before,
.m-featurePanel .m-landingPanel__content ol li ul li:before,
.m-heroHeading ol li ul li:before,
.m-textBlock.o-primaryContent ol li ul li:before {
  counter-increment: none; }

.o-quoteCarousel .m-moduleHeader {
  margin-bottom: 34px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel .m-moduleHeader {
      margin-bottom: 75px; } }

.o-quoteCarousel a {
  text-decoration: none; }

.o-quoteCarousel__cta {
  margin-top: 11px;
  color: inherit; }

.o-quoteCarousel__main {
  margin-top: 11px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel__main {
      margin-top: 40px; } }

.o-quoteCarousel__items {
  position: relative; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel__items {
      margin-top: 30px; } }

.o-quoteCarousel__main + .o-quoteCarousel__items {
  margin-top: 18px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel__main + .o-quoteCarousel__items {
      margin-top: 40px; } }

.o-quoteCarousel__item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding-bottom: 1px; }

.o-quoteCarousel blockquote {
  margin: 0;
  padding: 0;
  border: 0; }

.o-quoteCarousel blockquote p:first-child::before {
  content: '“'; }

.o-quoteCarousel blockquote p:last-of-type::after {
  content: '”'; }

.o-quoteCarousel cite {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 25px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel cite {
      margin-top: 28px; } }

.o-quoteCarousel cite .a-img {
  width: 35px;
  flex-shrink: 0;
  margin: 0;
  margin-right: 15px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel cite .a-img {
      width: 55px;
      margin-right: 20px; } }

.o-quoteCarousel cite img {
  max-width: 100%;
  border-radius: 50%; }

.o-quoteCarousel cite .a-img + span {
  margin-top: -5px; }

.o-quoteCarousel__nav {
  margin-top: 30px;
  text-align: right; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel__nav {
      margin-top: 0; } }

.o-quoteCarousel__nav-item {
  margin: 0 0 20px;
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none; }
  @media screen and (min-width: 61.25em) {
    .o-quoteCarousel__nav-item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }

@media screen and (min-width: 61.25em) {
  .o-quoteCarousel__nav--prev {
    left: 6.66667%;
    margin-left: 15px; } }

@media screen and (min-width: 72.5em) {
  .o-quoteCarousel__nav--prev {
    margin-left: 20px; } }

@media screen and (min-width: 61.25em) {
  .o-quoteCarousel__nav--next {
    right: 6.66667%;
    margin-right: 15px; } }

@media screen and (min-width: 72.5em) {
  .o-quoteCarousel__nav--next {
    margin-right: 20px; } }

body {
  overflow: visible; }

.o-quoteMedia {
  position: relative; }
  @media screen and (min-width: 61.25em) {
    .o-quoteMedia {
      height: 200vh; } }

@media screen and (min-width: 61.25em) {
  .o-quoteMedia__inner {
    position: sticky;
    top: 170px;
    left: 0; } }

@media screen and (min-width: 61.25em) {
  .o-quoteMedia__main {
    position: absolute;
    top: 75px;
    z-index: 2;
    transition: transform 0.1s linear; } }

.o-quoteMedia__main blockquote {
  margin: 0;
  padding: 0;
  border: 0; }

.o-quoteMedia__main blockquote cite {
  display: block;
  margin-top: 25px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteMedia__main blockquote cite {
      width: 30%;
      margin-top: 28px; } }
  .o-quoteMedia__main blockquote cite::before {
    content: '\2014';
    margin-right: 5px; }

.o-quoteMedia__main blockquote p:first-child::before {
  content: '“'; }

.o-quoteMedia__main blockquote p:last-of-type::after {
  content: '”'; }

.o-quoteMedia__media {
  margin-top: 25px; }
  @media screen and (min-width: 61.25em) {
    .o-quoteMedia__media {
      margin-top: 80px;
      opacity: 0.25; } }

.o-quoteMedia__media .a-img {
  margin: 0; }

.o-quoteMedia__media .a-img__image {
  display: block;
  max-width: 100%; }

.o-quotePortrait {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px; }
  @media screen and (min-width: 40em) {
    .o-quotePortrait {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .o-quotePortrait {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .o-quotePortrait {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .o-quotePortrait {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }

@media screen and (min-width: 40em) {
  .o-quotePortrait__inner {
    display: flex;
    flex-flow: row nowrap; } }

.o-quotePortrait__images {
  float: right;
  margin-left: 30px; }
  @media screen and (max-width: 39.9375em) {
    .o-quotePortrait__images {
      padding-left: 0;
      width: 130px; } }
  @media screen and (min-width: 40em) {
    .o-quotePortrait__images {
      float: none;
      margin-left: 0; } }

@media screen and (min-width: 40em) {
  .o-quotePortrait__images-inner {
    display: inline-block;
    position: relative;
    padding-bottom: 30px; } }

@media screen and (min-width: 61.25em) {
  .o-quotePortrait__images-inner {
    padding-bottom: 40px; } }

@media screen and (min-width: 40em) {
  .o-quotePortrait__image {
    position: relative; }
    .o-quotePortrait__image::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background: #191919; } }

.o-quotePortrait__image,
.o-quotePortrait__image img {
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

@media screen and (min-width: 40em) {
  .o-quotePortrait__image:first-child {
    z-index: 2; } }

.o-quotePortrait__image:last-child:not(:first-child) {
  display: none; }
  @media screen and (min-width: 40em) {
    .o-quotePortrait__image:last-child:not(:first-child) {
      display: block;
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 30px; }
      .o-quotePortrait__image:last-child:not(:first-child) img {
        opacity: 0.2; } }
  @media screen and (min-width: 61.25em) {
    .o-quotePortrait__image:last-child:not(:first-child) {
      top: 40px;
      left: 40px; } }

.o-quotePortrait__image img {
  display: block;
  position: relative;
  z-index: 2;
  max-width: 100%; }

@media screen and (min-width: 40em) {
  .o-quotePortrait--hover img {
    opacity: 0.2; } }

@media screen and (min-width: 40em) {
  .o-quotePortrait__image:hover, .o-quotePortrait__image:active {
    z-index: 5; }
    .o-quotePortrait__image:hover img, .o-quotePortrait__image:active img {
      opacity: 1; } }

.o-quotePortrait__main {
  position: relative;
  float: none !important; }
  @media screen and (min-width: 40em) {
    .o-quotePortrait__main {
      padding-bottom: 48px; } }

.o-quotePortrait__main blockquote {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0; }

.o-quotePortrait__main blockquote p:first-child::before {
  content: '“'; }

.o-quotePortrait__main blockquote p:last-of-type::after {
  content: '”'; }

.o-quotePortrait__main blockquote cite {
  display: block;
  margin-top: 14px; }
  @media screen and (min-width: 40em) {
    .o-quotePortrait__main blockquote cite {
      margin-top: 25px; } }

.o-quotePortrait__cta {
  margin-top: 23px; }
  @media screen and (min-width: 40em) {
    .o-quotePortrait__cta {
      position: absolute;
      bottom: 3px; } }

.o-sectionInfo .m-moduleHeader {
  margin-bottom: 25px; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo .m-moduleHeader {
      margin-bottom: 60px; } }

.o-sectionInfo .m-moduleHeader__intro {
  display: none; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo .m-moduleHeader__intro {
      display: block;
      margin-bottom: 0; } }

.o-sectionInfo__header {
  position: relative;
  z-index: 2;
  margin: -60px 0 0 -20px;
  padding: 25px 20px 15px;
  background: #FFFFFF; }
  @media screen and (min-width: 30em) {
    .o-sectionInfo__header {
      padding: 33px 20px 44px; } }
  @media screen and (min-width: 40em) {
    .o-sectionInfo__header {
      margin: -60px 0 0 -30px;
      padding: 27px 30px 48px; } }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__header {
      margin: 0;
      padding: 0;
      background: none; } }

.o-sectionInfo__title {
  position: relative;
  margin-bottom: -10px;
  color: #E42A1D;
  opacity: 0; }
  @media screen and (min-width: 40em) {
    .o-sectionInfo__title {
      margin-bottom: -15px; } }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__title {
      margin-bottom: -22px; } }
  .wf-neuedisplayrandom-n7-active .o-sectionInfo__title {
    opacity: 1; }

.o-sectionInfo__mediaImage {
  display: block;
  margin: 0 0 22px -20px;
  width: calc(100% + 40px); }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__mediaImage {
      margin: 0 0 0 -60px;
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      width: calc(100% + 60px); } }
  @media screen and (min-width: 80.0625em) {
    .o-sectionInfo__mediaImage {
      margin-left: calc((20px + ((100vw - 1200px) / 2)) * -1);
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      width: calc(100% + 20px + ((100vw - 1200px) / 2)); } }
  @media screen and (min-width: 90.625em) {
    .o-sectionInfo__mediaImage {
      margin-left: 0;
      min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
      width: 100%; } }
  @media screen and (min-width: 108.75em) {
    .o-sectionInfo__mediaImage {
      height: auto;
      min-height: auto; } }

.o-sectionInfo__mediaImageRight {
  display: block;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px); }
  @media screen and (min-width: 30em) {
    .o-sectionInfo__mediaImageRight {
      margin: 0 0 0 -30px;
      width: calc(100% + 30px); } }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__mediaImageRight {
      margin: 0;
      min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
      width: calc(100% + 45px); } }
  @media screen and (min-width: 72.5em) {
    .o-sectionInfo__mediaImageRight {
      width: calc(100% + 60px); } }
  @media screen and (min-width: 90.625em) {
    .o-sectionInfo__mediaImageRight {
      min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
      width: 100%; } }
  @media screen and (min-width: 108.75em) {
    .o-sectionInfo__mediaImageRight {
      min-height: auto; } }

.o-sectionInfo__mediaImageRight--absolute {
  display: none;
  opacity: 0; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__mediaImageRight--absolute {
      display: block;
      transition: opacity ease-in-out 180ms;
      width: calc(100% + 15px);
      position: absolute;
      top: 0; } }
  @media screen and (min-width: 72.5em) {
    .o-sectionInfo__mediaImageRight--absolute {
      width: calc(100% + 20px); } }
  @media screen and (min-width: 80.0625em) {
    .o-sectionInfo__mediaImageRight--absolute {
      width: calc(100% - 20px + ((100vw - 1200px) / 2)); } }
  @media screen and (min-width: 90.625em) {
    .o-sectionInfo__mediaImageRight--absolute {
      width: calc(100% - 40px); } }
  .o-sectionInfo__mediaImageRight--absolute.is-active {
    opacity: 1; }

.o-sectionInfo__cta {
  float: left;
  margin: 38px 0 46px; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__cta {
      float: none;
      margin: 19px 0; } }

.o-sectionInfo__content {
  margin-top: 5px; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo__content {
      margin-top: 0; } }

.o-sectionInfo .m-collegeList {
  margin: 45px 0 0; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo .m-collegeList {
      height: 100%;
      margin-top: 36px; } }

.o-sectionInfo .m-collegeList:first-child {
  margin-top: 38px; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo .m-collegeList:first-child {
      margin-top: 102px; } }

.o-sectionInfo .m-collegeList__items {
  height: 100%;
  padding-bottom: 13px;
  border-bottom: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo .m-collegeList__items {
      padding-bottom: 0;
      border-bottom: 0; } }

.o-sectionInfo .a-video__link {
  margin: 0; }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .m-moduleHeader {
    margin-bottom: 150px; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .o-sectionInfo__inner {
    display: flex;
    flex-flow: row nowrap; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .o-sectionInfo__main,
  .o-sectionInfo--alt .o-sectionInfo__aside {
    float: none !important; } }

.o-sectionInfo--alt .o-sectionInfo__main {
  margin-top: -7px; }
  @media screen and (min-width: 61.25em) {
    .o-sectionInfo--alt .o-sectionInfo__main {
      margin-top: 0;
      padding-bottom: 24px; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .o-sectionInfo__aside {
    margin-top: 30px; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .o-sectionInfo__sticky {
    position: sticky;
    top: 95px;
    margin-top: -80px; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .o-sectionInfo__content {
    display: none; } }

.o-sectionInfo--alt .m-collegeList:first-child {
  margin-top: 0; }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .is-active .m-collegeList__content-wrapper {
    bottom: -55px;
    overflow: visible; } }

@media screen and (min-width: 61.25em) {
  .o-sectionInfo--alt .is-active .m-collegeList__content {
    position: sticky;
    top: 100px;
    padding-bottom: 0; } }

.o-sectionInfo--alt .m-collegeList__content p:first-child {
  margin-top: 0; }

.o-shuffler .m-moduleHeader__intro {
  margin-bottom: 20px; }

.o-shuffler__items .carousel {
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden; }
  @media screen and (min-width: 40em) {
    .o-shuffler__items .carousel {
      margin-left: 0;
      margin-right: 0; } }

.o-shuffler .flickity-viewport {
  overflow: visible; }
  @media screen and (min-width: 40em) {
    .o-shuffler .flickity-viewport {
      overflow: hidden; } }

.o-shuffler .carousel-cell {
  width: 100%;
  margin: 0 -30px;
  padding: 0 40px; }
  @media screen and (min-width: 40em) {
    .o-shuffler .carousel-cell {
      width: 100%;
      margin: 0 10px;
      padding: 0; } }

.o-shuffler .carousel-cell img {
  width: 100%; }

.o-shuffler__carousel-image {
  margin: 0;
  opacity: 0.45;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .is-selected .o-shuffler__carousel-image {
    opacity: 1; }
  @media screen and (min-width: 40em) {
    .o-shuffler__carousel-image {
      display: none; } }

.o-shuffler__group {
  display: none; }
  .o-shuffler__group.o-shuffler__group--active {
    display: block; }

.o-shuffler__images {
  display: none; }
  @media screen and (min-width: 40em) {
    .o-shuffler__images {
      display: block; } }

.o-shuffler__images-inner {
  position: relative;
  height: 575px; }

.o-shuffler__image {
  position: absolute;
  margin: 0;
  background-color: #000000; }
  .o-shuffler__image:nth-child(1) {
    bottom: 14.78261%;
    left: 47.02381%;
    z-index: 6; }
  .o-shuffler__image:nth-child(2) {
    top: 19.13043%;
    left: 33.33333%;
    z-index: 5; }
  .o-shuffler__image:nth-child(3) {
    bottom: 2.6087%;
    left: 0;
    z-index: 4; }
  .o-shuffler__image:nth-child(4) {
    bottom: 0;
    right: 0;
    z-index: 3; }
  .o-shuffler__image:nth-child(5) {
    top: 5.21739%;
    left: 9.40476%;
    z-index: 2; }
  .o-shuffler__image:nth-child(6) {
    top: 0;
    left: 55.95238%;
    z-index: 1; }
  .o-shuffler__image:hover {
    z-index: 11; }
  .o-shuffler__image img {
    display: block;
    opacity: 0.35;
    cursor: pointer;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .o-shuffler__image:hover img {
    opacity: 0.75; }
  .o-shuffler__image.o-shuffler__image--active {
    z-index: 10; }
  .o-shuffler__image.o-shuffler__image--active img {
    opacity: 1; }

.o-shuffler__data {
  margin: 19px 0 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .is-selected .o-shuffler__data {
    opacity: 1; }
  @media print, screen and (min-width: 40em) {
    .o-shuffler__data {
      margin: 30px 0 0;
      opacity: 1; } }

.o-shuffler__data dt {
  margin-top: 22px;
  color: #E42A1D; }
  @media screen and (min-width: 40em) {
    .o-shuffler__data dt {
      margin-top: 31px; } }
  .o-shuffler__data dt:first-child {
    margin-top: 0; }

.o-shuffler__data dd {
  margin-top: 13px; }
  @media screen and (min-width: 40em) {
    .o-shuffler__data dd {
      margin-top: 9px; } }

.o-shuffler__data .o-shuffler__data--large {
  margin-top: 11px; }
  @media screen and (min-width: 40em) {
    .o-shuffler__data .o-shuffler__data--large {
      margin-top: 6px; } }

.o-shuffler__data .o-shuffler__data--large + dt {
  margin-top: 19px; }
  @media screen and (min-width: 40em) {
    .o-shuffler__data .o-shuffler__data--large + dt {
      margin-top: 29px; } }

.o-shuffler__footer {
  display: none; }
  @media screen and (min-width: 40em) {
    .o-shuffler__footer {
      display: block;
      margin-top: 30px; } }

.o-shuffler__nav {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none; }

.o-shuffler__nav .icon {
  vertical-align: middle; }

.o-shuffler__nav span {
  margin-left: 5px; }

.o-shuffler__nav--next {
  margin-left: 5px; }

.o-shuffler__nav--shuffle {
  margin-left: 40px; }

.m-featurePanel + .o-shuffler {
  margin-top: 5px; }
  @media screen and (min-width: 40em) {
    .m-featurePanel + .o-shuffler {
      margin-top: 65px; } }
  @media screen and (min-width: 61.25em) {
    .m-featurePanel + .o-shuffler {
      margin-top: 35px; } }

@media screen and (min-width: 61.25em) {
  .o-sidebar > :first-child,
  .o-sidebar--top-mobile > :first-child {
    margin-top: 60px; } }

.o-socialFeed .m-moduleHeader {
  margin-bottom: 16px; }
  @media screen and (min-width: 61.25em) {
    .o-socialFeed .m-moduleHeader {
      margin-bottom: 45px; } }

.o-socialFeed + .m-cta {
  margin-top: 0; }

.pageClass--lang .o-socialFeed__title {
  color: #E42A1D; }

.o-stats {
  position: relative;
  margin-top: 45px;
  border-bottom: 1px solid #D9D9D9; }
  .o-stats::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    border-top: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-stats {
      border-bottom: 0; }
      .o-stats::before {
        display: none; } }

.o-stats .m-statsList {
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0; }

@media screen and (min-width: 61.25em) {
  .m-statsList__oversized {
    font-size: 6.9vw;
    line-height: 1.2; } }

@media screen and (min-width: 90.625em) {
  .m-statsList__oversized {
    font-size: 110px;
    line-height: 120px; } }

.o-studyAreas__inner {
  position: relative; }

.o-studyAreas__footer {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  position: sticky;
  bottom: 0;
  z-index: 5;
  margin-top: -1px;
  background-color: #F5F5F5; }
  @media screen and (min-width: 40em) {
    .o-studyAreas__footer {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .o-studyAreas__footer {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .o-studyAreas__footer {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .o-studyAreas__footer {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }

.o-studyAreas__footer a {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  padding-top: 21px;
  padding-bottom: 24px;
  background-color: #F5F5F5;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  @media screen and (min-width: 40em) {
    .o-studyAreas__footer a {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .o-studyAreas__footer a {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .o-studyAreas__footer a {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .o-studyAreas__footer a {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }
  .o-studyAreas__footer a:hover, .o-studyAreas__footer a:active {
    background-color: #E42A1D;
    color: #FFFFFF; }
  @media screen and (min-width: 72.5em) {
    .o-studyAreas__footer a {
      margin-left: -9999px;
      margin-right: -9999px;
      padding-left: 9999px;
      padding-right: 9999px; } }

.o-studyAreas .m-moduleHeader__intro {
  margin-bottom: 18px; }

.o-studyAreas .m-contentList {
  margin-top: 0;
  padding-top: 0; }
  .o-studyAreas .m-contentList .ais-infinite-hits {
    margin-top: 0; }
  .o-studyAreas .m-contentList .m-contentFilters + .o-studyAreas__items .ais-infinite-hits {
    margin-top: 11px; }

.o-studyAreas:not(.o-studyAreas--alt) .m-contentList .ais-infinite-hits--item {
  margin-bottom: 0; }

@media screen and (min-width: 61.25em) {
  .o-studyAreas__more-header {
    padding-top: 20px; } }

.o-studyAreas__more-title {
  margin: 0; }

.o-studyAreas__more-main {
  padding-bottom: 15px; }
  @media screen and (min-width: 61.25em) {
    .o-studyAreas__more-main {
      padding-top: 35px;
      padding-bottom: 75px; } }

.o-studyAreas__more p:first-child {
  margin-top: 0; }

.o-studyAreas__more-cta {
  margin-top: 20px; }

.o-studyAreas--alt .o-studyAreas__footer {
  margin-top: 35px; }
  @media screen and (min-width: 61.25em) {
    .o-studyAreas--alt .o-studyAreas__footer {
      margin-top: 100px; } }

@media screen and (min-width: 72.5em) {
  .o-studyAreas--alt .m-contentList--grid-view .a-program.is-active::before, .o-studyAreas--alt .m-contentList--grid-view .a-program.is-active::after {
    bottom: -115px; } }

.o-textLongForm__content {
  margin-top: 33px;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0, 0, 0.2, 1); }
  @media screen and (min-width: 61.25em) {
    .o-textLongForm__content {
      margin-top: 64px; } }

.o-textLongForm__content .a-img {
  margin: 0; }

.o-textLongForm__content img {
  display: block;
  max-width: 100%; }

.o-textLongForm__content .a-img + p {
  margin-top: 32px; }
  @media screen and (min-width: 61.25em) {
    .o-textLongForm__content .a-img + p {
      margin-top: 66px; } }

.o-textLongForm__trigger {
  display: inline-block;
  margin-top: 24px;
  color: #E42A1D;
  text-decoration: none; }

.o-textLongForm__trigger svg {
  width: 11px;
  height: 7px;
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 0); }
  .o-textLongForm--open .o-textLongForm__trigger svg {
    transform: rotate(180deg); }

.o-three-col {
  overflow: hidden; }

@media screen and (max-width: 39.9375em) {
  .o-three-col .m-moduleHeader__intro {
    margin-bottom: 35px; } }

@media screen and (min-width: 61.25em) {
  .o-three-col__items {
    display: flex;
    flex-flow: row wrap; }
    .o-three-col__items::before, .o-three-col__items::after {
      display: none; } }

.o-three-col__item {
  float: none !important;
  margin-top: 40px; }
  .o-three-col__item:first-child {
    margin-top: 0; }
  .o-three-col__item:last-child .a-accordionCard {
    border-bottom: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-three-col__item {
      border-left: 1px solid #D9D9D9; }
      .o-three-col__item:nth-child(3n+1) {
        border-left: 0; }
      .o-three-col__item:nth-child(-n+3) {
        margin-top: 0; }
      .o-three-col__item:last-child .a-accordionCard {
        border-bottom: 0; }
      .flickity-viewport .o-three-col__item {
        margin-top: 0; } }

.o-three-col__item > .m-post {
  padding-top: 30px;
  border-top: 1px solid #D9D9D9; }
  @media screen and (min-width: 61.25em) {
    .o-three-col__item > .m-post {
      padding-top: 0;
      border-top: 0; } }

.o-three-col__items:first-child .o-three-col__item:first-child > .m-post {
  padding-top: 0;
  border-top: 0; }

.o-three-col__footer {
  margin-top: 20px; }
  @media screen and (min-width: 61.25em) {
    .o-three-col__footer {
      margin-top: 23px; } }

.o-three-col__footer-cta {
  margin-bottom: 4px; }

.o-three-col__nav {
  display: none;
  text-align: right; }
  @media screen and (min-width: 61.25em) {
    .o-three-col__nav.show {
      display: block; } }

.o-three-col__nav-item {
  margin: 0 0 20px;
  padding: 0;
  border: 0;
  background: none;
  color: #A7A9AC;
  cursor: pointer;
  outline: none; }
  .o-three-col__nav-item:hover {
    color: #E42A1D; }

.o-three-col__nav-item .icon {
  vertical-align: middle; }

.o-three-col__nav--next {
  margin-left: 5px; }

@media screen and (min-width: 61.25em) {
  .s-module-bkgd--dark .o-three-col__item {
    border-color: #333333; } }

.o-three-col--carousel {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px; }
  @media print, screen and (min-width: 40em) {
    .o-three-col--carousel {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .o-three-col--carousel {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; } }

@media screen and (min-width: 61.25em) {
  .o-three-col--carousel .m-moduleHeader__intro {
    margin-bottom: 17px; } }

@media screen and (min-width: 61.25em) {
  .o-three-col--carousel .o-three-col__items {
    display: block; } }

.o-three-col--carousel .flickity-viewport {
  width: 100%; }
  @media screen and (max-width: 61.1875em) {
    .o-three-col--carousel .flickity-viewport {
      overflow: visible; } }
  @media screen and (min-width: 61.25em) {
    .o-three-col--carousel .flickity-viewport {
      margin-top: -10px; } }

@media screen and (min-width: 61.25em) {
  .o-three-col--carousel .flickity-slider {
    padding-top: 10px; } }

@media screen and (max-width: 61.1875em) {
  .o-three-col--carousel .flickity-viewport .o-three-col__item {
    margin-top: 0; } }

@media screen and (min-width: 61.25em) {
  .o-three-col--carousel .flickity-viewport .o-three-col__item {
    padding-bottom: 10px; } }

@media screen and (min-width: 61.25em) {
  .o-three-col--carousel.o-three-col--set-height .o-three-col__item {
    height: 100%; } }

.o-three-col--no-divider .o-three-col__item {
  border-left: 0; }
  @media screen and (max-width: 39.9375em) {
    .o-three-col--no-divider .o-three-col__item {
      margin-top: 0; } }

.o-three-col--carousel .flickity-viewport .m-post,
.o-heroGallery + .o-three-col .o-three-col__item:first-child > .m-post {
  padding-top: 0;
  border-top: 0; }

.o-twoThirds__inner {
  margin-top: 37px; }
  @media screen and (min-width: 61.25em) {
    .o-twoThirds__inner {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 42px; } }

@media screen and (min-width: 61.25em) {
  .o-twoThirds__aside,
  .o-twoThirds__main {
    float: none !important; } }

.o-twoThirds__aside {
  margin-top: 45px; }
  @media screen and (min-width: 61.25em) {
    .o-twoThirds__aside {
      margin-top: 0; } }

.o-twoThirds__cta {
  margin-top: 28px; }

.s-header-fixed .o-utility-nav__tertiary .m-navList--secondary .m-navList__link--primary {
  line-height: 15px; }

.o-utility-nav__tertiary .m-navList--secondary > :last-child:after {
  position: absolute;
  top: -7px;
  height: 30px;
  background-color: #585858; }

body {
  transition: background-color 0.2s cubic-bezier(0.5, 0, 0.5, 0), color 0.2s cubic-bezier(0.5, 0, 0.5, 0); }

body .body--highlight {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  background: #E42A1D;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased; }
  @media screen and (min-width: 40em) {
    body .body--highlight {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    body .body--highlight {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    body .body--highlight {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    body .body--highlight {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }

.g-footer {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0; }

.pageClass--spe .o-sectionInfo__title,
.pageClass--spe .m-landingPanel__title,
.pageClass--spe .m-moduleHeader__title {
  color: #191919; }

.pageClass--spe.dark .o-sectionInfo__title,
.pageClass--spe.dark .m-landingPanel__title,
.pageClass--spe.dark .m-moduleHeader__title,
.pageClass--spe .s-module-bkgd--dark .o-sectionInfo__title,
.pageClass--spe .s-module-bkgd--dark .m-landingPanel__title,
.pageClass--spe .s-module-bkgd--dark .m-moduleHeader__title,
.pageClass--spe .s-module-bkgd--highlight .o-sectionInfo__title,
.pageClass--spe .s-module-bkgd--highlight .m-landingPanel__title,
.pageClass--spe .s-module-bkgd--highlight .m-moduleHeader__title {
  color: #FFFFFF; }

@media screen and (min-width: 61.25em) {
  .pageClass--spe .o-sectionInfo__header {
    margin-bottom: 43px; } }

.pageClass--spe .o-sectionInfo__title {
  margin-bottom: 0; }

.pageClass--spe .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__image,
.pageClass--spe .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__image {
  padding-left: 0; }

@media screen and (min-width: 61.25em) {
  .pageClass--spe .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink p,
  .pageClass--spe .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__imageLink p {
    left: 20px; } }

@media screen and (min-width: 61.25em) {
  .pageClass--spe .m-featurePanel--small-alt .m-landingPanel--alt .m-landingPanel__imageLink::after,
  .pageClass--spe .m-landingPanel--alt.m-landingPanel--small .m-landingPanel__imageLink::after {
    left: 0;
    width: 100%; } }

.pageClass--spe .m-featurePanel .m-landingPanel__title,
.pageClass--spe .m-featurePanel .m-landingPanel--alt .m-landingPanel__title {
  top: 0;
  margin-bottom: 23px;
  transform: translateX(0); }
  @media screen and (min-width: 61.25em) {
    .pageClass--spe .m-featurePanel .m-landingPanel__title,
    .pageClass--spe .m-featurePanel .m-landingPanel--alt .m-landingPanel__title {
      margin-bottom: 42px; } }

@media screen and (max-width: 61.1875em) {
  .pageClass--spe .m-featurePanel--small .m-landingPanel__title,
  .pageClass--spe .m-featurePanel--small .m-landingPanel--alt .m-landingPanel__title {
    margin-bottom: 0; } }

.s-module-spacer {
  margin-top: 75px; }
  @media screen and (min-width: 61.25em) {
    .s-module-spacer {
      margin-top: 145px; } }
  .s-module-spacer.o-three-col {
    margin-top: 65px;
    padding-top: 10px; }
    @media screen and (min-width: 61.25em) {
      .s-module-spacer.o-three-col {
        margin-top: 135px; } }

.s-module-border {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 45px;
  padding-top: 50px;
  border-top: 1px solid #D9D9D9;
  transition: border-top 0.2s cubic-bezier(0.5, 0, 0.5, 0); }
  @media screen and (min-width: 40em) {
    .s-module-border {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .s-module-border {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .s-module-border {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .s-module-border {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (min-width: 40em) {
    .s-module-border {
      margin-top: 110px;
      padding-top: 65px; } }
  .dark .s-module-border {
    border-top-color: #333333; }
  .body--highlight .s-module-border {
    border-top-color: transparent; }

@media screen and (max-width: 61.1875em) {
  .s-module-border--mobile {
    margin-top: 45px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #D9D9D9;
    transition: border-top 0.2s cubic-bezier(0.5, 0, 0.5, 0); }
    .dark .s-module-border--mobile {
      border-top-color: #333333; }
    .body--highlight .s-module-border--mobile {
      border-top-color: transparent; } }

@media screen and (max-width: 39.9375em) {
  .s-module-border--mobile {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px; } }

@media screen and (min-width: 40em) {
  .s-module-border.m-featurePanel {
    padding-top: 35px; } }

@media screen and (max-width: 39.9375em) {
  .s-module-border.m-featurePanel--small {
    padding-top: 0;
    border-top: 0; } }

.m-featurePanel + .s-module-border {
  margin-top: 7px; }
  @media screen and (min-width: 40em) {
    .m-featurePanel + .s-module-border {
      margin-top: 72px; } }
  @media screen and (min-width: 61.25em) {
    .m-featurePanel + .s-module-border {
      margin-top: 35px; } }

.m-featurePanel + .s-module-spacer {
  margin-top: 35px; }
  @media screen and (min-width: 40em) {
    .m-featurePanel + .s-module-spacer {
      margin-top: 125px; } }

.s-module-border:first-child,
.o-heroGallery + .o-three-col.s-module-border,
.o-studyAreas + .s-module-border,
.body--highlight + .s-module-border,
.s-module-bkgd + .s-module-border {
  margin-top: 0;
  border-top: 0; }

.s-module-bkgd {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 50px;
  padding-top: 51px;
  padding-bottom: 45px;
  background-color: #F5F5F5; }
  @media screen and (min-width: 40em) {
    .s-module-bkgd {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 61.25em) {
    .s-module-bkgd {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px; } }
  @media screen and (min-width: 72.5em) {
    .s-module-bkgd {
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media screen and (min-width: 90.625em) {
    .s-module-bkgd {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (min-width: 40em) {
    .s-module-bkgd {
      margin-top: 110px;
      padding-top: 75px;
      padding-bottom: 110px; } }

.s-module-bkgd.s-module-bkgd--dark {
  background-color: #191919;
  color: #FFFFFF; }

.s-module-bkgd.s-module-bkgd--highlight {
  background-color: #E42A1D;
  color: #FFFFFF; }

.s-module-bkgd.s-module-bkgd--highlight .m-moduleHeader__title {
  color: #191919; }

.s-module-bkgd + .s-module-bkgd,
.s-module-bkgd + .m-cta {
  margin-top: 0; }

.s-sticky-body {
  position: relative; }

.s-sticky-body--abs,
.s-sticky-body--active {
  position: fixed;
  top: auto;
  bottom: 49px; }

.s-sticky-body--abs {
  position: absolute; }

.s-sticky-body--sticky {
  position: sticky;
  top: 120px;
  bottom: auto; }
