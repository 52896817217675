.m-highlightsGrid {
  display: block;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 43px;

  &.add-separator--top:before {
    width: 100%;
  }

  @include breakpoint(medium up) {
    padding-top: 22px;
    padding-bottom: 66px;
  }
}

.m-highlightsGrid h2 {
  @include breakpoint(large up) {
    margin-top: 45px;
  }
}

.m-highlightsGrid__items,
.o-primaryContent ul .m-highlightsGrid__items {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  @include breakpoint(medium up){
    display: flex;
    flex-flow: row wrap;
    margin-top: 29px;
  }
}

.m-highlightsGrid li,
.o-primaryContent ul .m-highlightsGrid li {
  float: none !important;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  &::before {
    display: none;
  }

  @include breakpoint(medium up){
    width: 50%;
    margin-top: 29px;

    &:nth-child(-n+2){
      margin-top: 0;
    }
  }
}

.m-highlightsGrid__title {
  margin-top: 0;
}

.o-primaryContent.m-textBlock .m-highlightsGrid li {
  &::before {
    display: none;
  }
}
