.o-sectionInfo {
}

.o-sectionInfo .m-moduleHeader {
  margin-bottom: 25px;

  @include breakpoint(large up) {
    margin-bottom: 60px;
  }
}

.o-sectionInfo .m-moduleHeader__intro {
  display: none;

  @include breakpoint(large up) {
    display: block;
    margin-bottom: 0;
  }
}

.o-sectionInfo__header {
  position: relative;
  z-index: 2;
  margin: -60px 0 0 -20px;
  padding: 25px 20px 15px;
  background: $color__background;

  @include breakpoint(small up) {
    padding: 33px 20px 44px;
  }

  @include breakpoint(medium up) {
    margin: -60px 0 0 -30px;
    padding: 27px 30px 48px;
  }

  @include breakpoint(large up) {
    margin: 0;
    padding: 0;
    background: none;
  }
}

.o-sectionInfo__title {
  @extend %f-h18;
  position: relative;
  margin-bottom: -10px;
  color: $color__highlight;
  opacity: 0;

  @include breakpoint(medium up) {
    margin-bottom: -15px;
  }

  @include breakpoint(large up) {
    margin-bottom: -22px;
  }

  .wf-neuedisplayrandom-n7-active & {
    opacity: 1;
  }
}

.o-sectionInfo__mediaImage {
  display: block;
  margin: 0 0 22px -20px;
  width: calc(100% + 40px);

  @include breakpoint(large up) {
    margin: 0 0 0 -60px;
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    width: calc(100% + 60px);
  }

  @include breakpoint(1281px) {
    margin-left: calc((20px + ((100vw - 1200px) / 2)) * -1);
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    width: calc(100% + 20px + ((100vw - 1200px) / 2));
  }

  @include breakpoint(xxlarge up) {
    margin-left: 0;
    min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
    width: 100%;
  }

  @include breakpoint(1740px) {
    height: auto;
    min-height: auto;
  }
}

.o-sectionInfo__mediaImageRight {
  display: block;
  margin: 0 0 0 -20px;
  width: calc(100% + 40px);

  @include breakpoint(small up) {
    margin: 0 0 0 -30px;
    width: calc(100% + 30px);
  }

  @include breakpoint(large up) {
    margin: 0;
    min-height: calc(((100vw - (160px)) * 0.66) * 0.625);
    width: calc(100% + 45px);
  }

  @include breakpoint(xlarge up) {
    width: calc(100% + 60px);
  }

  @include breakpoint(xxlarge up) {
    min-height: calc(((100vw - (320px)) * 0.66) * 0.625);
    width: 100%;
  }

  @include breakpoint(1740px) {
    // height: 585px;
    min-height: auto;
  }
}

.o-sectionInfo__mediaImageRight--absolute {
  display: none;
  opacity: 0;

  @include breakpoint(large up) {
    display: block;
    transition: opacity ease-in-out 180ms;
    width: calc(100% + 15px);

    position: absolute;
    top: 0;
  }

  @include breakpoint(xlarge up) {
    width: calc(100% + 20px);
  }

  @include breakpoint(1281px) {
    width: calc(100% - 20px + ((100vw - 1200px) / 2));
  }

  @include breakpoint(xxlarge up) {
    width: calc(100% - 40px);
  }

  &.is-active {
    opacity: 1;
  }
}

.o-sectionInfo__cta {
  @extend %a-cta--dual-line;
  float: left;
  margin: 38px 0 46px;

  @include breakpoint(large up) {
    float: none;
    margin: 19px 0;
  }
}

.o-sectionInfo__content {
  margin-top: 5px;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.o-sectionInfo .m-collegeList {
  margin: 45px 0 0;

  @include breakpoint(large up) {
    height: 100%;
    margin-top: 36px;
  }
}

.o-sectionInfo .m-collegeList:first-child {
  margin-top: 38px;

  @include breakpoint(large up) {
    margin-top: 102px;
  }
}

.o-sectionInfo .m-collegeList__items {
  height: 100%;
  padding-bottom: 13px;
  border-bottom: 1px solid $color__separator;

  @include breakpoint(large up) {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.o-sectionInfo .a-video__link {
  margin: 0;
}

.o-sectionInfo--alt {
}

.o-sectionInfo--alt .m-moduleHeader {
  @include breakpoint(large up) {
    margin-bottom: 150px;
  }
}

.o-sectionInfo--alt .o-sectionInfo__inner {
  @include breakpoint(large up) {
    display: flex;
    flex-flow: row nowrap;
  }
}

.o-sectionInfo--alt .o-sectionInfo__main,
.o-sectionInfo--alt .o-sectionInfo__aside {
  @include breakpoint(large up) {
    float: none !important;
  }
}

.o-sectionInfo--alt .o-sectionInfo__main {
  margin-top: -7px;

  @include breakpoint(large up) {
    margin-top: 0;
    padding-bottom: 24px;
  }
}

.o-sectionInfo--alt .o-sectionInfo__aside {
  @include breakpoint(large up) {
    margin-top: 30px;
  }
}

.o-sectionInfo--alt .o-sectionInfo__sticky {
  @include breakpoint(large up) {
    position: sticky;
    top: 95px;
    margin-top: -80px;
  }
}

.o-sectionInfo--alt .o-sectionInfo__content {
  @extend %f-h15;

  @include breakpoint(large up) {
    display: none;
  }
}

.o-sectionInfo--alt .m-collegeList:first-child {
  margin-top: 0;
}

.o-sectionInfo--alt .is-active .m-collegeList__content-wrapper {
  @include breakpoint(large up) {
    bottom: -55px;
    overflow: visible;
  }
}

.o-sectionInfo--alt .is-active .m-collegeList__content-inner {
  // position: sticky;
  // top: 100px;
  // background-color: white;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 100%;
  //   width: 100%;
  //   height: 9999px;
  //   background-color: white;
  // }
}

.o-sectionInfo--alt .is-active .m-collegeList__content {
  @include breakpoint(large up) {
    position: sticky;
    top: 100px;
    padding-bottom: 0;
  }
}

.o-sectionInfo--alt .m-collegeList__content p:first-child {
  margin-top: 0;
}
